export const getBCMSCredentialErrors = bcmsaccount => {
  const errors = {};
  if (!bcmsaccount.ctsoluser) {
    errors.ctsoluser = 'user is required';
  } else if (!bcmsaccount.ctsoluser.match(/^\d{3}-\d{3}-\d{3}$/)) {
    errors.ctsoluser = 'user should be 3 blocks of 3 digits with dashes';
  }
  if (!bcmsaccount.pwd) {
    errors.pwd = 'password is required';
  }
  if (!bcmsaccount.location) {
    errors.location = 'location is required';
  } else if (!bcmsaccount.location.match(/^\d*\/\d*\/\d*$/)) {
    errors.location =
      'location should be 3 blocks of digits seperated by slashes';
  }
  return errors;
};

export const getAgriwebbCredentialsError = agriwebb => {
  const errors = {};
  return errors;
};
