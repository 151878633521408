import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getScoresObj } from '../reducer';
import { fetchScores, updateScore } from '../actions';
import './score-detail.scss';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import { Link, Prompt } from 'react-router-dom';
import TextField from '../../../form/TextField';
import TextAreaField from '../../../form/TextAreaField';
import { scoreHasChanged, getScoreValidationErrors } from '../util';
import { isAdmin } from '../../../util/jwt';
import TopLevelFormError from '../../../util/components/TopLevelFormError';

const ScoreDetail = ({ match, history }) => {
  if (!isAdmin()) {
    return (
      <h2 data-cy="restricted-header">Restricted</h2>
    );
  }
  const scoreId = match.params.scoreId;
  const scoreObject = useSelector(getScoresObj);
  const savedScore = scoreObject[scoreId] || {};
  const localScore = JSON.parse(JSON.stringify(savedScore));
  const [score, setScore] = useState(localScore);
  const [errors, setErrors] = useState({});
  const hasErrors = () => Object.values(errors).length > 0;
  useEffect(() => {
    if (savedScore && Object.keys(savedScore).length) {
      setScore(savedScore);
    }
  }, [savedScore]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchScores(scoreId));
  }, []);
  const backUrl = match.url.replace('/' + scoreId, '');
  const handleSaveClicked = () => {
    if (!hasErrors() && scoreHasChanged(savedScore, score)) {
      dispatch(updateScore(score));
    }
  };
  const updateScoreName = name => {
    const newScoreDef = JSON.parse(JSON.stringify(score));
    newScoreDef.name = name;
    setErrors(getScoreValidationErrors(newScoreDef));
    setScore(newScoreDef);
  };
  const updateScoreSignificance = significance => {
    const newScoreDef = JSON.parse(JSON.stringify(score));
    newScoreDef.significance = significance;
    setErrors(getScoreValidationErrors(newScoreDef));
    setScore(newScoreDef);
  };
  const updateScoreCardItem = (index, item) => {
    const newScoreDef = JSON.parse(JSON.stringify(score));
    newScoreDef.scoreCard = newScoreDef.scoreCard || [];
    newScoreDef.scoreCard[index] = item;
    setErrors(getScoreValidationErrors(newScoreDef));
    setScore(newScoreDef);
  };
  let saveButtonClass = 'button';
  if (!scoreHasChanged(savedScore, score) || hasErrors()) {
    saveButtonClass += ' disabled';
  }
  const errorDetails = Object.values(errors).length < 1 ? null : (
    <TopLevelFormError />
  );
  let title = score.name;
  const scoreCardItems = score && score.scoreCard ?
    score.scoreCard.map((item, index) => {
      const updateFunc = definition => {
        const newItem = { ...item };
        newItem.definition = definition;
        updateScoreCardItem(index, newItem);
      };
      return (
        <TextAreaField
          key={ item.score }
          name={ item.score + ' Points' }
          error={ errors[`score-${index}`]}
          testId={ `scorecard-significance-${index}` }
          value={ item.definition }
          className="score-card-item"
          update={ updateFunc } />
      );
    }) : null;
  const farmLevel = score.farmLevel ? (
    <div className="farm-level-wrapper">
      <div className="farm-level-icon"></div>
      <div className="farm-level-title">Farm Level Score</div>
    </div>
  ) : null;
  if (Object.keys(savedScore).length === 0) {
    return (
      <div id="score">
        <Link className="back-to-scores-link"
          to={ backUrl }>
          <ChevronIcon /> Back to scores
        </Link>
        <h2 data-cy="score-detail-title">Not found</h2>
      </div>
    );
  }
  return (
    <div id="score">
      <Prompt
        when={ scoreHasChanged(savedScore, score) }
        message={location =>
          'You have unsaved changes - you will lose them if you continue'}
      />
      <Link className="back-to-scores-link"
        to={ backUrl }>
        <ChevronIcon /> Back to scores
      </Link>
      <h2 data-cy="score-detail-title">{ title } </h2>
      <h4 className="key">:{ score.key }</h4>
      { farmLevel }
      <form onSubmit={ e => e.preventDefault() }>
        <TextField name="Name"
          value={ score ? score.name || '' : '' }
          error={ errors.name }
          testId="name-text-box"
          update={ updateScoreName } />
        <TextAreaField name="significance"
          value={ score && score.significance ? score.significance : '' }
          error={ errors.significance }
          className="significance-field"
          testId="significance-textarea"
          update={ updateScoreSignificance } />
      </form>
      <div className="score-card">
        <h4>Score Card</h4>
        { scoreCardItems }
      </div>
      <div className="button-row center">
        <div className="cancel button"
          data-cy="cancel-button"
          onClick={ () => history.push(backUrl) }>
          Cancel
        </div>
        <div className={ saveButtonClass }
          data-cy="save-button"
          onClick={ handleSaveClicked }>
          Save
        </div>
      </div>
      { errorDetails }
    </div>
  );
};

ScoreDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      scoreId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default ScoreDetail;
