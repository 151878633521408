/* eslint-disable no-unneeded-ternary */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getAdminAnimalsObject,
  isFetching,
  getAnimalsFetching,
  getAnimalUnderEdit,
  getFilesForDeletion,
  getNewFiles,
  getAnimalEditErrors
} from '../reducer';
import {
  updateAnimal,
  deleteAnimal,
  fetchAnimal,
  addAnimal,
  updateAnimalUnderEdit,
  setFilesForDeletion,
  setNewFiles
} from '../actions';
import PropTypes from 'prop-types';
import {
  newAnimal,
  newAnimalHasChanged,
  animalHasChanged
} from '../validation/animal';
import './animal.scss';
import LoadingIcon from '../../util/icons/components/LoadingIcon';
import DocumentUpload from '../documents/components/DocumentUpload';
import AdminAPI from '../AdminAPI';
import { Prompt, Link } from 'react-router-dom';
import ChevronIcon from '../../util/icons/components/ChevronIcon';
import AnimalScoreDisplay from './AnimalScoreDisplay';
import AnimalScore from './AnimalScore';
import moment from 'moment';
import getS3ObjectFromURL from '../util/getS3ObjectFromURL';
import { getOrgsObj } from '../orgAdmin/reducer';
import { fetchOrgs } from '../orgAdmin/actions';
import Images from '../imageAdmin/components/Images';
import { captureException } from '../../util/logger';
import AnimalQRSection from './AnimalQRSection';
import ErrorBoundary from '../../util/components/ErrorBoundary';
import { getScoreDefinitions } from '../scoreDefinitions/reducer';
import FarmDocumentSelector from '../documents/components/FarmDocumentSelector';
import DocumentList from '../documents/components/DocumentList';
import Modal from '../../main/components/Modal';
import GBIButton from '../../util/buttons/components/GBIButton';
import analytics from '../../util/analytics';
import BeefProcessingAnimalFields from './BeefProcessingAnimalFields';
import VideoAdminSection from '../videoAdmin/components/VideoAdminSection';
import LiveStockFields from './LiveStockFields';
import { updateObjectAttribute } from '../util';
import CoreAnimalFields from './CoreAnimalFields';
import AnimalDisplayFields from './AnimalDisplayFields';
import ExpandableFormSection from '../util/components/ExpandableFormSection';
import AnimalUltrasoundSection from './AnimalUltrasoundSection';
import TopLevelFormError from '../../util/components/TopLevelFormError';
import StickySaveButtons from './StickySaveButtons';
import CalvingFields from './CalvingFields';

/*
  React is too slow - do this directly in js
*/
const handleScroll = () => {
  const marker = document.getElementById('section-end');
  if (!marker) return;
  const rect = marker.getBoundingClientRect();
  let bottom = window.innerHeight || document.documentElement.clientHeight;
  bottom = bottom - 95;
  const fix = rect.bottom > bottom;
  const buttonRow = document.getElementById('main-save-delete-controls');
  if (buttonRow.className === 'fixed' && !fix) {
    buttonRow.className = '';
  } else if (buttonRow.className !== 'fixed' && fix) {
    buttonRow.className = 'fixed';
  }
};

const mapStateToProps = state => ({
  animals: getAdminAnimalsObject(state),
  animal: getAnimalUnderEdit(state),
  errors: getAnimalEditErrors(state),
  filesForDeletion: getFilesForDeletion(state),
  newFiles: getNewFiles(state),
  orgs: getOrgsObj(state),
  loadingAll: isFetching(state),
  individualsLoading: getAnimalsFetching(state),
  scoreDefinitions: getScoreDefinitions(state)
});

const mapDispatchToProps = dispatch => ({
  add: (animal, files, redirectUrl) =>
    dispatch(addAnimal(animal, files, redirectUrl)),
  update: animal => dispatch(updateAnimalUnderEdit(animal)),
  save: (animal, files) => dispatch(updateAnimal(animal, files)),
  delete: (animal, files) => dispatch(deleteAnimal(animal, files)),
  fetch: id => dispatch(fetchAnimal(id)),
  fetchOrgs: () => dispatch(fetchOrgs()),
  setFilesForDeletion: files => dispatch(setFilesForDeletion(files)),
  setNewFiles: files => dispatch(setNewFiles(files))
});

class Animal extends Component {
  constructor (props) {
    super(props);
    let animal = props.animals[props.match.params.animalId] || null;
    const fullyLoaded = animal && animal.fullyLoaded;
    let org = null;
    try {
      let orgId = Object.keys(this.props.orgs)[0];
      org = this.props.orgs[orgId];
    } catch (error) { /* */ }
    if (props.match.params.animalId === 'new') {
      animal = newAnimal(org, this.props.scoreDefinitions);
    }
    this.props.update(animal);
    this.props.setFilesForDeletion([]);
    this.props.setNewFiles([]);
    this.state = {
      ignorePromptOnRouteChange: false,
      urlAnimalId: props.match.params.animalId,
      showErrors: false,
      showDelete: false,
      tab: 'production'
    };
    this.updateFormField = this.updateFormField.bind(this);
    if (this.props.match.params.animalId &&
        this.props.match.params.animalId !== 'new' &&
        !fullyLoaded) {
      props.fetch(this.props.match.params.animalId);
    }
    this.props.fetchOrgs();
  }
  static getDerivedStateFromProps (props, state) {
    let ignorePromptOnRouteChange = state.ignorePromptOnRouteChange;
    let urlAnimalId = state.urlAnimalId;
    if (props.match.params.animalId !== state.urlAnimalId) {
      ignorePromptOnRouteChange = false;
      urlAnimalId = props.match.params.animalId;
    }
    if (!props.animal && props.match.params.animalId !== 'new' &&
    props.animals[props.match.params.animalId]) {
      props.update(props.animals[props.match.params.animalId]);
    }
    if (props.animal &&
      props.animal.fullyLoaded !== true &&
      props.animals[props.match.params.animalId] &&
      props.animals[props.match.params.animalId].fullyLoaded === true) {
      props.update(props.animals[props.match.params.animalId]);
    }

    return { ...state, ignorePromptOnRouteChange, urlAnimalId };
  }
  componentDidMount () {
    window.scrollTo({ top: 0, behavior: 'auto' });
    window.addEventListener('scroll', handleScroll);
  }
  componentWillUnmount () {
    if (this.props.newFiles && this.props.newFiles.length) {
      AdminAPI.deleteDocuments(this.props.newFiles).catch(
        error => captureException(error)
      );
    }
    window.removeEventListener('scroll', handleScroll);
  }
  isLoading () {
    return this.props.loadingAll ||
    this.props.individualsLoading[this.props.match.params.animalId];
  }
  hasErrors () {
    return Object.values(this.props.errors).length > 0;
  }
  updateFormField (fieldName, fieldValue) {
    const animal = updateObjectAttribute(
      this.props.animal, fieldName, fieldValue
    );
    this.props.update(animal);
  }
  updateGBIScore (element, factor, score) {
    const animal = JSON.parse(JSON.stringify(this.props.animal)) || {};
    const scores = this.props.scoreDefinitions[factor].scoreCard;
    let validScore = false;
    for (let i = 0; i < scores.length; i++) {
      if (scores[i].score === score) {
        validScore = true;
        break;
      }
    }
    if (validScore) {
      animal[element] = animal[element] || {};
      animal[element][factor] = animal[element][factor] || {};
      animal[element][factor].score = score;
    }
    this.props.update(animal);
  }
  handleSubmit (event) {
    event.preventDefault();
  }
  handleSaveClicked () {
    const animal = this.props.animals[this.props.match.params.animalId];
    if (this.hasErrors()) {
      this.setState({ showErrors: true });
    }
    if (this.hasErrors() || !this.unsavedChanges()) return;
    if (!animal || !animal.saved) {
      let redirectUrl = null;
      if (this.props.match.params.animalId === 'new') {
        redirectUrl = this.props.match.url.replace('new', this.props.animal.id);
        this.setState({ ignorePromptOnRouteChange: true });
      }
      this.props.add(
        this.props.animal, this.props.filesForDeletion, redirectUrl
      );
      analytics(
        'send',
        'event',
        'AnimalUploads',
        'newAnimalSaved',
        this.props.animal.tag
      );
    } else {
      this.props.save(this.props.animal, this.props.filesForDeletion);
    }
    this.props.setFilesForDeletion([]);
    this.props.setNewFiles([]);
  }
  handleDeleteClicked () {
    this.props.delete(this.props.animal, this.props.newFiles);
    this.props.history.push('/account/animals');
  }
  getAnimalAttribute (...attributePath) {
    try {
      let attribute = this.props.animal;
      attributePath.forEach(attributeName => {
        attribute = attribute[attributeName];
      });
      return attribute;
    } catch (error) {
      return null;
    }
  }
  addDocumentRef (file, documentType, keyFact) {
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    animal.documents = animal.documents || {};
    const filesForDeletion =
      JSON.parse(JSON.stringify(this.props.filesForDeletion));
    if (animal.documents[documentType]) {
      if (!this.isFarmDocument(file, documentType)) {
        const s3Object = getS3ObjectFromURL(file);
        filesForDeletion.push(s3Object);
      }
    }
    animal.documents[documentType] = animal.documents[documentType] || {};
    animal.documents[documentType].src = file;
    if (keyFact) {
      animal.documents[documentType].keyFact = keyFact;
    }
    const s3Object = getS3ObjectFromURL(file);
    const newFiles =
      JSON.parse(JSON.stringify(this.props.newFiles));
    newFiles.push(s3Object);
    this.props.update(animal);
    this.props.setNewFiles(newFiles);
    this.props.setFilesForDeletion(filesForDeletion);
    analytics(
      'send',
      'event',
      'AnimalUploads',
      'animalDocumentUploaded',
      animal.tag
    );
  }
  removeDocument (file) {
    const s3Object = getS3ObjectFromURL(file);
    const newFiles =
      JSON.parse(JSON.stringify(this.props.newFiles));
    newFiles.push(s3Object);
    this.props.setNewFiles(newFiles);
  }
  addImage (src) {
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    const images = animal.images || [];
    const imageData = {
      fileName: src.substring(src.lastIndexOf('/') + 1),
      processed: false,
      base: src
    };
    images.push(imageData);
    animal.images = images;
    const s3Object = getS3ObjectFromURL(src);
    const newFiles =
      JSON.parse(JSON.stringify(this.props.newFiles));
    newFiles.push(s3Object);
    this.props.update(animal);
    this.props.setNewFiles(newFiles);
  }
  deleteImage (imageData) {
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    let images = animal.images || [];
    images = images.filter(img => {
      return img.base !== imageData.base;
    });
    animal.images = images;
    const filesForDeletion = [ ...this.props.filesForDeletion ];
    ['base', 'thumb330', 'thumb660', 'large1080', 'large2160'].forEach(attr => {
      if (imageData[attr]) {
        filesForDeletion.push(imageData[attr]);
      }
    });
    this.props.update(animal);
    this.props.setFilesForDeletion(filesForDeletion);
  }
  moveImage (oldIndex, newIndex) {
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    let images = animal.images;
    const image = images[oldIndex];
    images.splice(oldIndex, 1);
    images.splice(newIndex, 0, image);
    animal.images = images;
    this.props.update(animal);
  }
  isFarmDocument (src, key) {
    const animal = this.props.animal;
    if (!animal.orgId) return false;
    if (!this.props.orgs[animal.orgId]) return false;
    if (!this.props.orgs[animal.orgId].documents) return false;
    if (!this.props.orgs[animal.orgId].documents[key]) return false;
    if (!this.props.orgs[animal.orgId].documents[key].src) return false;
    return this.props.orgs[animal.orgId].documents[key].src === src;
  }
  deleteDocumentRef (key) {
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    const src = animal.documents[key].src;
    delete animal.documents[key];
    const filesForDeletion =
      JSON.parse(JSON.stringify(this.props.filesForDeletion));
    if (!this.isFarmDocument(src, key)) {
      const s3Object = getS3ObjectFromURL(src);
      filesForDeletion.push(s3Object);
    }
    this.props.update(animal);
    this.props.setFilesForDeletion(filesForDeletion);
  }
  addFarmDocument (key, src, keyFact) {
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    animal.documents = animal.documents || {};
    const newFiles =
      JSON.parse(JSON.stringify(this.props.newFiles));
    if (animal.documents[key] && animal.documents[key].src &&
    animal.documents[key].src !== src) {
      const s3Object = getS3ObjectFromURL(animal.documents[key].src);
      newFiles.push(s3Object);
    }
    animal.documents[key] = { src };
    if (keyFact) {
      animal.documents[key].keyFact = keyFact;
    }
    this.props.update(animal);
    this.props.setNewFiles(newFiles);
  }
  updateDocuments (documents) {
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    animal.documents = documents;
    this.props.update(animal);
  }
  getError (errorKey) {
    if (this.state.showErrors && this.props.errors[errorKey]) {
      return this.props.errors[errorKey];
    }
    return null;
  }
  unsavedChanges () {
    if (this.props.match.params.animalId === 'new') {
      let org = null;
      if (this.props.orgs &&
        this.props.animal &&
        this.props.orgs[this.props.animal.orgId]) {
        org = this.props.orgs[this.props.animal.orgId];
      }
      return newAnimalHasChanged(
        this.props.animal, org, this.props.scoreDefinitions
      );
    }
    const savedAnimal = this.props.animals[this.props.match.params.animalId];
    const animal = JSON.parse(JSON.stringify(this.props.animal));
    return animalHasChanged(savedAnimal, animal, this.props.scoreDefinitions);
  }
  renderDeleteModal () {
    if (!this.state.showDelete) return null;
    let title = 'new animal';
    if (this.props.animal && (
      this.props.animal.name || this.props.animal.tag
    )) {
      title = this.props.animal.name || this.props.animal.tag;
    }
    return (
      <Modal
        pos={ window.scrollY }
        contentClass="delete-animal-modal-content"
        close={ () => this.setState({ showDelete: false })} >
        <div>
          <h2 className="title">{ `Delete ${title}?` }</h2>
          <p className="text">{ 'this action can not be undone' }</p>
          <div className="button-row">
            <GBIButton
              onClick={ () => this.setState({ showDelete: false }) }
              label="cancel"
              className="light" />
            <GBIButton onClick={ this.handleDeleteClicked.bind(this) }
              testId="animal-confirm-delete-button"
              label="delete" />
          </div>
        </div>
      </Modal>
    );
  }
  renderButtons () {
    let saveButtonClass = 'button';
    if ((this.hasErrors() && this.state.showErrors) ||
      !this.unsavedChanges()) {
      saveButtonClass += ' disabled';
    } else {
      saveButtonClass += ' save';
    }
    const animal = this.props.animals[this.props.match.params.animalId];
    let deleteButtonClass = 'button';
    if (animal && animal.saved) {
      deleteButtonClass += ' delete';
    } else {
      deleteButtonClass += ' disabled';
    }
    const error = this.state.showErrors &&
      Object.keys(this.props.errors).length ? (
        <TopLevelFormError testId="animal-error-message"/>
      ) : null;
    return (
      <StickySaveButtons
        handleSaveClicked={ this.handleSaveClicked.bind(this) }
        handleDeleteClicked={ () => this.setState({ showDelete: true }) }
        saveButtonClass={ saveButtonClass }
        deleteButtonClass={ deleteButtonClass }
        backUrl="/account/animals">
        { error }
      </StickySaveButtons>
    );
  }
  renderGBIFields (element) {
    return Object.keys(this.props.scoreDefinitions).map(key => ({
      key,
      name: this.props.scoreDefinitions[key].name,
      category: this.props.scoreDefinitions[key].category,
      scores: this.props.scoreDefinitions[key].scoreCard.map(
        score => score.score
      )
    })).filter(factor => {
      const type = this.props.animal && this.props.animal.type ?
        this.props.animal.type : null;
      if ((type !== `YoungBulls` && factor.key === 'bullFertility') ||
      (type !== `BreedingCows` && factor.key === 'cowFertility')) {
        return false;
      }
      return factor.category === element;
    }).map(factor => {
      let score = '-';
      try {
        score = this.props.animal[element][factor.key].score;
      } catch (error) { /**/ }
      const setNewScore = score => {
        this.updateGBIScore(element, factor.key, score);
      };
      return <AnimalScore key={ factor.key }
        score={ score }
        update={ setNewScore }
        factor={ factor } />;
    });
  }
  renderGBIScoreError () {
    if (this.state.showErrors !== true) return null;
    let showError = false;
    try {
      Object.keys(this.props.errors).forEach(errorKey => {
        if (errorKey.includes('score_') &&
        this.props.scoreDefinitions[errorKey.replace('score_', '')]) {
          showError = true;
        }
      });
    } catch (error) { /* */ }
    return showError ? (
      <div className="error gbi-scores-error">
        Values for all factors are required
      </div>
    ) : null;
  }
  renderDocuments () {
    const documents = this.props.animal && this.props.animal.documents ?
      this.props.animal.documents : {};
    let error = this.getError('documents') ? (
      <div className="errors" data-cy="document-error">
        { this.getError('documents') }
      </div>
    ) : null;
    if (!this.props.animal || !this.props.animal.orgId) return null;
    return (
      <div className="documents" data-cy="animal-document-section">
        <ExpandableFormSection
          title="Authentication documents"
          id="document-expandable"
          error={ error } >
          <div className="document-buttons">
            <DocumentUpload
              removeFunc={ this.removeDocument.bind(this) }
              ownerId={ this.props.animal ? this.props.animal.orgId : '' }
              uploaded={ this.addDocumentRef.bind(this) } />
            <FarmDocumentSelector animal={ this.props.animal }
              add={ this.addFarmDocument.bind(this) } />
          </div>
          <DocumentList documents={ documents }
            updateDocuments={ this.updateDocuments.bind(this) }
            removeDocument={ this.deleteDocumentRef.bind(this) } />
          { error }
        </ExpandableFormSection>
      </div>
    );
  }
  renderImages () {
    if (this.props.animal && this.props.animal.saved &&
      this.props.animal.orgId) {
      const images = this.getAnimalAttribute('images') || [];
      const orgId = this.props.animal && this.props.animal.orgId ?
        this.props.animal.orgId : 'error';
      return (
        <Images images={ images }
          addImage={ this.addImage.bind(this) }
          deleteImage={ this.deleteImage.bind(this) }
          moveImage={ this.moveImage.bind(this) }
          ownerId={ orgId }
          expandable
          maxImages={ 10 } />
      );
    }
    return null;
  }
  renderBlanket () {
    const animal = this.props.animals[this.props.match.params.animalId];
    const isSaving = animal ? !!animal.saving : false;
    let className = 'animal-api-blanket';
    if (this.isLoading() || isSaving) className += ' visible';
    return (
      <div className={ className } >
        <LoadingIcon />
      </div>
    );
  }
  renderBackButton () {
    const id = this.props.match.params.animalId;
    let path = this.props.match.url.replace('/' + id, '');
    return (
      <Link className="back-to-animals-link"
        data-cy="back-to-animals-link"
        to={ path }>
        <ChevronIcon /> Back to animals
      </Link>
    );
  }
  renderLastUpdate () {
    let updateInfo = null;
    try {
      if (this.props.animal.lastUpdate) {
        const update = this.props.animal.lastUpdate;
        updateInfo = (
          <span className="update-info">
            { `Last changed ${moment(update.dateTime).fromNow()} 
              by ${update.userName || 'unknown'}` }
          </span>
        );
      }
    } catch (error) { /* */ }
    return updateInfo;
  }
  renderVideo () {
    if (this.props.animal && this.props.animal.saved &&
      this.props.animal.orgId) {
      const animal = this.props.animal;
      return (
        <VideoAdminSection
          videoData={animal ? animal.video : null }
          animalId={ animal ? animal.id : null }
          orgId={ animal ? animal.orgId : null }
          expandable
          update={ val => this.updateFormField('video', val) } />
      );
    }
    return null;
  }
  getOrg () {
    try {
      return this.props.orgs[this.props.animal.orgId].name || '';
    } catch (error) {
      return '';
    }
  }
  renderOrg () {
    if (this.getOrg()) {
      return <h5 className="org">{ this.getOrg() }</h5>;
    }
    return null;
  }
  renderSaveAnimalNotice () {
    if (!this.props.animal || !this.props.animal.saved) {
      return (
        <ExpandableFormSection title="Video / Images">
          <p>
            { `Save the animal in order to upload videos or images` }
          </p>
        </ExpandableFormSection>
      );
    }
    return null;
  }
  render () {
    if (this.isLoading()) {
      return (
        <div id="animal" className="loading-page">
          <LoadingIcon />
        </div>
      );
    }
    const allowPrompt = this.unsavedChanges() &&
      !this.state.ignorePromptOnRouteChange;
    const statusClass = this.getAnimalAttribute('liveOnGBI') ||
      this.getAnimalAttribute('liveOnBeefBook') ?
      'status-icon live' : 'status-icon';
    let viewCount = this.getAnimalAttribute('views') ? (
      <div className="view-count">
        { this.getAnimalAttribute('views').toLocaleString() + ' views' }
      </div>
    ) : null;
    const scoreError = this.renderGBIScoreError();
    const scans = this.props.animal ? this.props.animal.ultrasoundScans : [];
    return (
      <Fragment>
        { this.renderBlanket() }
        <Prompt
          when={ allowPrompt }
          message={location =>
            'You have unsaved changes - you will lose them if you continue'}
        />
        <div id="animal" data-cy="admin-animal-page">
          { this.renderBackButton() }
          <h2 data-cy="animal-page-title">
            { this.getAnimalAttribute('name') || '' }
          </h2>
          { this.renderOrg() }
          <div className="status-wrapper">
            <div className={ statusClass }></div>
            { this.renderLastUpdate() }
            { viewCount }
          </div>
          <CoreAnimalFields showErrors={ this.state.showErrors } />
          <AnimalDisplayFields showErrors={ this.state.showErrors } />
          <LiveStockFields showErrors={ this.state.showErrors } />
          <CalvingFields showErrors={ this.state.showErrors } />
          { this.renderDocuments() }
          { this.renderImages() }
          { this.renderVideo() }
          { this.renderSaveAnimalNotice() }
          <ExpandableFormSection title="Score card"
            isErrored={scoreError ? true : false }>
            <AnimalScoreDisplay
              animal={ this.props.animal }
              scoreError={ scoreError }
              selectElement={ element => this.setState({ tab: element }) } >
              { this.renderGBIFields(this.state.tab) }
            </AnimalScoreDisplay>
          </ExpandableFormSection>
          { this.renderDeleteModal() }
          <BeefProcessingAnimalFields showErrors={ this.state.showErrors } />
          <AnimalUltrasoundSection scans={ scans } />
          <ErrorBoundary>
            <ExpandableFormSection title="QR Code, labels and links"
              id="qr-section-expandable">
              <AnimalQRSection animal={ this.props.animal }
                farm={ this.getOrg() }/>
            </ExpandableFormSection>
          </ErrorBoundary>
          { this.renderButtons() }
        </div>
      </Fragment>
    );
  }
}

Animal.propTypes = {
  animals: PropTypes.object,
  animal: PropTypes.object,
  errors: PropTypes.object,
  filesForDeletion: PropTypes.array,
  newFiles: PropTypes.array,
  orgs: PropTypes.object,
  loadingAll: PropTypes.bool,
  individualsLoading: PropTypes.object,
  add: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  fetchOrgs: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      animalId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object,
  scoreDefinitions: PropTypes.object,
  setFilesForDeletion: PropTypes.func.isRequired,
  setNewFiles: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Animal);
