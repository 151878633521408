import { mongoid } from 'mongoid-js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyButton from '../../../util/buttons/components/CopyButton';
import DropDownSelect from '../../../util/form/components/DropDownSelect';
import PlusIcon from '../../../util/icons/components/PlusIcon';
import {
  addLabelRow,
  deleteLabelRow,
  duplicateLabelRow,
  setLabelPage,
  updateLabelRow
} from '../actions';
import {
  getLabelPage,
  getLabelPageCount,
  getLabels,
  getPageOfLabels
} from '../reducer';
import './label-table.scss';
import PropTypes from 'prop-types';
import ComplexLabelButton from './ComplexLabelButton';
import Pagination from '../../../util/tables/Pagination';

const cuts = [
  "Side",
  "Tenderloin",
  "Rib Eye",
  "Striploin",
  "Bone In Rib Eye",
  "Shortloin",
  "Butt Tenderloin",
  "Rump Heart",
  "Piccania",
  "Short ribs",
  "Short ribs Chuck",
  "Feather Blade",
  "Brisket",
  "Flank Steak",
  "Tri Tip",
  "Flap Meat",
  "Topside",
  "Silverside",
  "Knuckle",
  "Lmc",
  "Chuck tender",
  "Skirt  Hind / Flank",
  "Chuck roll",
  "Clod / Neck",
  "Pot Roast",
  "Fore  Shin",
  "Hind shin",
  "Trim 85/70 Vl",
  "Burgers"
]

const LabelTable = ({ animal, farm }) => {
  const labels = useSelector(getLabels);
  const currentPageOfLabels = useSelector(getPageOfLabels);
  const pageCount = useSelector(getLabelPageCount);
  const page = useSelector(getLabelPage);
  const dispatch = useDispatch();
  const cells = [];
  currentPageOfLabels.forEach((label, index) => {
    const catchReturn = event => {
      const key = event.which || event.keyCode || 0;
      if (key === 13) {
        dispatch(duplicateLabelRow(label));
      }
    };
    const cutOptions = cuts.sort().map(cut => ({
      name: cut, value: cut, selected: label.cut === cut
    }));
    const cutClass = label.error ? 'cut error' : 'cut';
    cells.push(
      <DropDownSelect key={ `label-cut-${label.id}` }
        className={ cutClass }
        placeholder="select cut"
        options={ cutOptions }
        onChange={ cut => dispatch(updateLabelRow({ ...label, cut })) } />
    );
    const key = `label-weight-${label.id}`;
    const className = label.error ?
      'table-cell weight error' : 'table-cell weight error';
    const value = label.weight;
    const placeholder = 'weight in kg';
    const onChange = event =>
      dispatch(updateLabelRow({ ...label, weight: event.target.value }));
    const onKeyDown = catchReturn;
    const props = { key, className, value, placeholder, onChange, onKeyDown };
    cells.push(
      <input { ...props } />
    );
    const onPackDateChange = event =>
      dispatch(updateLabelRow({ ...label, packDate: event.target.value }));
    const packDateClass = label.error ?
      'table-cell pack-date error' : 'table-cell pack-date error';
    cells.push(
      <input key={ `label-pack-date-${label.id}` }
        className={ packDateClass }
        onKeyDown={ catchReturn }
        value={ label.packDate }
        placeholder="pack date"
        onChange={ onPackDateChange } />
    );
    const onUseByChange = event =>
      dispatch(updateLabelRow({ ...label, useBy: event.target.value }));
    const useByClass = label.error ?
      'table-cell use-by error' : 'table-cell use-by error';
    cells.push(
      <input key={ `label-use-by-${label.id}` }
        className={ useByClass }
        value={ label.useBy }
        onKeyDown={ catchReturn }
        placeholder="use by"
        onChange={ onUseByChange } />
    );
    const onFrozenUseChange = event =>
      dispatch(updateLabelRow({ ...label, frozenUse: event.target.value }));
    const frozenUseClass = label.error ?
      'table-cell frozen-use error' : 'table-cell frozen-use error';
    const handleKeyDown = event => {
      const key = event.which || event.keyCode || 0;
      if (key === 13) {
        dispatch(setLabelPage(pageCount));
        return addNew();
      }
      if (key === 9 && index === (currentPageOfLabels.length - 1)) {
        if (page === pageCount) { // last page
          return addNew();
        } else {
          dispatch(setLabelPage(page + 1));
        }
      }
    };
    cells.push(
      <input key={ `label-frozen-use-${label.id}` }
        className={ frozenUseClass }
        value={ label.frozenUse }
        placeholder="frozen use by"
        onKeyDown={ handleKeyDown }
        onChange={ onFrozenUseChange } />
    );
    cells.push(
      <div className="actions-row-cell" key={ `actions_${label.id}`}>
        <PlusIcon onClick={ () => dispatch(deleteLabelRow(label.id)) } />
        <CopyButton onClick={ () => dispatch(duplicateLabelRow(label)) }/>
      </div>
    );
  });
  const addNew = () => {
    const newLabel = labels.length ? { ...labels[labels.length - 1] } : {
      cut: null, weight: null, packDate: null, useBy: null, frozenUse: null
    };
    dispatch(addLabelRow({ ...newLabel, id: mongoid() }));
  };
  const addNewButton = (
    <div className="add-new-button"
      onClick={ addNew }>
      Add Label
      <PlusIcon />
    </div>
  );
  return (
    <section id="label-config-section">
      <div className="button-row top">{ addNewButton }</div>
      <div className="table">
        { cells }
      </div>
      <div className="button-row bottom">
        <ComplexLabelButton animal={ animal } farm={ farm }/>

        <Pagination toPage={ page => dispatch(setLabelPage(page)) }
          total={ pageCount }
          current={ page } />
        { addNewButton }
      </div>
    </section>
  );
};

LabelTable.propTypes = {
  animal: PropTypes.object.isRequired,
  farm: PropTypes.string.isRequired
};

export default LabelTable;
