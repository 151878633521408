import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import OrgListView from '../../orgAdmin/components/OrgListView';
import {
  getUnfilteredOrgCount,
  getOrgsObj,
  hasFetchedOrgs,
  isFetchingOrgs
} from '../../orgAdmin/reducer';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import LoadingPage from '../../../util/components/LoadingPage';
import BCMS from './BCMS';
import Agriwebb from './Agriwebb';
import { fetchOrgs } from '../../orgAdmin/actions';
import './integrations.scss';

const Integrations = ({ match }) => {
  const orgObject = useSelector(getOrgsObj);
  const orgCount = useSelector(getUnfilteredOrgCount);
  const orgsFetching = useSelector(isFetchingOrgs);
  const orgsFetched = useSelector(hasFetchedOrgs);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!orgsFetching && !orgsFetched) {
      dispatch(fetchOrgs());
    }
  }, []);
  if (orgsFetching && !orgsFetched) {
    return <LoadingPage />;
  }
  if (!match || !match.params.orgId) {
    if (orgCount === 1) {
      const id = Object.values(orgObject)[0].id;
      return <Redirect to={ `${match.url}/${id}`} />;
    }
    return <OrgListView match={ match } location="integration page"/>;
  }
  const organisationName = orgCount > 1 ? (
    <h2 className="org">{orgObject[match.params.orgId]?.name || ''}</h2>
  ) : null;
  const orgId = match.params.orgId;
  const integrationClass = match.url !== window.location.pathname ?
    'integration visible' : 'integration';
  return (
    <div id="integrations" data-cy="integrations-page">
      <h1 data-cy="integrations-title" className="integrations-title">
        Integrations
      </h1>
      { organisationName }
      <div className="integration-options">
        <Link to={ `${match.url}/bcms`}>
          <div className="integration-option bcms">
            BCMS
          </div>
        </Link>
        <Link to={ `${match.url}/agriwebb`}>
          <div className="integration-option agriwebb">
            Agriwebb
          </div>
        </Link>
      </div>
      <div className={ integrationClass }>
        <div className="integrations-triangle"></div>
        <Switch>
          <Route path={ `${match.url}/bcms`} render={
            props => <BCMS { ...props } orgId={ orgId } /> } />
          <Route path={ `${match.url}/agriwebb`} render={
            props => <Agriwebb { ...props } orgId={ orgId } /> } />
        </Switch>
      </div>
    </div>
  );
};

Integrations.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string
    }),
    url: PropTypes.string
  })
};

export default Integrations;
