/* eslint-disable max-len */
import React from 'react';
// import GBIButton from '../../../util/buttons/components/GBIButton';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';

const csvStr =
  `Cuts of Meat,Waitrose,Tesco (finest),Sainsburys (TtD),Warrens,HG Walter,Pipers Farm,Riverford,FarmDrop,Farmison & Co,National Retail (AHDB),Total Average,Retail Average,Boxed Beef Average,Warson,kg per carcass
Fillet,43.98,29.42,36.00,49.99,57.75,61.00,56.49,58.00,98.83,28.61,52.01,39.85,66.41,47.06,7
Sirloin,30.00,21.00,17.78,26.99,30.26,38.00,34.87,36.00,47.38,16.05,29.83,23.94,37.30,30.00,18
Rib-eye,25.00,24.50,26.67,31.49,45.00,39.75,34.87,24.00,42.95,16.05,31.03,26.92,37.31,28.98,16
Rump,20.00,16.00,13.33,24.06,26.00,32.50,29.21,28.00,33.00,13.00,23.51,18.35,29.74,20.00,14
Lean Mince,8.75,5.18,5.20,7.50,14.50,14.20,14.25,14.00,13.00,5.18,10.18,6.66,13.99,8.00,52
Burger,9.37,6.61,6.62,10.29,25.20,11.90,14.75,14.70,18.25,5.18,12.29,8.22,16.96,9,0
Topside,14.99,13.50,6.50,13.99,18.50,21.00,18.70,15.00,21.73,9.86,15.38,12.25,18.99,10.67,39
Diced,8.75,12.50,8.00,13.50,14.20,13.44,16.00,15.50,10.50,7.87,12.03,10.69,13.93,12.00,30
Brisket,12.98,9.00,8.50,11.49,14.19,14.95,10.53,15.00,15.93,9.86,12.24,10.49,14.12,10.00,35`;

const ExampleButton = () => {
  const handleClick = () => {
    const file = new window.Blob([csvStr], { type: 'csv' });
    const el = document.createElement('a');
    const url = URL.createObjectURL(file);
    el.href = url;
    el.download = 'example_price_check.csv';
    document.body.appendChild(el);
    el.click();
    setTimeout(() => {
      document.body.removeChild(el);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
  return (
    <div className="example-button"
      data-cy="example-pricing-csv-button"
      onClick={ handleClick }>
      <span className="label">View Example</span>
      <DownloadIcon />
    </div>
  );
};

export default ExampleButton;
