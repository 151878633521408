export const vidHasChanged = (vid1, vid2) => {
  if (!!vid1 !== !!vid2) return true;
  if (vid1) {
    if (vid1.title !== vid2.title) return true;
    if (vid1.id !== vid2.id) return true;
    if (vid1.tags && !vid2.tags) return true;
    if (vid2.tags && !vid1.tags) return true;
    if (Array.isArray(vid1.tags) !== Array.isArray(vid2.tags)) return true;
    if (Array.isArray(vid1.tags)) {
      if (vid1.tags.length !== vid2.tags.length) return true;
      for (let i = 0; i < vid1.tags.length; i++) {
        let tag = vid1.tags[i];
        if (!vid2.tags.includes(tag)) return true;
      }
    }
    if (!!vid1.videoData !== !!vid2.videoData) return true;
    if (vid1.videoData) {
      if (vid1.videoData.base !== vid2.videoData.base) return true;
    }
    if (!vid1.permissions !== !vid2.permissions) return true;
    if (vid1.permissions) {
      if (vid1.permissions.length !==
        vid2.permissions.length) {
        return true;
      }
      for (let i = 0; i < vid1.permissions.length; i++) {
        if (!vid2.permissions.includes(vid1.permissions[i])) {
          return true;
        }
      }
    }
  }
  return false;
};

export const permissionNames = {
  'public': 'Public',
  'admin': 'Administrator',
  'canViewFarmPages': 'Farmer Page',
  'ultrasound': 'Ultrasound'
};

export const availableVideoPerms = [
  'public', 'admin', 'canViewFarmPages', 'ultrasound'
];
