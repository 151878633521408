import React, { Fragment, useEffect } from 'react';
import './help-pages.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSavedHelpVids,
  getAllVideoTags
} from '../reducer';
import { fetchHelpVids, setHelpVideoView } from '../actions';
import HelpPageVideo from './HelpPageVideo';
import SwitchViewsButton from './SwitchViewsButton';

const HelpPage = () => {
  const videos = useSelector(getSavedHelpVids);
  const categories = useSelector(getAllVideoTags);
  const sections = categories.map(category => {
    const sectionVideos = videos
      .filter(vid => vid.tags && vid.tags.includes(category))
      .map(vid => <HelpPageVideo key={ vid.id} vid={ vid } />);
    return (
      <Fragment key={ category }>
        <h4 data-cy={ `${category}-category-header`}>{ category }</h4>
        <ul>
          { sectionVideos }
        </ul>
      </Fragment>
    );
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (videos.length < 1) {
      dispatch(fetchHelpVids());
    }
    dispatch(setHelpVideoView(false));
  }, []);
  const comeBackSoon = videos.length < 1 ? (
    <p className="come-back-soon-notice">
      { `We'll be uploading help videos over the next few days and weeks.
        Check back soon to find out more.`}
    </p>
  ) : null;
  return (
    <section id="help-pages">
      <h2 data-cy="user-help-menu-title">Help Topics</h2>
      <SwitchViewsButton />
      { sections }
      { comeBackSoon }
    </section>
  );
};

export default HelpPage;
