import React from 'react';
import ImageUpload from './ImageUpload';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import './images.scss';
import ImageRow from './ImageRow';
import ExpandableFormSection from '../../util/components/ExpandableFormSection';

const Images = ({
  images, addImage, deleteImage, ownerId, maxImages, moveImage, expandable
}) => {
  let existingDocuments = null;
  const shouldCancelStart = event => {
    let el = event.target;
    if (el.classList.contains('bb-plus-icon')) return true;
    while (el.parentElement) {
      el = el.parentElement;
      if (el.classList.contains('bb-plus-icon')) return true;
    }
    return false;
  };
  try {
    const SortableImage = SortableElement(({ imageData }) => {
      const src = imageData.thumb660 ? imageData.thumb660 : imageData.base;
      const position = imageData.index === 0 ?
        'primary image' : `position: ${imageData.index + 1}`;
      const fileName = imageData.fileName;
      const deleteImageFunc = () => {
        deleteImage(imageData);
      };
      return (
        <ImageRow position={ position }
          key={ imageData.base }
          src={ src }
          fileName={ fileName }
          deleteImage={ deleteImageFunc } />
      );
    });
    const SortableImageList = SortableContainer(({ images }) => {
      const items = images.map((imageData, index) => {
        return (
          <SortableImage key={ `item-${imageData.base}`}
            index={ index }
            imageData={ { ...imageData, index: index } } />
        );
      });
      return (
        <div className="sortable-image-list">
          { items }
        </div>
      );
    });
    if (images.length > 0) {
      existingDocuments = (
        <SortableImageList images={ images }
          shouldCancelStart={ shouldCancelStart }
          helperClass='dragging'
          onSortEnd={ e => moveImage(e.oldIndex, e.newIndex) } />
      );
    }
  } catch (error) { /* */ }
  const addNew = typeof maxImages === 'number' && images.length >= maxImages ?
    null : (
      <ImageUpload
        ownerId={ ownerId }
        uploaded={ addImage } />
    );
  const title = images.length > 1 ? 'Images' : 'Image';
  if (expandable) {
    return (
      <ExpandableFormSection
        title={ title }>
        <div className="editable-image-array">
          { existingDocuments }
          { addNew }
        </div>
      </ExpandableFormSection>
    );
  }
  return (
    <div className="editable-image-array">
      <h4>{ title }</h4>
      { existingDocuments }
      { addNew }
    </div>
  );
};

Images.propTypes = {
  images: PropTypes.array.isRequired,
  addImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  ownerId: PropTypes.string.isRequired,
  maxImages: PropTypes.number,
  moveImage: PropTypes.func,
  expandable: PropTypes.bool
};

export default Images;
