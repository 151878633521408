/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './user-list-view.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, setUserPage } from '../actions';
import {
  getPageOfUsers,
  hasFetchedUsers,
  getUserPage,
  getUserPageSize,
  getUserCount
} from '../reducer';
import UserListViewHeader from './UserListViewHeader';
import PropTypes from 'prop-types';
import UserListViewItem from './UserListViewItem';
import { getOrgsObj, hasFetchedOrgs } from '../../orgAdmin/reducer';
import { fetchOrgs } from '../../orgAdmin/actions';
import Pagination from '../../../util/tables/Pagination';
import AdminSearchBox from '../../adminListSearch/components/AdminSearchBox';

const filterAttributes = [
  { name: 'Name', attribute: 'name', dataType: 'string' },
  { name: 'Email', attribute: 'email', dataType: 'string' },
  { name: 'Organisation', attribute: 'orgs', dataType: 'string-array' }
];

const UserListView = ({ match, history }) => {
  const dispatch = useDispatch();
  const users = useSelector(getPageOfUsers);
  const hasFetched = useSelector(hasFetchedUsers);
  const orgsFetched = useSelector(hasFetchedOrgs);
  const page = useSelector(getUserPage);
  const pageSize = useSelector(getUserPageSize);
  const count = useSelector(getUserCount);
  const orgs = useSelector(getOrgsObj);
  useEffect(() => {
    if (!users || !hasFetched || users.length < 1) {
      dispatch(fetchUsers());
    }
    if (!orgs || !orgsFetched) {
      dispatch(fetchOrgs());
    }
  }, []);
  const userListItems = users.map(user => (
    <UserListViewItem key={ user.id } user={user} match={match} />
  ));
  return (
    <div id="admin-user-list" className="bb-page-wrapper users">
      <h2 className="caption">Registered Users</h2>
      <Link className="new-user-button" to={ `${match.url}/new`}>
        <span className="text">Add new</span>
        <span className="plus-icon">+</span>
      </Link>
      <AdminSearchBox attributes={ filterAttributes }/>
      <UserListViewHeader />
      { userListItems }
      <Pagination
        toPage={ page => dispatch(setUserPage(page)) }
        total={ Math.ceil(count / pageSize) }
        current={ page } />
    </div>
  );
};

UserListView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default UserListView;
