/* global Image */
import React, { useState, useRef, Fragment } from 'react';
import AdminAPI from '../../AdminAPI';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import PlusIcon from '../../../util/icons/components/PlusIcon';
import './org-logo.scss';
import { captureException } from '../../../util/logger';

const minWidth = 100;
const maxWidth = 600;
const minHeight = 108;
const maxHeight = 120;

const OrgLogo = ({ ownerId, uploaded, label, current }) => {
  const fileInputRef = useRef();
  const elementRef = useRef(uuid());
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [progress, setProgress] = useState(0);
  const onProgress = event => {
    if (event.lengthComputable) {
      const progress = event.loaded / event.total / 1.5;
      setProgress(progress);
    }
  };
  const onChange = event => {
    const file = event.target.files[0];
    if (!file) return;
    const name = file.name;
    setName(name);
    const image = new Image();
    image.onload = function () {
      if (this.width < minWidth) {
        setError(`Image must be at least ${minWidth}px wide`);
      } else if (this.width > maxWidth) {
        setError(`Image must be no more than ${maxWidth}px wide`);
      } else if (this.height < minHeight) {
        setError(`Image must be at least ${minHeight}px high`);
      } else if (this.height > maxHeight) {
        setError(`Image must be no more than ${maxHeight}px high`);
      } else {
        AdminAPI.saveFile(
          ownerId, name, file, onProgress
        ).then(response => {
          const data = response.data;
          setProgress(1);
          uploaded(`https://${data.bucket}/${data.name}`);
        }).catch(error => captureException(error));
      }
    };
    image.src = window.URL.createObjectURL(file);
  };
  const reset = () => {
    setError(null);
    setName(null);
    fileInputRef.current.value = '';
  };
  const style = {
    // eslint-disable-next-line max-len
    background: `linear-gradient(90deg, #eaebec ${progress * 100}%, #f4f4f4 ${(1 - progress) * 100}%)`
  };
  const content = name && progress !== 1 ? (
    <div className="uploading-file"
      style={ style }>
      { name }
    </div>
  ) : (
    <Fragment>
      <span className="add-text">
        { label || 'Upload new logo' }
      </span>
      <PlusIcon />
    </Fragment>
  );
  const errorText = error ? (
    <div className="error">
      <span>{ error }</span>
      <div className="close-error" onClick={ reset }>x</div>
    </div>
  ) : null;
  const existingImage = current ?
    <img src={ current } className="current-image" data-cy="current-image"/> :
    null;
  let className = 'logo-upload-widget form-field-wrapper';
  if (current) className += ' has-logo';
  return (
    <div className={ className }
      data-cy="logo-upload-widget">
      <label htmlFor={ elementRef.current }>{ content }</label>
      <input
        id={ elementRef.current }
        ref={fileInputRef}
        className="file-input"
        data-cy='logo-upload-input'
        type="file"
        multiple
        onChange={onChange} />
      { errorText }
      { existingImage }
    </div>
  );
};

OrgLogo.propTypes = {
  ownerId: PropTypes.string.isRequired,
  uploaded: PropTypes.func.isRequired,
  label: PropTypes.string,
  current: PropTypes.string
};

export default OrgLogo;
