import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './org-list-view.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrgs, setOrgPage } from '../actions';
import {
  getPageOfOrgs,
  hasFetchedOrgs,
  getOrgCount,
  getOrgPageSize,
  getOrgPage
} from '../reducer';
import OrgListViewHeader from './OrgListViewHeader';
import PropTypes from 'prop-types';
import OrgListViewItem from './OrgListViewItem';
import AdminSearchBox from '../../adminListSearch/components/AdminSearchBox';
import Pagination from '../../../util/tables/Pagination';

const filterAttributes = [
  { name: 'Name', attribute: 'name', dataType: 'string' },
  { name: 'Address', attribute: 'addressString', dataType: 'string' }
];

const OrgListView = ({ match, location }) => {
  const dispatch = useDispatch();
  const orgs = useSelector(getPageOfOrgs);
  const orgCount = useSelector(getOrgCount);
  const pageSize = useSelector(getOrgPageSize);
  const page = useSelector(getOrgPage);
  const fetched = useSelector(hasFetchedOrgs);
  useEffect(() => {
    if (!orgs || !fetched || orgs.length < 1) {
      dispatch(fetchOrgs());
    }
  }, []);
  const orgListItems = orgs.map(org => (
    <OrgListViewItem key={ org.id } org={org} match={match} />
  ));
  const title = location === 'integration page' ?
    'Select a Farm or Organisation' : 'Organisations';
  const backButton = location === 'integration page' ? null : (
    <Link className="new-org-button"
      to={ `${match.url}/new`}>
      <span className="text" data-cy="new-org-button">Add new</span>
      <span className="plus-icon">+</span>
    </Link>
  );
  return (
    <div id="admin-org-list"
      data-cy="orgs-list-page"
      className="bb-page-wrapper orgs">
      <h2 className="caption">{ title }</h2>
      { backButton }
      <AdminSearchBox attributes={ filterAttributes } />
      <OrgListViewHeader />
      { orgListItems }
      <Pagination
        toPage={ page => dispatch(setOrgPage(page)) }
        total={ Math.ceil(orgCount / pageSize)}
        current={ page } />
    </div>
  );
};

OrgListView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  location: PropTypes.oneOf([
    'org list', 'integration page'
  ])
};

export default OrgListView;
