import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './outlet-list-view.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutlets, setOutletPage } from '../actions';
import {
  getPageOfOutlets,
  hasFetchedOutlets,
  getOutletCount,
  getOutletPageSize,
  getOutletPage
} from '../reducer';
import OutletListViewHeader from './OutletListViewHeader';
import PropTypes from 'prop-types';
import OutletListViewItem from './OutletListViewItem';
import AdminSearchBox from '../../adminListSearch/components/AdminSearchBox';
import Pagination from '../../../util/tables/Pagination';

const filterAttributes = [
  { name: 'Name', attribute: 'name', dataType: 'string' },
  { name: 'Address', attribute: 'addressString', dataType: 'string' }
  // { name: 'Outlet', attribute: 'outlets', dataType: 'string-array' }
];

const OutletListView = ({ match, history }) => {
  const dispatch = useDispatch();
  const outlets = useSelector(getPageOfOutlets);
  const outletCount = useSelector(getOutletCount);
  const pageSize = useSelector(getOutletPageSize);
  const page = useSelector(getOutletPage);
  const fetched = useSelector(hasFetchedOutlets);
  useEffect(() => {
    if (!outlets || !fetched || outlets.length < 1) {
      dispatch(fetchOutlets());
    }
  }, []);
  const outletListItems = outlets.map(outlet => (
    <OutletListViewItem key={ outlet.id } outlet={outlet} match={match} />
    // <div key={ outlet.id }>{ outlet.name }</div>
  ));
  return (
    <div id="admin-outlet-list"
      data-cy="outlets-list-page"
      className="bb-page-wrapper outlets">
      <h2 data-cy="sales-outlet-title" className="caption">Sales Outlets</h2>
      <Link className="new-outlet-button" to={ `${match.url}/new`}>
        <span data-cy="add-new-button" className="text">Add new</span>
        <span className="plus-icon">+</span>
      </Link>
      <AdminSearchBox attributes={ filterAttributes } />
      <OutletListViewHeader />
      { outletListItems }
      <Pagination
        toPage={ page => dispatch(setOutletPage(page)) }
        total={ Math.ceil(outletCount / pageSize)}
        current={ page } />
    </div>
  );
};

OutletListView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default OutletListView;
