import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ShortArrow from '../../../util/icons/components/ShortArrow';
import { isAdmin, isAuthenticated } from '../../../util/jwt';
import './switch-view-button.scss';
import { useSelector } from 'react-redux';
import { getHelpVideoView } from '../reducer';

const SwitchViewsButton = () => {
  let admin = isAdmin() && isAuthenticated();
  const [ authorised, setAuthorised ] = useState(admin);
  const viewIsAdmin = useSelector(getHelpVideoView);
  if (!authorised) return null;
  const href = viewIsAdmin ? '/account/help' : '/account/help-video-admin';
  const label = viewIsAdmin ? 'exit admin view' : 'admin view';
  useEffect(() => {
    let admin = isAdmin() && isAuthenticated();
    setAuthorised(admin);
  });
  return (
    <div className="switch-view-button-wrapper">
      <Link to={ href } >
        <div className="button" data-cy="switch-view-button">
          { label } <ShortArrow />
        </div>
      </Link>
    </div>
  );
};

export default SwitchViewsButton;
