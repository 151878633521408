import {
  INTEGRATION_SYNC_BCMS,
  INTEGRATION_SYNC_AGRIWEBB
} from './constants';

export const syncBCMS = org => ({
  type: INTEGRATION_SYNC_BCMS, org
});

export const syncAgriwebb = org => ({
  type: INTEGRATION_SYNC_AGRIWEBB, org
});
