import parsePriceCheckPrice from './parsePriceCheckPrice';
import parseRetailerName from './parseRetailerName';
import parseCutName from './parseCutName';
import parseCarcassBreakdown from './parseCarcassBreakdown';

const priceCheckFromCSVData = (data, id, date, farmGatePrice) => {
  const retailers = [];
  for (let retailer = 1; retailer < data[0].length - 1; retailer++) { // length - 1 because last column is breakdown
    const name = parseRetailerName(data[0][retailer], retailer);
    const cuts = [];
    for (let cut = 1; cut < data.length; cut++) {
      cuts.push({
        name: parseCutName(data[cut][0], cut, cut === data.length - 1),
        price: parsePriceCheckPrice(data[cut][retailer], data[cut][0], name)
      });
    }
    retailers.push({
      name, cuts
    });
  }
  const breakdown = parseCarcassBreakdown(data);
  const priceCheck = {
    retailers, id, date, breakdown, farmGatePrice
  };
  return priceCheck;
};

export default priceCheckFromCSVData;
