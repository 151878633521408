import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './score-list-view-item.scss';

const ScoreListViewItem = ({ score, match }) => {
  const nameLink = score.name && score.id ? (
    <Link to={ `${match.url}/${score.id}`}>
      <h3 className="name">{ score.name }</h3>
    </Link>
  ) : null;
  const editButton = score.name && score.id ? (
    <Link to={ `${match.url}/${score.id}`}
      className="edit-link">
      Edit
    </Link>
  ) : null;
  const farmLevel = score.farmLevel ? (
    <div className="farm-level-wrapper">
      <div className="farm-level-icon"></div>
      <div className="farm-level-title">Farm Level</div>
    </div>
  ) : null;
  return (
    <div className="score-list-item" data-cy="score-list-view-item">
      <div className="block name-block">
        { nameLink }
        <h5 className="significance">{ score.significance }</h5>
      </div>
      <div className="block element-block">
        { score.category }
        { farmLevel }
      </div>
      <div className="block edit-button-block">
        { editButton }
      </div>
    </div>
  );
};

ScoreListViewItem.propTypes = {
  score: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default ScoreListViewItem;
