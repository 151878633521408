import React, { Fragment, useEffect, useState } from 'react';
import TextField from '../../../form/TextField';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgsObj, getOrgUnderEdit } from '../../orgAdmin/reducer';
import { fetchOrg, setEditingOrg } from '../../orgAdmin/actions';
import GBIButton from '../../../util/buttons/components/GBIButton';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import useInterval from '../../../util/hooks/useInterval';
import { syncBCMS } from '../actions';
import { timeFromNow } from '../../../util/datesAndAges';
import TopLevelFormError from '../../../util/components/TopLevelFormError';
import { getBCMSCredentialErrors } from '../util';
import ToolTip from '../../../util/tooltips/components/ToolTip';

const BCMS = ({ orgId }) => {
  const [ requestingSync, setRequestingSync ] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const orgsObj = useSelector(getOrgsObj);
  const org = useSelector(getOrgUnderEdit);
  const bcmsaccount = org && org.bcmsaccount ? org.bcmsaccount : {};
  const dispatch = useDispatch();
  const errors = showErrors ? getBCMSCredentialErrors(bcmsaccount) : {};
  const hasErrors = !!Object.keys(getBCMSCredentialErrors(bcmsaccount)).length;
  useEffect(() => {
    return () => dispatch(setEditingOrg(null));
  }, []);
  useEffect(() => {
    if (orgsObj[orgId] && (!org || org.id !== orgId)) {
      dispatch(setEditingOrg(orgsObj[orgId]));
    }
    if (org && org.bcmsaccount && org.bcmsaccount.currentlySyncing) {
      setRequestingSync(false);
    }
  }, [orgsObj, org]);
  useInterval(() => {
    if (bcmsaccount.currentlySyncing || requestingSync) {
      dispatch(fetchOrg(orgId));
    }
  }, 6 * 1000, [bcmsaccount, requestingSync]); // poll the org endpoint
  const updateField = (field, value) => {
    const newOrg = JSON.parse(JSON.stringify(org || {}));
    const bcmsData = JSON.parse(JSON.stringify(bcmsaccount));
    bcmsData[field] = value;
    newOrg.bcmsaccount = bcmsData;

    dispatch(setEditingOrg(newOrg));
  };
  const handleSyncClicked = () => {
    setShowErrors(true);
    if (!requestingSync && !hasErrors) {
      dispatch(syncBCMS(org));
      setRequestingSync(true);
    }
  };
  if (bcmsaccount.currentlySyncing) {
    return (
      <div className="integration-content" data-cy="sync-in-progress">
        <h1>British Cattle Movement Service</h1>
        <h4>{` We're currently syncing your account...`}</h4>
        <p>{ `Task ID: ${bcmsaccount.currentlySyncing} `}</p>
        <LoadingIcon />
      </div>
    );
  }
  const lastSync = bcmsaccount.lastSync && !bcmsaccount.error ? (
    <div className="last-sync" data-cy="last-sync">
      <span className="label">Last sync: </span>
      <span className="date">{ timeFromNow(bcmsaccount.lastSync) }</span>
    </div>
  ) : null;
  const lastResult = bcmsaccount.lastResult ? (
    <div className="last-sync" data-cy="result">
      <span className="label">Sync result: </span>
      <span className="text">{ bcmsaccount.lastResult }</span>
    </div>
  ) : null;
  const buttonClass = requestingSync || (showErrors && hasErrors) ?
    'disabled' : null;
  const bcmsError = bcmsaccount.error ? (
    <Fragment>
      <TopLevelFormError testId="sync-failed-warning"
        message={`Sync failed ${timeFromNow(bcmsaccount.lastSync) || ''}`}/>
      <div className="third-party-error" data-cy="bcms-error">
        <span className="error-label">Error response from BCMS: </span>
        <span className="error-text">{ bcmsaccount.error }</span>
      </div>
    </Fragment>
  ) : null;
  return (
    <div className="integration-content">
      <h2 className="integration-title">
        British Cattle Movement Service
        <ToolTip className="wide">
          <p>The GoodBeef Index BCMS sync service will download all
          cattle on your holding and either save them in your
          GoodBeef Index account. If the animal exists in your account
          it the service will updated the animal (if necassary), otherwise
          a new animal will be created.</p>
          <p>Your username should be 9 numbers in the format 123-456-789.
          The location shouuld be 3 groups of numbers seperated by a
          { ` "/"`} symbol. Your password is likely 7 characters long.
          If you { `don't` } have these credentials you can contact the
          British Cattle Movement Service on 0345 050 1234.</p>
        </ToolTip>
      </h2>
      { lastSync }
      { lastResult }
      { bcmsError }
      <div className="bcms-fields fields">
        <TextField name="CTS username"
          value={ bcmsaccount.ctsoluser }
          error={ errors.ctsoluser }
          testId="username"
          update={ val => updateField('ctsoluser', val) } />
        <TextField name="CTS password"
          value={ bcmsaccount.pwd }
          error={ errors.pwd }
          testId="pwd"
          update={ val => updateField('pwd', val) } />
        <TextField name="Location"
          value={ bcmsaccount.location }
          error={ errors.location }
          testId="location"
          update={ val => updateField('location', val) } />
        <TextField name="Sub Location"
          value={ bcmsaccount.sublocation }
          error={ null }
          testId="sublocation"
          update={ val => updateField('sublocation', val) } />
      </div>
      <div className="buttons">
        <GBIButton label="Download BCMS Cattle"
          onClick={ handleSyncClicked }
          testId="sync_bcms_button"
          className={ buttonClass } />
      </div>
    </div>
  );
};

BCMS.propTypes = {
  orgId: PropTypes.string.isRequired
};

export default BCMS;
