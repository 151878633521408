import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminAnimalsObject,
  getAnimalsFetching,
  isFetching
} from '../../reducer';
import { fetchAnimal } from '../../actions';
import PropTypes from 'prop-types';
import NotFound from '../../../util/components/NotFound';
import LoadingPage from '../../../util/components/LoadingPage';
import SmallLabelsButton from './SmallLabelsButton';
import QRCode from 'qrcode.react';
import './labels.scss';
import {
  getOrgsObj,
  isFetchingOrgs
} from '../../orgAdmin/reducer';
import { fetchOrgs } from '../../orgAdmin/actions';
import SimplePackingLabelsButton from './SimplePackingLabelsButton';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import { Link } from 'react-router-dom';
import LabelTable from './LabelTable';
import { addLabelRow, deleteLabelRows } from '../actions';
import { mongoid } from 'mongoid-js';
import { isPrinting } from '../reducer';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import ToolTip from '../../../util/tooltips/components/ToolTip';
import { getGBIDomain } from '../../../util/site';

const Labels = ({ match }) => {
  const [ showingLabelTable, setShowingLabelTable ] = useState(false);
  const dispatch = useDispatch();
  const animals = useSelector(getAdminAnimalsObject);
  const fetchingAll = useSelector(isFetching);
  const specificFetches = useSelector(getAnimalsFetching);
  const orgs = useSelector(getOrgsObj);
  const fetchingOrgs = useSelector(isFetchingOrgs);
  const printing = useSelector(isPrinting);
  let fetchingAnimal = fetchingAll || specificFetches[match.params.animalId];
  const [ animalFetchAttempted, setAnimalFetchAttempted ] = useState(false);
  const [ orgFetchAttempted, setOrgFetchAttempted ] = useState(false);
  const animal = animals[match.params.animalId];
  if (!animal && !fetchingAnimal) {
    if (animalFetchAttempted) {
      return (
        <NotFound message="check the animal ID in the URL" />
      );
    }
    dispatch(fetchAnimal(match.params.animalId));
    setAnimalFetchAttempted(true);
  }
  if (!orgs && !fetchingOrgs) {
    if (orgFetchAttempted) {
      return (
        <NotFound message="could not find the farm for this animal" />
      );
    }
    dispatch(fetchOrgs());
    setOrgFetchAttempted(true);
  }
  if (!animal) {
    return <LoadingPage />;
  }
  const org = orgs[animal.orgId];
  const { host } = new URL(getGBIDomain());
  const protocol = window.location.protocol;
  const url = `${protocol}//${host}/animal/${animal.id}`;
  const toggleTableVisible = () => {
    if (showingLabelTable) {
      dispatch(deleteLabelRows());
      setShowingLabelTable(false);
    } else {
      const label = {
        cut: null, weight: null, packDate: null, useBy: null, frozenUse: null
      };
      dispatch(addLabelRow({ ...label, id: mongoid() }));
      setShowingLabelTable(true);
    }
  };
  let smallLabelButton = null;
  let simplePackagingButton = null;
  let bespokeLabelButton = null;
  let helpText = 'To print packaging labels that include the farm name, ';
  helpText += 'grade, regulatory beef packaging info, (and possibly cut, ';
  helpText += 'weight and best before dates), make sure the animal has a ';
  helpText += 'name, GoodBeef Index score, and beef processing info.';
  if (animal && animal.tag && animal.saved) {
    smallLabelButton = <SmallLabelsButton animal={ animal } />;
    const beef = animal.beefProcessing;
    if (beef && beef.slaughterHouseID && beef.cuttingPlantID) {
      helpText = 'Print labels for use on packaging';
      simplePackagingButton = (
        <SimplePackingLabelsButton animal={animal}
          farm={ org ? org.name : null } />
      );
      bespokeLabelButton = (
        <div className="button build-labels-button"
          onClick={ toggleTableVisible }>
          { showingLabelTable ? 'Clear config' : 'Configure bespoke labels' }
          { showingLabelTable ? null : <ChevronIcon /> }
        </div>
      );
    }
  }
  const labelTable = showingLabelTable ?
    <LabelTable animal={ animal} farm={ org ? org.name : '' } /> :
    null;
  return (
    <>
      <div id="label-page-blanket" className={ printing ? 'showing' : ''}>
        <LoadingIcon />
      </div>
      <div id="label-page" data-cy="admin-label-page">
        <Link className="back-to-animals-link"
          data-cy="print-labels-link"
          to={ `/account/animals/${animal.id}` }>
          <ChevronIcon />
          Back to animal
        </Link>
        <h1 className="top-title">
          print labels
          <ToolTip >{ helpText }</ToolTip>
        </h1>
        <h2 data-cy="label-page-title" className="page-title">
          { animal.name ? animal.name : animal.tag }
        </h2>
        <h5 className="org-name"> { org ? org.name : '' }</h5>
        <div className="buttons">
          { smallLabelButton }
          { simplePackagingButton }
        </div>
        { bespokeLabelButton }
        { labelTable }
        <div id="animal-qr-code-wrapper">
          <QRCode size={ 140 }
            value={ url }
            renderAs='svg'
            id="qr-code-svg"/>
          <QRCode size={ 140 }
            value={ url }
            renderAs='canvas'
            id="qr-code"/>
        </div>
      </div>
    </>
  );
};

Labels.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      animalId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  })
};

export default Labels;
