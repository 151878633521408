import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer';

// https://react-pdf.org/

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    paddingTop: '15mm',
    paddingLeft: '9.7mm',
    paddingRight: '0',
    // padding: '10px 3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0.2mm',
    // marginBottom: '1.1mm',
    // width: '63mm',
    // height: '37mm',
    // backgroundColor: '#eee',

    width: '64.2mm',
    height: '38.1mm',
    position: 'relative'
  },
  TextSection: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 300,
    justifyContent: 'space-between',
    // width: '100%',
    overFlow: 'hidden'
  },
  logo: {
    // height: 18,
    width: '60%',
    left: '20%',
    marginBottom: '5mm'
    // width: 20
  },
  tag: {
    marginBottom: 2,
    fontSize: 9,
    fontWeight: 300,
    left: '10%',
    textOverflow: 'ellipsis',
    width: '36mm'
  },
  name: {
    fontSize: 9,
    fontWeight: 300,
    left: '10%',
    textOverflow: 'ellipsis',
    width: '36mm'
  },
  findOutMore: {
    fontSize: 8,
    marginTop: '5mm',
    left: '10%'
    // textAlign: 'center'
  },
  qrCode: {
    height: '20mm',
    width: '20mm',
    marginLeft: '3mm'
  },
  gbiTick: {
    height: 20,
    width: 22,
    top: 10,
    bottom: 10,
    position: 'absolute'
  },
  qrWrapper: {
    position: 'relative'
  }
});

const AnimalQRCodeLabelsPDF = ({ tag, name, tickDataUri, logoDataUri }) => {
  const qrCodeWrapper = document.getElementById('animal-qr-code-wrapper');
  const qrCodeCanvas = qrCodeWrapper.querySelector('canvas');
  const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
  const nameComponent = name ? (
    <Text style={ styles.name }>{ name.substring(0, 19) }</Text>
  ) : null;
  const labels = [ ...Array(21) ].map((_, i) => (
    <View key={ i } style={ styles.label }>
      <View style={ styles.qrWrapper }>
        <Image style={ styles.qrCode } source={ { uri: qrCodeDataUri } } />
      </View>
      <View styles={ styles.textSection }>
        <Image
          source={ { uri: logoDataUri } }
          style={ styles.logo } />

        <Text style={ styles.tag }>{ tag }</Text>
        { nameComponent }

        <Text style={ styles.findOutMore }>Find out more</Text>

      </View>
    </View>
  ));
  return (
    <Document>
      <Page size='A4' style={ styles.page }>
        { labels }
      </Page>
    </Document>
  );
};

AnimalQRCodeLabelsPDF.propTypes = {
  tag: PropTypes.string.isRequired,
  name: PropTypes.string,
  tickDataUri: PropTypes.string,
  logoDataUri: PropTypes.string
};

export default AnimalQRCodeLabelsPDF;
