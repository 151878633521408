import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPricingSort } from '../actions';
import { getPricingsSortAttribute, getPricingsCount } from '../reducer';
import '../../components/admin-list-view-header.scss';

const sortOptions = {
  email: 'User',
  date: 'Upload Date'
};

const PricingListViewHeader = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const handleSortOptionClicked = sortOption => {
    dispatch(setPricingSort(sortOption));
  };
  const [ showingDropdown, setShowingDropdown ] = useState(false);
  const toggleVisibility = () => setShowingDropdown(!showingDropdown);
  const dropDownOptions = Object.keys(sortOptions).map(key => (
    <li key={ key }
      data-cy={ `pricing-sort-option-${key}` }
      onClick={() => handleSortOptionClicked(key)}>
      { sortOptions[key] }
    </li>
  ));
  const currentSort = useSelector(getPricingsSortAttribute);
  const pricingsCount = useSelector(getPricingsCount);
  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setShowingDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const optionsClass = showingDropdown ?
    'dropdown-options' : 'dropdown-options hidden';
  return (
    <div id='admin-search-header'>
      <div className='count'>
        <span className='number'>
          { pricingsCount.toLocaleString() }
        </span>
        Price Checks
      </div>
      <div ref={wrapperRef}
        className='sort'
        data-cy="pricing-sort-dropdown"
        onClick={ toggleVisibility }>
        { 'Sort by' }
        <p className='attribute'>{ sortOptions[currentSort] }</p>
        <ul className={ optionsClass }>
          { dropDownOptions }
        </ul>
      </div>
      <div className="triangle-wrapper">
        <div className='triangle'></div>
      </div>
    </div>
  );
};

export default PricingListViewHeader;
