import {
  LABELS_ADD_ROW,
  LABELS_DELETE_ROW,
  LABELS_UPDATE_ROW,
  LABELS_COPY_ROW,
  LABELS_DELETE_ALL,
  LABELS_PRINT_ALL,
  LABELS_FINISHED_PRINTING,
  LABELS_SET_PAGE
} from './constants';

export const addLabelRow = label => ({
  type: LABELS_ADD_ROW, label
});
export const deleteLabelRow = labelId => ({
  type: LABELS_DELETE_ROW, labelId
});
export const updateLabelRow = label => ({
  type: LABELS_UPDATE_ROW, label
});
export const duplicateLabelRow = label => ({
  type: LABELS_COPY_ROW, label
});
export const deleteLabelRows = () => ({
  type: LABELS_DELETE_ALL
});
export const printLabels = () => ({
  type: LABELS_PRINT_ALL
});
export const finishPrintingLabels = () => ({
  type: LABELS_FINISHED_PRINTING
});
export const setLabelPage = page => ({
  type: LABELS_SET_PAGE, page
});
