import React from 'react';
import PropTypes from 'prop-types';

const UploadResults = ({ results }) => {
  if (!results) return null;
  if (Object.keys(results).length === 0) return null;
  let title = 'Upload Process Results';
  if (results.animalsUpdated || results.newAnimals) {
    title += ' - Success';
  }
  if (results.errors && results.errors.length) {
    title += ' - Some Errors';
  }
  let failedUpdatesClass = 'headline-result';
  if (results.failedUpdates) failedUpdatesClass += ' error';
  const errors = results.errors && results.errors.length ? (
    <>
      <h6 className="error-title">error messages</h6>
      <ul className="error-list">
        {
          results.errors.map(err => (
            <li key={ err }>{ err }</li>
          ))
        }
      </ul>
    </>
  ) : null;
  const log = results.logMsgs && results.logMsgs.length ? (
    <>
      <h6 className="log-title">log messages</h6>
      <ul className="log-msg-list">
        {
          results.logMsgs.map(msg => (
            <li key={ msg }>{ msg }</li>
          ))
        }
      </ul>
    </>
  ) : null;
  return (
    <div className="upload-result" data-cy="upload-result">
      <h4 className="upload-title">{ title }</h4>
      <p className="headline-result">
        { `animals updated: ${results.animalsUpdated || 0}` }
      </p>
      <p className="headline-result">
        { `new animals created: ${results.newAnimals || 0}` }
      </p>
      <p className={failedUpdatesClass}>
        { `failed updates: ${results.failedUpdates || 0}` }
      </p>
      { log }
      { errors }
    </div>
  );
};

UploadResults.propTypes = {
  results: PropTypes.object
};

export default UploadResults;
