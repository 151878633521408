import React, { useState, useRef } from 'react';
import uuid from 'uuid/v4';
import Modal from '../../../main/components/Modal';
import GBIButton from '../../../util/buttons/components/GBIButton';
import Papa from 'papaparse';
import './upload-price-check.scss';
import priceCheckFromCSVData from '../util.js/priceCheckFromCSVData'; 
import mongoid from 'mongoid-js';
import { useDispatch } from 'react-redux';
import { uploadPricing } from '../actions';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import ExampleButton from './ExampleButton';
import { BASE_FARM_GATE_PRICE } from '../util.js/variables';

const UploadPriceCheck = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const elementRef = useRef(uuid());
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState('');
  const [ priceCheck, setPriceCheck ] = useState(null);
  const [showOutputModal, setShowOutputModal] = useState(false);
  const onChange = event => {
    const file = event.target.files[0];
    setErrors([]);
    setName(file.name);
    setShowOutputModal(true);
    Papa.parse(file, {
      complete: onFileParsed
    });
  };
  const onFileParsed = (results, file) => {
    // console.log('Parsing complete:', results, file);
    if (results && Array.isArray(results.errors) && results.errors.length) {
      setErrors([ ...errors, ...results.errors ]);
    } else {
      try {
        const priceCheck =
          priceCheckFromCSVData(results.data, mongoid(), new Date());
          priceCheck.farmGatePrice = BASE_FARM_GATE_PRICE * 100; // just use default
        setPriceCheck(priceCheck);
      } catch (error) {
        setErrors([ ...errors, { message: error.toString() } ]);
      }
    }
  }; 
  const closeModal = () => {
    setShowOutputModal(false);
    setPriceCheck(null);
    fileInputRef.current.value = null;
  };
  const savePriceCheck = () => {
    dispatch(uploadPricing(priceCheck));
    closeModal();
  };
  const errorElements = errors.map(error => (
    <div className="error" key={ error.message }>{ error.message }</div>
  ));
  const buttons = errorElements.length || !priceCheck ? null : (
    <div className="button-row">
      <GBIButton
        onClick={ closeModal }
        testId="close-modal-button"
        label="cancel"
        className="light" />
      <GBIButton onClick={ savePriceCheck }
        testId="save-button"
        label="save" />
    </div>
  );
  const parsing = !priceCheck && errorElements.length === 0 ?
    <LoadingIcon /> : null;
  const result = errorElements.length > 0 ?
    <h2 className="failed result">Invalid</h2>
    : <h2 className="success result">Valid</h2>;
  const modal = showOutputModal ? (
    <Modal close={ closeModal }
      contentClass="price-check-upload-modal">
      { result }
      <h1 className="main-title" data-cy="upload-modal-title">{ name }</h1>
      { buttons }
      { parsing }
      { errorElements.slice(0, 10) }
    </Modal>
  ) : null;
  return (
    <div className="upload-price-check-widget">
      <div className="button-row">
        <label htmlFor={ elementRef.current }>
          <GBIButton className="new-price-check-button"
            testId="upload-button"
            label="New Price Check +"/>
        </label>
        <ExampleButton />
      </div>
      <input
        id={ elementRef.current }
        ref={fileInputRef}
        className="file-input"
        type="file"
        multiple
        data-cy="price-check-upload-input"
        onChange={onChange} />
      { modal }
    </div>
  );
};

export default UploadPriceCheck;
