import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrgs } from '../../orgAdmin/actions';
import {
  getOrgsObj,
  hasFetchedOrgs,
  isFetchingOrgs
} from '../../orgAdmin/reducer';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UltrasoundIDIcon from '../../../util/icons/components/UltrasoundIDIcon';
import './user-org-list.scss';

const UserOrgs = ({ orgIds }) => {
  const dispatch = useDispatch();
  const fetchedOrgs = useSelector(hasFetchedOrgs);
  const fetchingOrgs = useSelector(isFetchingOrgs);
  const orgs = useSelector(getOrgsObj);
  useEffect(() => {
    if (orgIds && orgIds.length && !fetchingOrgs && !fetchedOrgs) {
      dispatch(fetchOrgs);
    }
  }, [orgIds, fetchingOrgs, fetchedOrgs]);
  if (!orgIds || orgIds.length === 0) return null;
  const rows = orgIds.map(id => {
    const org = orgs[id];
    if (org) {
      return (
        <Link to={ `/account/orgs/${id}`} key={ id }>
          <div className="org-row"
            data-cy={ `org-link-${id} `}>
            <UltrasoundIDIcon />
            <span className="org-name">{ org.name }</span>
          </div>
        </Link>
      );
    }
  });
  return (
    <div className="user-org-list" data-cy="user-orgs">
      { rows }
    </div>
  );
};

UserOrgs.propTypes = {
  orgIds: PropTypes.arrayOf(PropTypes.string)
};

export default UserOrgs;
