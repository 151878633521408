import parseCutName from './parseCutName';

/**
 * Takes csv data (array of arrays) and returns
 * an array of carcus cut percentages
 * [
 *  { name: 'rump': percentage: '40' },
 *  { name: 'sirloin', percentage: '30' }
 *  { name: 'mince', percengage: '30' }
 * ]
 */
const parseCarcassBreakdown = data => {
  if (data[0][data[0].length - 1] !== 'kg per carcass') {
    throw new Error('missing carcass breakdown header ' +
      '(which should have the title "kg per carcass")');
  }
  let breakdown = [];
  let totalWeight = 0;
  for (let i = 1; i < data.length; i++) {
    const name = parseCutName(data[i][0]);
    const val = data[i][data[0].length - 1];
    if (isNaN(val) || isNaN(parseFloat(val))) {
      throw new Error(`carcass breakdown weight for ${name} is not a number`);
    }
    totalWeight += parseFloat(val);
  }
  let percentageTotal = 0;
  for (let i = 1; i < data.length; i++) {
    const name = parseCutName(data[i][0]);
    let weight = parseFloat(data[i][data[0].length - 1]);
    // console.log(`weight is ${weight}, totalWeight is ${totalWeight}`);
    const percentageFloat = weight / totalWeight;
    const percentage = Math.round(percentageFloat * 100);
    const modulus = (percentageFloat * 100) % 1;
    percentageTotal += percentage;
    breakdown[i - 1] = { name, percentage, modulus, weight };
    // console.log(
    //   `pushed ${JSON.stringify(breakdown[i - 1])} to breakdown array`
    // );
  }
  // console.log(JSON.stringify(breakdown));
  let remainder = percentageTotal % 100;
  if (remainder !== 0) { // percentages don't add up to 100
    if (remainder === percentageTotal) { // we're short of points
      breakdown.sort((a, b) => {
        let aMod = a.modulus;
        if (aMod >= 0.5) aMod = aMod - 1;
        let bMod = b.modulus;
        if (bMod >= 0.5) bMod = bMod - 1;
        if (aMod > bMod) return -1;
        if (aMod === bMod) return 0;
        return 1;
      });
      // console.log(JSON.stringify(breakdown));
      let i = 0;
      // console.log(`adding percentage points. remainder is ${remainder}`);
      while (remainder < 100) {
        // console.log(`adding 1 to ${breakdown[i].name}`);
        breakdown[i].percentage += 1;
        remainder += 1;
        i += 1;
      }
    } else { // we've got too many points
      breakdown.sort((a, b) => {
        let aMod = a.modulus;
        if (aMod < 0.5) aMod += 1;
        let bMod = b.modulus;
        if (bMod < 0.5) bMod += 1;
        if (aMod < bMod) return -1;
        if (aMod === bMod) return 0;
        return 1;
      });
      let i = 0;
      // console.log(`removing percentage points. remainder is ${remainder}`);
      // console.log(JSON.stringify(breakdown));
      while (remainder > 0) {
        // console.log(
        //   `taking 1 from ${breakdown[i].name} (currently ${breakdown[i].percentage})`
        // );
        breakdown[i].percentage -= 1;
        remainder -= 1;
        i += 1;
      }
    }
  }
  return breakdown.map(obj => {
    // eslint-disable-next-line no-unused-vars
    const { modulus, ...newObj } = obj; // remove modulus
    return newObj;
  });
};

export default parseCarcassBreakdown;
