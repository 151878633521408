import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import CsvUploader from '../../admin/components/CsvUploader';
import AnimalListView from '../../admin/components/AnimalListView';
import './gbi-account.scss';
import Animal from '../../admin/components/Animal';
import ShortArrow from '../../util/icons/components/ShortArrow';
import PropTypes from 'prop-types';
import { sideMenuIsOpen } from '../../header/reducer';
import { setMobileMenuOpen, setSideMenuOpen } from '../../header/actions';
import GBIMyAccount from './GBIMyAccount';
import { isAdmin, getOrgIds, hasUltrasoundPerms } from '../../util/jwt';
import UserListView from '../../admin/userAdmin/components/UserListView';
import UserDetail from '../../admin/userAdmin/components/UserDetail';
import OrgDetail from '../../admin/orgAdmin/components/OrgDetail';
import OrgListView from '../../admin/orgAdmin/components/OrgListView';
import HelpVideoListView
  from '../../admin/helpVideos/components/HelpVideoListView';
import ScoreListView from
  '../../admin/scoreDefinitions/components/scoresListView';
import ScoreDetail from '../../admin/scoreDefinitions/components/ScoreDetail';
import { fetchOrgs } from '../../admin/orgAdmin/actions';
import OutletListView from '../../admin/outlets/components/OutletListView';
import OutletDetail from '../../admin/outlets/components/OutletDetail';
import PricingListView from '../../admin/pricing/components/PricingListView';
import Labels from '../../admin/labels/components/Labels';
import HelpVideoEdit from '../../admin/helpVideos/components/HelpVideoEdit';
import Integrations from '../../admin/integrations/components/Integrations';
import HelpPage from '../../admin/helpVideos/components/HelpPage';
import { getHelpVideoView } from '../../admin/helpVideos/reducer';
import UltrasoundListView from
  '../../admin/ultrasound/components/UltrasoundListView';
import { fetchUltrasoundScans } from '../../admin/ultrasound/actions';
import { getUltrasoundCount } from '../../admin/ultrasound/reducer';
import UltrasoundDetailView from
  '../../admin/ultrasound/components/UltrasoundDetailView';

const mapStateToProps = state => ({
  sideMenuOpen: sideMenuIsOpen(state),
  helpViewIsAdmin: getHelpVideoView(state),
  ultrasoundScanCount: getUltrasoundCount(state)
});

const mapDispatchToProps = dispatch => ({
  openSideMenu: () => dispatch(setMobileMenuOpen(true)),
  closeSideMenu: () => dispatch(setSideMenuOpen(false)),
  closeMobileMenu: () => dispatch(setMobileMenuOpen(false)),
  fetchOrgs: () => dispatch(fetchOrgs()),
  fetchUltrasound: () => dispatch(fetchUltrasoundScans())
});

class GBIAccountPage extends Component {
  constructor (props) {
    super(props);
    this.handleSelection = this.handleSelection.bind(this);
    this.showUltrasound = this.showUltrasound.bind(this);
    this.props.fetchOrgs();
    if (!hasUltrasoundPerms()) {
      this.props.fetchUltrasound();
    }
  }
  handleSelection () {
    this.props.closeMobileMenu();
    window.scrollTo({ top: 0 });
  }
  showUltrasound () {
    if (hasUltrasoundPerms() || this.props.ultrasoundScanCount) {
      return true;
    }
    return false;
  }
  renderUltrasoundMenuItem () {
    if (!this.showUltrasound()) return null;
    return (
      <li className='top-level'
        onClick={ this.handleSelection }>
        <NavLink
          className="account-menu-item"
          to={ `${this.props.match.url}/ultrasound`}
          activeClassName='active'>
          <div className="text" data-cy="scans-menu-item">
            Ultrasound
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    );
  }
  renderUsersMenuItem () {
    if (isAdmin()) {
      return (
        <li className='top-level'
          data-cy="users-menu-item"
          onClick={ this.handleSelection }>
          <NavLink
            className="account-menu-item"
            to={ `${this.props.match.url}/users`}
            activeClassName='active'>
            <div className="text">
              Users
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  }
  renderScoreDefinitionsMenuItem () {
    if (isAdmin()) {
      return (
        <li className='top-level'
          data-cy="scores-menu-item"
          onClick={ this.handleSelection }>
          <NavLink
            className="account-menu-item"
            to={ `${this.props.match.url}/score-definitions`}
            activeClassName='active'>
            <div className="text" data-cy="score-def-menu-option">
              Score Definitions
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  }
  renderOutletsMenuItem () {
    if (isAdmin()) {
      return (
        <li className='top-level'
          data-cy="outlet-menu-item"
          onClick={ this.handleSelection }>
          <NavLink
            className="account-menu-item"
            to={ `${this.props.match.url}/outlets`}
            activeClassName='active'>
            <div className="text" data-cy="outlet-menu-option">
              Sales Outlets
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  }
  renderPricesMenuItem () {
    if (isAdmin()) {
      return (
        <li className='top-level'
          data-cy="prices-menu-item"
          onClick={ this.handleSelection }>
          <NavLink
            className="account-menu-item"
            to={ `${this.props.match.url}/pricing`}
            activeClassName='active'>
            <div className="text">
              Pricing
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  }
  renderHelpMenuItem () {
    if (isAdmin() && this.props.helpViewIsAdmin) {
      return null;
    }
    return (
      <li className='top-level'
        data-cy="help-menu-item"
        onClick={ this.handleSelection }>
        <NavLink
          className="account-menu-item"
          to={ `${this.props.match.url}/help`}
          activeClassName='active'>
          <div className="text" data-cy="user-help-menu-item">
            Help
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    );
  }
  renderHelpVideoAdminMenuItem () {
    if (isAdmin() && this.props.helpViewIsAdmin) {
      return (
        <li className='top-level'
          data-cy="help-vide-admin-menu-item"
          onClick={ this.handleSelection }>
          <NavLink
            className="account-menu-item"
            to={ `${this.props.match.url}/help-video-admin`}
            activeClassName='active'>
            <div className="text" data-cy="admin-help-menu-item">
              Help Video Admin
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
      );
    }
    return null;
  }
  renderOrgMenuItem () {
    let url = `${this.props.match.url}/orgs`;
    let title = 'Your Organisation';
    if (isAdmin() || getOrgIds().length > 1) {
      title = 'Organisations';
    } else if (getOrgIds().length === 1) {
      url = `${this.props.match.url}/orgs/${getOrgIds()[0]}`;
    } else {
      return null;
    }
    return (
      <li className='top-level'
        data-cy="orgs-menu-item"
        onClick={ this.handleSelection }>
        <NavLink
          className="account-menu-item"
          to={ url }
          activeClassName='active'>
          <div className="text">
            { title }
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    );
  }
  renderLabelsMenuItem () {
    const baseURL = this.props.match.url;
    return (
      <Route exact
        path={`${baseURL}/animal/labels/:animalId`}
        render={
          props => (
            <li className='top-level'
              data-cy="orgs-menu-item"
              onClick={ this.handleSelection }>
              <NavLink
                className="account-menu-item"
                to={ `${baseURL}/animal/labels/${props.match.params.animalId}` }
                activeClassName='active'>
                <div className="text">
                  Labels
                  <div className="line"></div>
                </div>
              </NavLink>
            </li>
          )} />
    );
  }
  render () {
    let integrationsOrgId = '';
    if (!isAdmin()) {
      const orgIds = getOrgIds();
      if (orgIds.length === 1) {
        integrationsOrgId = `/${orgIds[0]}`;
      }
    }
    const className = this.props.sideMenuOpen ?
      'mobile-menu-visible' : null;
    return (
      <div id="account-page" className={ className }>
        <ul className="account-menu">
          <li className="header">
            <span className="top">Account</span>
            <span className="bottom">Admin</span>
          </li>
          <li className="back"
            data-cy="back-to-main-menu"
            onClick={ this.props.closeSideMenu }>
            <ShortArrow />
            <span className='text'>Main Menu</span>
          </li>
          <li className='top-level'
            data-cy="my-account-menu-item"
            onClick={ this.handleSelection }>
            <NavLink
              className="account-menu-item"
              to={ `${this.props.match.url}/my-account`}
              activeClassName='active'>
              <div className="text">
                Account Profile
                <div className="line"></div>
              </div>
            </NavLink>
          </li>
          <li className='top-level'
            data-cy="animals-menu-item"
            onClick={ this.handleSelection }>
            <NavLink
              className="account-menu-item"
              to={ `${this.props.match.url}/animals`}
              activeClassName='active'>
              <div className="text">
                { isAdmin() ? 'Animals' : 'Your Animals' }
                <div className="line"></div>
              </div>
            </NavLink>
          </li>
          { this.renderOrgMenuItem() }
          { this.renderUltrasoundMenuItem() }
          { this.renderUsersMenuItem() }
          { this.renderScoreDefinitionsMenuItem() }
          { this.renderOutletsMenuItem() }
          { this.renderPricesMenuItem() }
          { this.renderLabelsMenuItem() }
          { this.renderHelpMenuItem() }
          { this.renderHelpVideoAdminMenuItem() }
          <li className='top-level'
            onClick={ this.handleSelection }>
            <NavLink
              className="account-menu-item"
              to={ `${this.props.match.url}/integrations${integrationsOrgId}`}
              activeClassName='active'>
              <div className="text"
                data-cy="integrations-menu-item">
                Integrations
                <div className="line"></div>
              </div>
            </NavLink>
          </li>
        </ul>
        <div className="content">
          <Switch>
            <Route exact path={ this.props.match.url } render={
              () => <Redirect to={ `${this.props.match.url}/my-account` }
              /> } />
            <Route exact path={`${this.props.match.url}/animals`} render={
              props => <AnimalListView { ...props } /> } />
            <Route exact
              path={`${this.props.match.url}/animals/:animalId`}
              render={
                props => <Animal { ...props } /> } />
            <Route exact
              path={`${this.props.match.url}/animal/labels/:animalId`}
              render={
                props => <Labels { ...props } /> } />
            <Route path={`${this.props.match.url}/upload`} render={
              props => <CsvUploader { ...props } /> } />
            <Route path={`${this.props.match.url}/my-account`} render={
              props => <GBIMyAccount /> } />
            <Route exact
              path={`${this.props.match.url}/orgs`}
              render={
                props => <OrgListView { ...props } /> } />
            <Route path={`${this.props.match.url}/orgs/:orgId`} render={
              props => <OrgDetail { ...props} /> } />
            <Route path={`${this.props.match.url}/integrations/:orgId`} render={
              props => <Integrations { ...props} /> } />
            <Route path={`${this.props.match.url}/integrations`} render={
              props => <Integrations { ...props} /> } />
            <Route exact path={`${this.props.match.url}/users`} render={
              props => <UserListView { ...props } /> } />
            <Route exact path={`${this.props.match.url}/users/:userId`} render={
              props => <UserDetail { ...props } /> } />
            <Route exact path={`${this.props.match.url}/outlets`} render={
              props => <OutletListView { ...props } /> } />
            <Route path={`${this.props.match.url}/outlets/:outletId`} render={
              props => <OutletDetail { ...props } /> } />
            <Route exact path={`${this.props.match.url}/ultrasound`} render={
              props => <UltrasoundListView { ...props } /> } />
            <Route path={`${this.props.match.url}/ultrasound/:scanId`}
              render={ props => <UltrasoundDetailView { ...props } /> } />
            <Route exact
              path={`${this.props.match.url}/score-definitions`}
              render={props => <ScoreListView { ...props } /> }
            />
            <Route path={ `${this.props.match.url}/pricing`}
              render={ props => <PricingListView />} />
            <Route exact path={ `${this.props.match.url}/help-video-admin`}
              render={ props => <HelpVideoListView { ...props } /> } />
            <Route path={ `${this.props.match.url}/help-video-admin/:vidId`}
              render={ props => <HelpVideoEdit { ...props } /> } />
            <Route path={ `${this.props.match.url}/help`}
              render={ props => <HelpPage />} />
            <Route exact
              path={`${this.props.match.url}/score-definitions/:scoreId`}
              render={ props => <ScoreDetail { ...props } /> }
            />
          </Switch>
        </div>
      </div>
    );
  }
}

GBIAccountPage.propTypes = {
  match: PropTypes.object,
  openSideMenu: PropTypes.func,
  closeSideMenu: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
  sideMenuOpen: PropTypes.bool,
  fetchOrgs: PropTypes.func.isRequired,
  helpViewIsAdmin: PropTypes.bool.isRequired,
  fetchUltrasound: PropTypes.func.isRequired,
  ultrasoundScanCount: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(GBIAccountPage);
