import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberField from '../../form/NumberField';
import TextField from '../../form/TextField';
import TextAreaField from '../../form/TextAreaField';
import DropDownSelectField from '../../form/DropDownSelectField';
import {
  cowMaturity,
  getObjectAttribute,
  updateObjectAttribute
} from '../util';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnimalUnderEdit,
  getAnimalEditErrors } from '../reducer';
import { updateAnimalUnderEdit } from '../actions';
import DateField from '../../form/DateField';
import ExpandableFormSection from '../util/components/ExpandableFormSection';
import './livestock-fields.scss';

const LiveStockFields = ({ showErrors }) => {
  const dispatch = useDispatch();
  const animal = useSelector(getAnimalUnderEdit);
  const errors = useSelector(getAnimalEditErrors);
  const update = (param, value) => {
    const newAnimal = updateObjectAttribute(animal, param, value);
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const getAttribute = key => getObjectAttribute(animal, key);
  const getError = key => {
    if (showErrors && errors && errors[key]) return errors[key];
    return null;
  };
  if (!animal || (!animal.liveOnBeefBook && !animal.liveOnGBI)) {
    return null;
  }
  let isErrored = false;
  let maturity = null;
  if (getAttribute('type') === 'BreedingCows' ||
    getAttribute('type') === 'Heifers') {
    const currentMaturity = getAttribute('maturity');
    const mOptions = cowMaturity.map(opt => ({
      name: opt, value: opt, selected: opt === currentMaturity
    }));
    maturity = (
      <DropDownSelectField options={mOptions}
        name="maturity"
        testId="maturity-field"
        update={ value => update('maturity', value) } />
    );
  }
  const handleIMFUpdate = value => {
    const newAnimal = JSON.parse(JSON.stringify(animal)) || {};
    newAnimal.IMF = value;
    let score = 0;
    if (value >= 6) score = 4;
    else if (value >= 4) score = 3;
    else if (value >= 2) score = 2;
    else if (value >= 1) score = 1;
    const factor = 'flavour';
    const element = 'eating';
    try {
      newAnimal[element] = newAnimal[element] || {};
      newAnimal[element][factor] = newAnimal[element][factor] || {};
      newAnimal[element][factor].score = score;
    } catch (error) { /* */ }
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const dob = getAttribute('dob');
  const birthDate = dob ? new Date(dob) : undefined;
  const breedingAnimalFields = animal.type === 'FinishedCattle' ? null : (
    <Fragment>
      <NumberField name="EBV Terminal Index"
        testId="EBVTI"
        value={ getAttribute('EBV.TIscore') }
        error={ getError('EBVTIScore') }
        update={ value => update('EBV.TIscore', value) } >
      Estimate Breed Value  - Terminal Index.
      This information is used for livestock sales on
      Beefbook. Speak to your breed society if you need
      help finding this figure.
      </NumberField>
      <NumberField name="EBV Self Replacing Index"
        testId="EBVSRI"
        value={ getAttribute('EBV.SRIscore') }
        error={ getError('EBVSRIScore') }
        update={ value => update('EBV.SRIscore', value) } >
      Estimate Breed Value  - Self Replacing Index.
      This information is used for livestock sales on
      Beefbook. Speak to your breed society if you need
      help finding this figure.
      </NumberField>
      <TextField name="EBV Rank"
        testId="EBVRank"
        value={ getAttribute('EBV.rank') }
        error={ getError('EBVRank') }
        update={ value => update('EBV.rank', value) } >
      Estimated Breed Value - Rank.
      This describes where this animal fits in the range of
      estimated breed values for this breed. You should include
      the number along with a percentage symbol.
      Eg &quote10%&quote to indicate that this animal sits in the
      top 10% of the breed.
      </TextField>
      <NumberField name="Birth Weight (Kg)"
        value={ getAttribute('birthWeight')}
        error={ getError('birthWeight') }
        update={ value => update('birthWeight', value) } />
    </Fragment>
  );
  if (breedingAnimalFields) {
    isErrored = isErrored ||
    !!getError('EBVTIScore') ||
    !!getError('EBVSRIScore') ||
    !!getError('EBVRank') ||
    !!getError('birthWeight');
  }
  return (
    <ExpandableFormSection
      isErrored={ isErrored }
      id="livestock-fields-expandable"
      title="Enhanced data">
      <form className="animal-form livestock-fields">
        <DateField name="Birth Date"
          value={ birthDate }
          testId="animal-dob-field"
          error={ getError('dob') }
          update={ value => update('dob', value) } />
        <TextField name="Location"
          value={ getAttribute('location') }
          error={ getError('location') }
          update={ value => update('location', value) } />
        <NumberField name="Daily Weight Gain (Kg per day)"
          value={ getAttribute('dailyGain')}
          testId="animal-daily-gain"
          error={ getError('dailyGain') }
          update={ value => update('dailyGain', value) } />
        <NumberField name="Intra muscular fat (IMF) %"
          value={ getAttribute('IMF')}
          testId="animal-imf-input"
          error={ getError('IMF') }
          update={ handleIMFUpdate } />
        { breedingAnimalFields }
        { maturity }
        <TextAreaField name="Comments"
          value={ getAttribute('comments') }
          update={ val => update('comments', val) }
          className="comments-field"
          testId="comments-field" >
          Add any additional information that can be displayed with this animal
        </TextAreaField>
        {/* { status }
        { sire }
        { calfBirthDate } */}
      </form>
    </ExpandableFormSection>
  );
};

LiveStockFields.propTypes = {
  showErrors: PropTypes.bool
};

export default LiveStockFields;
