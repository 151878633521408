import React from 'react';
import PropTypes from 'prop-types';
import DropDownSelectField from '../../form/DropDownSelectField';
import { getObjectAttribute, updateObjectAttribute } from '../util';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnimalUnderEdit,
  getAnimalEditErrors } from '../reducer';
import { updateAnimalUnderEdit } from '../actions';
import DateField from '../../form/DateField';
import { canPostOnBB, canPostOnGBI, isAdmin } from '../../util/jwt';
import TickBoxField from '../../form/TickBoxField';
import { getCowTypeDropDownOptions } from '../../cows/util/cowTypes';
import NumberField from '../../form/NumberField';
import ExpandableFormSection from '../util/components/ExpandableFormSection';
import { getGBIDomain } from '../../util/site';

const AnimalDisplayFields = ({ showErrors }) => {
  const dispatch = useDispatch();
  const animal = useSelector(getAnimalUnderEdit);
  const errors = useSelector(getAnimalEditErrors);
  const update = (param, value) => {
    const newAnimal = updateObjectAttribute(animal, param, value);
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const deleteAttribute = key => {
    const newAnimal = JSON.parse(JSON.stringify(animal)) || {};
    delete newAnimal[key];
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const getAttribute = key => getObjectAttribute(animal, key);
  const getError = key => {
    if (showErrors && errors && errors[key]) return errors[key];
    return null;
  };
  const isErrored = showErrors && errors && (
    errors['liveOnBeefBook'] ||
    errors['liveOnGBI'] ||
    errors['availabilityStatus'] ||
    errors['pricePerKilo'] ||
    errors['price'] ||
    errors['availableDate'] ||
    errors['type']
  );
  let beefbook = null;
  if (canPostOnBB() || isAdmin()) {
    const toggle = () =>
      update('liveOnBeefBook', !getAttribute('liveOnBeefBook'));
    beefbook = (
      <TickBoxField
        title="BeefBook Listings"
        testId="display-on-bb-tick-box"
        isOn={ getAttribute('liveOnBeefBook') }
        error={ getError('liveOnBeefBook') }
        toggle={ toggle } >
        { `Tick the checkbox to display this animal in the listings page ` }
        <a href={ `${getGBIDomain()}/cattle/finished-cattle` }>here</a>
      </TickBoxField>
    );
  }
  let goodBeefIndex = null;
  if (canPostOnGBI() || isAdmin()) {
    const toggle = () => update('liveOnGBI', !getAttribute('liveOnGBI'));
    goodBeefIndex = (
      <TickBoxField
        title="GoodBeef Index Page"
        testId="display-on-gbi-tick-box"
        isOn={ getAttribute('liveOnGBI') }
        error={ getError('liveOnGBI') }
        toggle={ toggle } />
    );
  }
  let farmSale = null;
  if (isAdmin()) {
    const toggle = () =>
      update('warsonFarmSale', !getAttribute('warsonFarmSale'));
    farmSale = (
      <TickBoxField
        title="Warson Farm Sale"
        testId="farm-sale-tick-box"
        isOn={ getAttribute('warsonFarmSale') }
        error={ getError('warsonFarmSale') }
        toggle={ toggle } />
    );
  }
  let semen = null;
  if ((canPostOnBB() || isAdmin()) && getAttribute('type') === 'YoungBulls') {
    const toggle = () =>
      update('hasSemen', !getAttribute('hasSemen'));
    semen = (
      <TickBoxField
        title="Semen Available"
        testId="has-semen-tick-box"
        isOn={ getAttribute('hasSemen') }
        error={ getError('hasSemen') }
        toggle={ toggle } >
        { `Tick the checkbox to show the "Semen Available" banner on the ` +
        `image in BeefBook` }
      </TickBoxField>
    );
  }
  let price = null;
  let availabilityStatus = null;
  if (animal && animal.liveOnBeefBook) {
    const cur = getAttribute('availabilityStatus');
    const options = [
      { name: 'available', value: 'AVAILABLE', selected: cur === 'AVAILABLE' },
      { name: 'reserved', value: 'RESERVED', selected: cur === 'RESERVED' },
      { name: 'sold', value: 'SOLD', selected: cur === 'SOLD' }
    ];
    const handleUpdate = val => update('availabilityStatus', val);
    availabilityStatus = (
      <DropDownSelectField
        options={ options }
        name="Status"
        error={ getError('availabilityStatus') }
        update={ handleUpdate } />
    );
    const attribute = animal.type === 'FinishedCattle' ?
      'pricePerKilo' : 'price';
    const title = animal.type === 'FinishedCattle' ?
      'Price Per Kilo (£)' : 'Price (£)';
    const updatePrice = pounds => {
      const pence = Math.round((pounds || 0) * 100);
      update(attribute, pence);
    };
    const val = getAttribute(attribute) ? getAttribute(attribute) / 100 : null;
    price = (
      <NumberField name={ title }
        value={ val }
        testId="animal-price"
        error={ getError(attribute) }
        update={ updatePrice } />
    );
  }
  let availableDate = null;
  if (getAttribute('liveOnBeefBook') &&
    getAttribute('availabilityStatus') === 'AVAILABLE') {
    const current = getAttribute('availableDate');
    const value = current ? new Date(current) : undefined;
    const toggle = () => update('availableDate', new Date().toISOString());
    const clear = () => deleteAttribute('availableDate');
    availableDate = current ? (
      <DateField name="Availability Date"
        value={ value }
        error={ getError('availableDate') }
        placeholder="available now"
        update={ value => update('availableDate', value) } >
        <span onClick={ clear } className="clear-date-icon">x</span>
      </DateField>
    ) : (
      <TickBoxField
        title="Available Immediately"
        isOn={ true }
        error={ getError('availableDate') }
        toggle={ toggle } />
    );
  }
  const typeOptions = getCowTypeDropDownOptions(getAttribute('type'))
    .filter(option => {
      return ['YoungBulls', 'BreedingCows', 'Heifers', 'FinishedCattle']
        .includes(option.value);
    });
  const updateType = value => {
    const newAnimal = updateObjectAttribute(animal, 'type', value);
    if (value !== 'YoungBulls') {
      delete newAnimal['hasSemen'];
    }
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const category = animal && animal.liveOnBeefBook ? (
    <DropDownSelectField
      testId="animal-category-dropdown"
      options={ typeOptions }
      name="Animal category"
      error={ getError('type') }
      update={ updateType } >
      { 'this is the category used for display on the' +
        'BeefBook' }
    </DropDownSelectField>
  ) : null;
  let toolTipMsg = 'Control where this animal is displayed ';
  toolTipMsg += '(eg on BeefBook or on a GoodBeef Index authentication page),';
  toolTipMsg += ' and set display specific fields. ';
  if (!canPostOnBB() && !canPostOnGBI && !isAdmin()) {
    toolTipMsg = 'Your GoodBeef Index account does not include permissions ' +
    'to display animals publicly. Get in touch to add permissions.';
  }
  return (
    <ExpandableFormSection
      isErrored={ isErrored }
      title="Display settings"
      id="display-section-expandable"
      toolTip={ toolTipMsg }>
      <form className="animal-form" onSubmit={ e => e.preventDefault() }>
        { beefbook }
        { goodBeefIndex }
        { farmSale }
        { category }
        { availabilityStatus }
        { availableDate }
        { price }
        { semen }
      </form>
    </ExpandableFormSection>
  );
};

AnimalDisplayFields.propTypes = {
  showErrors: PropTypes.bool.isRequired
};

export default AnimalDisplayFields;
