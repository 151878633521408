import React, { useState, useRef, Fragment } from 'react';
import OrgUserRow from './OrgUserRow';
import PropTypes from 'prop-types';
import './org-user-controls.scss';
import GBIButton from '../../../util/buttons/components/GBIButton';
import { validateEmail } from '../../../util/email';
import { useDispatch, useSelector } from 'react-redux';
import { isAddingNewUser } from '../reducer';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import { addOrgUser } from '../actions';
import ExpandableFormSection from '../../util/components/ExpandableFormSection';

const OrgUsers = ({ users, orgId }) => {
  const [ showNewUserInput, setShowNewUserInput ] = useState(false);
  const [ newUser, setNewUser ] = useState('');
  const [ newUserError, setNewUserError ] = useState(null);
  const [ newUserInputFocus, setNewUserInputFocus ] = useState(false);
  const userElements = users.map(user =>
    <OrgUserRow key={ user.id } user={ user } />
  );
  const addNewButton = showNewUserInput ? null : (
    <GBIButton label="add user" onClick={ () => setShowNewUserInput(true) } />
  );
  const newUserRef = useRef();
  const handleNewUserInputChange = () => {
    const newUser = newUserRef.current.value;
    const error = validateEmail(newUser) ?
      null : 'A valid email address is required';
    setNewUserError(error || null);
    setNewUser(newUser);
  };
  const newUserErrorElement = newUserError && !newUserInputFocus ? (
    <span className="error-message">{ newUserError }</span>
  ) : null;
  const inputClass = newUserError ? 'error' : '';
  const hideNewUserInput = () => {
    setNewUserError(null);
    setNewUser('');
    setShowNewUserInput(false);
  };
  const newUserInput = showNewUserInput ? (
    <Fragment>
      <input ref={ newUserRef }
        placeholder="email address"
        className={ inputClass }
        value={ newUser }
        onFocus={ () => setNewUserInputFocus(true) }
        onBlur={ () => setNewUserInputFocus(false) }
        onChange={ handleNewUserInputChange } />
      <div className="close" onClick={hideNewUserInput}>
        x
      </div>
    </Fragment>
  ) : null;
  const dispatch = useDispatch();
  const addNewUser = () => {
    if (newUser && !newUserError) {
      setNewUser('');
      dispatch(addOrgUser(newUser.toLowerCase(), orgId));
    }
  };
  const confButton = showNewUserInput && newUser && !newUserError ? (
    <GBIButton label="add" className="conf-button" onClick={ addNewUser } />
  ) : null;
  const addingNewUser = useSelector(isAddingNewUser);
  const newUserContent = addingNewUser ? (
    <LoadingIcon />
  ) : (
    <Fragment>
      { addNewButton }
      { newUserInput }
      { newUserErrorElement }
      { confButton }
    </Fragment>
  );
  return (
    <ExpandableFormSection title="Authorised users">
      <div className="org-user-controls">
        <div className="users">
          { userElements }
        </div>
        <div className="new-user">
          { newUserContent }
        </div>
      </div>
    </ExpandableFormSection>
  );
};

OrgUsers.propTypes = {
  users: PropTypes.array.isRequired,
  orgId: PropTypes.string.isRequired
};

export default OrgUsers;
