import React, { Fragment } from 'react';
import ExpandableFormSection from '../util/components/ExpandableFormSection';
import PropTypes from 'prop-types';
import ScanningTechnicianIcon from
  '../../util/icons/components/ScanningTechnicianIcon';
import ScanCalendarIcon from
  '../../util/icons/components/ScanCalendarIcon';
import DownloadIcon from
  '../../util/icons/components/DownloadIcon';
import './animal-ultrasound-section.scss';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSessionIds,
  hasFetchedScans,
  isFetchingUltrasoundScans
} from '../ultrasound/reducer';
import { fetchUltrasoundScans } from '../ultrasound/actions';
import { Link } from 'react-router-dom';
import UltrasoundIdIcon from '../../util/icons/components/UltrasoundIDIcon';

const fields = {
  'rumpFatThickness': 'rump fat thickness',
  'ribFatThickness': 'rib fat thickness',
  'ribEyeArea': 'rib eye area',
  'imf': 'intra muscular fat',
  'scrotalCircumference': 'scrotal circumference',
  'weight': 'weight'
};

const AnimalUltrasoundSection = ({ scans }) => {
  if (!scans || scans.length < 1) return null;
  const sessionIds = useSelector(getSessionIds);
  const dispatch = useDispatch();
  const fetched = useSelector(hasFetchedScans);
  const fetching = useSelector(isFetchingUltrasoundScans);
  const handleExpand = () => {
    if (!fetching && !fetched) {
      dispatch(fetchUltrasoundScans());
    }
  };
  const reports = scans.map((scan, index) => {
    const data = Object.keys(fields).map(key => {
      if (!scan[key]) return null;
      return (
        <Fragment key={ key }>
          <div data-cy={ `${key}-title` }
            className="title">{ fields[key] }</div>
          <div data-cy={ `${key}-value` }
            className="value">{ scan[key] }</div>
        </Fragment>
      );
    });
    const downloadBlock = scan.reportUrl ? (
      <div className="download block">
        <div className="text">download PDF</div>
        <div className="icon">
          <a target="_blank" rel="noopener noreferrer" href={ scan.reportUrl }>
            <DownloadIcon />
          </a>
        </div>
      </div>
    ) : null;
    const id = sessionIds && sessionIds[scan.scanId] ? (
      <Link to={ `/account/ultrasound/${sessionIds[scan.scanId]}`}>
        { scan.scanId }
      </Link>
    ) : scan.scanId;
    return (
      <div key={ index }
        data-cy={ `scan-${scan.scanId}` }
        className="ultrasound-scan">
        <div className="session-details block">
          <h5 className="scanning-session-title">Ultrasound Scan Report</h5>
          <div className="icon"><ScanningTechnicianIcon /></div>
          <div data-cy="technician-name" className="technician value">
            { scan.technician }
          </div>
          <div className="icon"><ScanCalendarIcon /></div>
          <div className="date value" data-cy="scan-date">
            { moment(scan.date).format('MMMM Do YYYY') }
          </div>
          <div className="icon"><UltrasoundIdIcon /></div>
          <div className="scan-id value">{ id }</div>
        </div>
        <div className="scan-data block">
          { data }
        </div>
        { downloadBlock }
      </div>
    );
  });
  return (
    <ExpandableFormSection title="Ultrasound Reports"
      onFirstOpen={ handleExpand }
      id="ultrasound-section-expand">
      <div className="animal-ultrasound-section">
        { reports }
      </div>
    </ExpandableFormSection>
  );
};

AnimalUltrasoundSection.propTypes = {
  scans: PropTypes.array
};

export default AnimalUltrasoundSection;
