/* global Image */
import React, { useState, useRef, Fragment } from 'react';
import AdminAPI from '../../AdminAPI';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import PlusIcon from '../../../util/icons/components/PlusIcon';
import './image-upload.scss';
import { captureException } from '../../../util/logger';

const minWidth = 2160;
const maxRatio = 1.5;

const ImageUpload = ({ ownerId, uploaded, testId }) => {
  const fileInputRef = useRef();
  const elementRef = useRef(uuid());
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [progress, setProgress] = useState(0);
  const onProgress = event => {
    if (event.lengthComputable) {
      const progress = event.loaded / event.total / 1.5;
      setProgress(progress);
    }
  };
  const onChange = event => {
    const file = event.target.files[0];
    if (!file) return;
    const name = file.name;
    setName(name);
    const image = new Image();
    image.onload = function () {
      if (this.width < minWidth) {
        setError('Image must be at least 2160px wide');
      } else if (this.width < this.height) {
        setError('Image must be landscape');
      } else if ((this.width / this.height) > maxRatio) {
        setError('Image width:height ratio must be less than 1.5');
      } else {
        AdminAPI.saveFile(
          ownerId, name, file, onProgress
        ).then(response => {
          const data = response.data;
          setProgress(1);
          uploaded(`https://${data.bucket}/${data.name}`);
        }).catch(error => captureException(error));
      }
    };
    image.src = window.URL.createObjectURL(file);
  };
  const reset = () => {
    setError(null);
    setName(null);
  };
  const style = {
    // eslint-disable-next-line max-len
    background: `linear-gradient(90deg, #eaebec ${progress * 100}%, #f4f4f4 ${(1 - progress) * 100}%)`
  };
  const content = name && progress !== 1 ? (
    <div className="uploading-file"
      style={ style }>
      { name }
    </div>
  ) : (
    <Fragment>
      <span className="add-text">Upload new image</span>
      <PlusIcon />
    </Fragment>
  );
  const errorText = error ? (
    <div className="error">
      <span>{ error }</span>
      <div className="close-error" onClick={ reset }>x</div>
    </div>
  ) : null;
  return (
    <div className="image-upload-widget" data-cy="image-upload-widget">
      <label htmlFor={ elementRef.current }>{ content }</label>
      <input
        id={ elementRef.current }
        ref={fileInputRef}
        className="file-input"
        data-cy={ testId || 'image-upload-input' }
        type="file"
        multiple
        onChange={onChange} />
      { errorText }
    </div>
  );
};

ImageUpload.propTypes = {
  ownerId: PropTypes.string.isRequired,
  uploaded: PropTypes.func.isRequired,
  testId: PropTypes.string
};

export default ImageUpload;
