import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../form/TextField';
import DropDownSelectField from '../../../form/DropDownSelectField';
import counties from '../counties';

const Address = ({ address, onChange, errors }) => {
  const updateAddressField = (field, value) => {
    const newAddress = address ? JSON.parse(JSON.stringify(address)) : {};
    newAddress[field] = value;
    onChange(newAddress);
  };
  const options = JSON.parse(JSON.stringify(counties));
  if (address && address.county) {
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.value === address.county) {
        option.selected = true;
        break;
      }
    }
  }
  return (
    <Fragment>
      <TextField name="Address Line 1"
        value={ address ? address.addressLine1 || '' : '' }
        testId="address-line1-input"
        error={ null }
        update={ value => updateAddressField('addressLine1', value) } />
      <TextField name="Address Line 2"
        value={ address ? address.addressLine2 || '' : '' }
        testId="address-line2-input"
        error={ null }
        update={ value => updateAddressField('addressLine2', value) } />
      <TextField name="Address Line 3"
        value={ address ? address.addressLine3 || '' : '' }
        testId="address-line3-input"
        error={ null }
        update={ value => updateAddressField('addressLine3', value) } />
      <TextField name="Town"
        value={ address ? address.town || '' : '' }
        testId="address-town-input"
        error={ errors ? errors['address_town'] : null }
        update={ value => updateAddressField('town', value) } />
      <DropDownSelectField
        options={ options }
        name="County"
        testId="address-county-input"
        error={ errors ? errors['address_county'] : null }
        update={ value => updateAddressField('county', value) } />
      <TextField name="PostCode"
        value={ address ? address.postCode || '' : '' }
        error={ null }
        testId="address-postcode-input"
        update={ value => updateAddressField('postCode', value) } />
    </Fragment>
  );
};

Address.propTypes = {
  address: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object
};

export default Address;
