import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getScansObj,
  hasFetchedScans,
  isFetchingUltrasoundScans
} from '../reducer';
import { fetchUltrasoundScans } from '../actions';
import moment from 'moment';
import './ultrasound-detail-view.scss';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';
import { Link } from 'react-router-dom';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import ScanningTechnicianIcon from
  '../../../util/icons/components/ScanningTechnicianIcon';
import UltrasoundFarmIcon from
  '../../../util/icons/components/UltrasoundFarmIcon';
import UltrasoundIdIcon from '../../../util/icons/components/UltrasoundIDIcon';
import BreedplanExport from './BreedplanExport';
import MarblingMeaterLogo from '../../../util/logos/MarblingMeaterLogo';

const fields = {
  earTag: 'Ear tag',
  rumpFatThickness: 'Rump fat thickness',
  ribFatThickness: 'Rib fat thickness',
  ribEyeArea: 'Rib eye area',
  imf: 'IMF',
  weight: 'Weight',
  scrotalCircumference: 'Scrotal circumference'
};

const UltrasoundDetailView = ({ match }) => {
  const scans = useSelector(getScansObj);
  const scan = scans[match.params.scanId];
  const scansFetched = useSelector(hasFetchedScans);
  const scansFetching = useSelector(isFetchingUltrasoundScans);
  useEffect(() => {

  }, []);
  const dispatch = useDispatch();
  if (!scansFetched && !scansFetching) {
    dispatch(fetchUltrasoundScans());
  }
  const date = scan ? moment(scan.date).format('MMMM Do YYYY') : '';
  const tableCells = Object.keys(fields).map((key, i) => (
    <div key={`header-${key}`}
      className={`header cell ${i % 2 ? 'even' : 'odd'}`}>
      <span className="text">{ fields[key] }</span>
    </div>
  ));
  tableCells.push(<div key="pdf" className="header cell even">pdf report</div>);
  if (scan && scan.animals && scan.animals.length) {
    scan.animals.forEach((animal, rowIndex) => {
      Object.keys(fields).map((key, columnIndex) => {
        let className = 'cell';
        if (rowIndex % 2 === 1) {
          className += ' oddRow';
        }
        if (columnIndex % 2 === 1) {
          className += ' oddColumn';
        }
        let content = animal[key];
        if (key === 'earTag' && animal.animalId) {
          content = (
            <Link to={ `/account/animals/${animal.animalId}`}>
              <span data-cy={ `ear-tag-${animal.earTag}` }>
                { animal.earTag }
              </span>
            </Link>
          );
        }
        tableCells.push(
          <div key={ `${rowIndex}-${columnIndex}`}
            data-cy={ `${animal.earTag} ${key}`}
            className={ className }>
            { content }
          </div>
        );
      });
      const link = animal.reportUrl ? (
        <a href={ animal.reportUrl }
          data-cy={ `report-link-${animal.earTag}`}
          target="_blank"
          rel="noopener noreferrer">
          <DownloadIcon />
        </a>
      ) : null;
      let className = 'cell oddColumn';
      if (rowIndex % 2 === 1) className += ' oddRow';
      tableCells.push(
        <div key={ 'pdf-' + rowIndex } className={ className }>
          { link }
        </div>
      );
    });
  }
  const sessionReport = scan && scan.reportUrl ? (
    <div><a className="session-report"
      href={ scan.reportUrl }
      target="_blank"
      data-cy="report-link"
      rel="noopener noreferrer">
      <span className="label">Session report</span>
      <DownloadIcon />
    </a></div>
  ) : null;
  let path = match.url.replace('/' + match.params.scanId, '');
  return (
    <article className="ultrasound-detail-view"
      data-cy={ `ultrasound-session-${scan ? scan.id : ''}`}>
      <Link className="back-to-sessions-link"
        data-cy="back-to-sessions-link"
        to={ path }>
        <ChevronIcon /> Back to scan sessions
      </Link>
      {/* <h1 className="main-title">
        { `Ultrasound Scanning Session ${scan ? scan.scanId : ''}` }
      </h1> */}
      <div className="top-row">
        <h2 className="date">{ date } </h2>
        <MarblingMeaterLogo />
      </div>
      <div className="info-row">
        <div className="info">
          <div className="icon"><ScanningTechnicianIcon /></div>
          <div className="technician">{ scan ? scan.technician : null }</div>
          <div className="icon"><UltrasoundFarmIcon /></div>
          <div className="farm">{ scan ? scan.farm : null }</div>
          <div className="icon id-icon"><UltrasoundIdIcon /></div>
          <div className="scan-id">{scan ? scan.scanId : ''}</div>
        </div>
        <BreedplanExport scanSession={ scan } />
        { sessionReport }
      </div>
      <div className="scan-table-wrapper">
        <div className="scan-table">
          { scan && scan.animals && scan.animals.length ? tableCells : null }
        </div>
      </div>
    </article>
  );
};

UltrasoundDetailView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      scanId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  })
};

export default UltrasoundDetailView;
