import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import getCreationTimeFromMongoId from '../util/getCreationTimeFromMongoId';
import { Link } from 'react-router-dom';
import './animal-qr-section.scss';
import ChevronIcon from '../../util/icons/components/ChevronIcon';
import { getGBIDomain } from '../../util/site';

const AnimalQRSection = ({ animal, farm }) => {
  if (!animal || !animal.tag || !animal.saved) return null;
  const qrElementId = `qr-code-${animal.id}`;
  const downloadClicked = () => {
    const canvas = document.getElementById(qrElementId);
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${animal.tag.replaceAll(' ', '_')}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  if (!animal) return null;
  const { host } = new URL(getGBIDomain());
  const protocol = window.location.protocol;
  const url = `${protocol}//${host}/animal/${animal.id}`;
  const creationString = getCreationTimeFromMongoId(animal.id);
  const pngDownloadLink = (
    <p className="png-download" onClick={ downloadClicked }>
      Download a QR code (png)
    </p>
  );
  const handleAuthPageLinkClick = () => {
    const code = new Date().getTime().toString(16);
    const code2 = [...Array(6)] // dummy code for obfuscation
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('');
    const url = `${getGBIDomain()}/animal/${animal.id}?dc=${code2}&ac=${code}`;
    window.location = url;
  };
  const authenticationPageText = animal.liveOnGBI && animal.saved ?
    'view the public authentication page for this animal' :
    'view a demo authenticationi page for this animal (only visible to you)';
  return (
    <div className='animal-detail-qr-section'>
      <div id="animal-qr-code-wrapper">
        <QRCode size={ 140 }
          value={ url }
          renderAs='canvas'
          id={ qrElementId }/>
      </div>
      <div>
        <h3 className="title">QR Code</h3>
        <p className="creation-string">{ creationString }</p>
        <p className="url">
          <span className="bold">url: </span>
          { url }
        </p>
        { pngDownloadLink }
        <div className="authentication-page"
          onClick={ handleAuthPageLinkClick }
          data-cy="qr-page-link">
          { authenticationPageText }
        </div>
        <Link id="print-labels-link"
          data-cy="print-labels-link"
          to={ `/account/animal/labels/${animal.id}` }>
          Print Labels
          <ChevronIcon />
        </Link>
      </div>
    </div>
  );
};

AnimalQRSection.propTypes = {
  animal: PropTypes.object,
  farm: PropTypes.string
};

export default AnimalQRSection;
