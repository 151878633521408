import React, { useEffect, useState } from 'react';
// import { formatDate } from 'react-day-picker/moment';
import PropTypes from 'prop-types';
import GBIButton from '../../../util/buttons/components/GBIButton';
import Modal from '../../../main/components/Modal';
import { useDispatch } from 'react-redux';
import { deletePricing } from '../actions';
import './price-check-list-item.scss';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import PricingDisplay from './PricingDisplay';
import { setFarmGatePrice as setFarmGatePriceAction } from '../actions';

const PricingListViewItem = ({ priceCheck, uploading, errored }) => {
  const dispatch = useDispatch();
  const [ showDelete, setShowDelete ] = useState(false);
  const [ displayInModal, setDisplayInModal ] = useState(false);
  const [ modalStartPos, setModalStartPos ] = useState(window.scrollY);
  const deletePriceCheck = () => dispatch(deletePricing(priceCheck));
  const [ farmGatePrice, setFarmGatePrice] = useState(priceCheck.farmGatePrice);
  useEffect(() => {
    setFarmGatePrice(priceCheck.farmGatePrice / 100);
  }, [priceCheck])
  const handleDeleteClicked = () => {
    setModalStartPos(window.scrollY);
    setShowDelete(true);
  };
  const handleClick = () => {
    setModalStartPos(window.scrollY);
    setDisplayInModal(true);
  };
  const handlePriceCheckChange = event => {
    console.log(event);
    console.log(event.target.value);
    const priceInPennies = Math.round(event.target.value * 100);
    dispatch(setFarmGatePriceAction(priceCheck.id, priceInPennies));
  }
  const deleteModal = showDelete ? (
    <Modal
      pos={ window.scrollY }
      contentClass="delete-pricing-modal-content"
      close={ () => setShowDelete(false) }>
      <div>
        <h2 className="title">
          { `Delete price check - ${priceCheck.date}?` }
        </h2>
        <p className="text">{ 'this action can not be undone' }</p>
        <div className="button-row">
          <GBIButton
            onClick={ () => setShowDelete(false) }
            label="cancel"
            testId="cancel-delete"
            className="light" />
          <GBIButton onClick={ deletePriceCheck }
            label="delete"
            testId="confirm-delete"/>
        </div>
      </div>
    </Modal>
  ) : null;
  const displayModal = displayInModal ? (
    <Modal
      pos={ modalStartPos }
      contentClass="display-price-check-modal"
      close={ () => setDisplayInModal(false) }>
      <PricingDisplay pricing={priceCheck} />
    </Modal>
  ) : null;
  const buttonOrLoading = uploading ? (
    <LoadingIcon />
  ) : (
    <GBIButton onClick={ handleDeleteClicked }
      className="delete-button"
      testId="delete-button"
      label="Delete" />
  );
  let date = null;
  let email = null;
  try {
    const d = new Date(priceCheck.date);
    date = d.toDateString() + ' - ' + d.toLocaleTimeString();
  } catch (error) { /* */ }
  try {
    email = priceCheck.email || null;
  } catch (error) { /* */ }
  const rowClass = errored ? 'pricing-list-item error' : 'pricing-list-item';
  return (
    <div className={ rowClass } data-cy="price-check-item">
      { displayModal }
      <div className="details" onClick={ handleClick }>
        <div className="date">{ date }</div>
        <div className="email">{ email }</div>
      </div>
      <div className="farm-gate-price-wrapper">
        <div className="form-field-wrapper">
          <div className="farm-gate-price-label form-label">Farm gate price</div>
          <input className="farm-gate-price-value form-text-box"
            defaultValue={ farmGatePrice / 100 || 0 }
            type="number"
            onBlur={ handlePriceCheckChange } />
        </div>
      </div>
      <div className="delete-section">
        { deleteModal }
        { buttonOrLoading }
      </div>
    </div>
  );
};

PricingListViewItem.propTypes = {
  priceCheck: PropTypes.object,
  uploading: PropTypes.bool,
  errored: PropTypes.bool
};

export default PricingListViewItem;
