import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './admin-form-field.scss';
import ToolTip from '../util/tooltips/components/ToolTip';

const NumberField = ({ name, value, error, update, testId, children }) => {
  const [ focused, setFocused ] = useState(false);
  const [ val, setVal ] = useState(typeof value !== 'undefined' ? value : '');
  useEffect(() => {
    setVal(typeof value !== 'undefined' ? value : '');
  }, [value]);
  const ref = useRef();
  let labelClass = 'form-label';
  let className = 'form-field-wrapper';
  if (error && !focused) {
    labelClass += ' error';
    className += ' error';
  }
  if (children) labelClass += ' tooltip';
  const onBlur = () => {
    const val = ref.current.value;
    if (val.indexOf('.') !== -1) {
      update(parseFloat(val));
    } else {
      update(parseInt(val));
    }
    setFocused(false);
  };
  const errorElement = !focused && error ? (
    <div className="field-error-text" data-cy="field-error-text">
      { !focused ? error : null }
    </div>
  ) : null;
  const toolTip = children ? (
    <ToolTip>children</ToolTip>
  ) : null;
  return (
    <div className={ className }>
      <label className={ labelClass }>
        <span className="name">{ name }</span>
        { toolTip }
      </label>
      <input
        className='form-text-box'
        data-cy={ testId }
        type='number'
        value={ val || '' }
        onChange={ () => setVal(ref.current.value) }
        ref={ ref }
        onFocus={ () => setFocused(true) }
        onBlur={ onBlur } />
      { errorElement }
    </div>
  );
};

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  error: PropTypes.string,
  update: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  testId: PropTypes.string
};

export default NumberField;
