import React from 'react';
import TickIcon from '../../util/icons/components/TickIcon';
import PropTypes from 'prop-types';
import './profile-permissions.scss';

const ProfilePermisions = ({ user }) => {
  if (!user) return null;
  const permissions = [];
  if (user.admin) {
    permissions.push(
      <div key="admin"
        data-cy="permissions-admin"
        className="permission">
        <TickIcon />
        <span className="text">Administrator</span>
      </div>
    );
  }
  if (user.permissions && user.permissions.canPostOnGBI) {
    permissions.push(
      <div key="gbi"
        data-cy="permissions-gbi"
        className="permission">
        <TickIcon />
        <span className="text">GoodBeef Index</span>
      </div>
    );
  }
  if (user.permissions && user.permissions.canPostOnBeefBook) {
    permissions.push(
      <div key="bb"
        data-cy="permissions-bb"
        className="permission">
        <TickIcon />
        <span className="text">BeefBook</span>
      </div>
    );
  }
  if (permissions.length < 1) {
    permissions.push(
      <div key="trial"
        data-cy="permissions-trial-account"
        className="permission">
        <span className="text">Trial account. Get in touch to upgrade. </span>
        <a className="email"
          href="mailto:info@goodbeefindex.org"
          target="_blank"
          rel="noopener noreferrer">
          <span className="label">email: </span>
          <span>info@goodbeefindex.org</span>
        </a>
        <span> </span>
        <a className="phone" href="tel:01822820740">
          <span className="label">phone: </span>
          <span>01822 820740</span>
        </a>
      </div>
    );
  }
  return (
    <div className="profile-permissions">
      <h4 className="permissions-title">Permissions</h4>
      { permissions }
    </div>
  );
};

ProfilePermisions.propTypes = {
  user: PropTypes.object
};

export default ProfilePermisions;
