import axios, { getStandardAxiosConfig } from '../../util/api';

class VideoAPI {
  static pollEncodingJob (jobId) {
    return axios.get(
      `/video/poll/${jobId}`,
      getStandardAxiosConfig()
    );
  }
  static encodeOrgVideo (bucket, name, orgId) {
    const params = { bucket, name, orgId };
    return axios.post(
      `/orgs/process-video/${orgId}`,
      params,
      getStandardAxiosConfig()
    );
  }
  static encodeAnimalVideo (bucket, name, orgId, animalId) {
    const params = { bucket, name, orgId, animalId };
    return axios.post(
      `/admin/cattle/process-video/${animalId}`,
      params,
      getStandardAxiosConfig()
    );
  }
  static encodeHelpVideo (id, title, bucket, tags, name) {
    const params = { id, title, bucket, tags, name };
    return axios.post(
      `/helpvideo`, params, getStandardAxiosConfig()
    );
  }
}

export default VideoAPI;
