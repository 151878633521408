import React, { useState } from 'react';
import PlusIcon from '../../../util/icons/components/PlusIcon';
import PropTypes from 'prop-types';
import './image-row.scss';

const ImageRow = ({ position, fileName, src, deleteImage }) => {
  const [ deleteHovered, setDeleteHovered ] = useState(false);
  const rowClass = deleteHovered ?
    'sortable-draggable-image-row delete-hovered' :
    'sortable-draggable-image-row';
  return (
    <div
      className={ rowClass }>
      <PlusIcon onMouseEnter={ () => setDeleteHovered(true) }
        onMouseLeave={ () => setDeleteHovered(false) }
        onClick={ deleteImage } />
      <div className="text">
        <div className="position">
          { position }
        </div>
        <div className='file-name'>{ fileName }</div>
      </div>
      <img data-cy="uploaded-image" className="image" src={ src } />
    </div>
  );
};

ImageRow.propTypes = {
  position: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  deleteImage: PropTypes.func.isRequired
};

export default ImageRow;
