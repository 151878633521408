import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { logoSrc, tickSrc } from '../util/imageURLs';
import getDataURIFromImageSrc from '../util/getDataURIFromImageSrc';
import { BlobProvider } from '@react-pdf/renderer';
import AnimalQRCodeLabelsPDF from './AnimalQRCodeLabelsPDF';
import { captureException } from '../../../util/logger';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';

const SmallLabelsButton = ({ animal }) => {
  const [tickDataUri, setTickDataUri] = useState(null);
  const [logoDataUri, setLogoDataUri] = useState(null);
  const [ labelsRequested, setLabelsRequested ] = useState(false);
  getDataURIFromImageSrc(tickSrc, setTickDataUri, 'png');
  getDataURIFromImageSrc(logoSrc, setLogoDataUri, 'png');
  let button = null;
  if (tickDataUri && logoDataUri) {
    if (!labelsRequested) {
      button = (
        <div className="button small-labels-button"
          onClick={ () => setLabelsRequested(true) }>
          <span className="label" data-cy="build-small-labels-button">
            Build Small Labels
          </span>
          <ChevronIcon />
        </div>
      );
    } else {
      const name = animal.name || null;
      const tag = animal.tag || '';
      const smallLabelProps = { name, tag, tickDataUri, logoDataUri };
      let fileName = `${tag.replaceAll(' ', '_')}_labels.pdf`;
      button = (
        <BlobProvider
          document={ <AnimalQRCodeLabelsPDF { ...smallLabelProps } /> }>
          {({ blob, url, loading, error }) => {
            if (loading) {
              return (
                <div className="button disabled">
                  <span data-cy="building-small-labels-notice">
                    Building Small Labels
                  </span>
                  <LoadingIcon />
                </div>
              );
            }
            if (error) {
              captureException(error);
              return null;
            }
            return (
              <a href={ url }
                className="download-small-labels-button button"
                data-cy="down-load-small-labels"
                download={ fileName }>
                Download Small labels
                <DownloadIcon />
              </a>
            );
          }}
        </BlobProvider>
      );
    }
  }
  return (
    <div className="small-labels-button">
      { button }
    </div>
  );
};

SmallLabelsButton.propTypes = {
  animal: PropTypes.object.isRequired
};

export default SmallLabelsButton;
