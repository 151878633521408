import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberField from '../../form/NumberField';
import TextField from '../../form/TextField';
import DropDownSelectField from '../../form/DropDownSelectField';
import { getObjectAttribute, updateObjectAttribute } from '../util';
import { breeds } from '../../cowFilters/components/BreedFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnimalUnderEdit,
  getAnimalEditErrors } from '../reducer';
import { updateAnimalUnderEdit } from '../actions';
import DateField from '../../form/DateField';
import { getOrgIds, isAdmin } from '../../util/jwt';
import FarmSelectionDropDown from './FarmSelectionDropDown';
import { getOrgsObj } from '../orgAdmin/reducer';
import { updateAnimalWithNewOrg } from '../validation/animal';
import { getScoreDefinitions } from '../scoreDefinitions/reducer';
import { updateEarTag } from '../validation/earTag';

const CoreAnimalFields = ({ showErrors }) => {
  const dispatch = useDispatch();
  const animal = useSelector(getAnimalUnderEdit);
  const orgs = useSelector(getOrgsObj);
  const scoreDefinitions = useSelector(getScoreDefinitions);
  const errors = useSelector(getAnimalEditErrors);
  const update = (param, value) => {
    const newAnimal = updateObjectAttribute(animal, param, value);
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const handleEarTagUpdated = value => {
    const newAnimal = updateEarTag(value, animal);
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const getAttribute = key => getObjectAttribute(animal, key);
  const getError = key => {
    if (showErrors && errors && errors[key]) return errors[key];
    return null;
  };
  const breedOptions = breeds.map(breed => ({
    value: breed,
    name: breed,
    selected: getAttribute('breed') === breed
  }));
  const sexOptions = ['bull', 'steer', 'female'].map(sex => ({
    value: sex,
    name: sex[0].toUpperCase() + sex.slice(1),
    selected: getAttribute('animalCategory') === sex
  }));
  let weighDate = null;
  if (getAttribute('weight')) {
    const current = getAttribute('weightDate');
    const value = current ? new Date(current) : undefined;
    weighDate = (
      <DateField name="Date of Weighing"
        value={ value }
        testId="animal-weight-date"
        error={ getError('weightDate') }
        update={ value => update('weightDate', value) } />
    );
  }
  let farm = null;
  if (isAdmin() || (getOrgIds() && getOrgIds().length)) {
    const setOrg = id => {
      const updatedAnimal = JSON.parse(JSON.stringify(animal)) || {};
      if (orgs && orgs[id]) {
        updateAnimalWithNewOrg(orgs[id], updatedAnimal, scoreDefinitions);
      } else {
        updatedAnimal.orgId = id;
      }
      dispatch(updateAnimalUnderEdit(updatedAnimal));
    };
    farm = (
      <FarmSelectionDropDown farms={orgs}
        farmId={ animal ? animal.orgId : '' }
        setFarm={ setOrg } />
    );
  }
  return (
    <Fragment>
      <h4 className="section-header"></h4>
      <form className="animal-form core-animal-fields"
        onSubmit={ e => e.preventDefault() }>
        <TextField name="Animal Name"
          value={ getAttribute('name') }
          testId="animal-name-input"
          error={ getError('name') }
          update={ value => update('name', value) } />
        <TextField name="Ear Tag"
          value={ getAttribute('tag') }
          testId="animal-tag-input"
          error={ getError('tag') }
          update={ handleEarTagUpdated } >
          { 'In the case of UK animals this should be entered ' +
            'in the format on the animals passport document - ' +
            '"UK" followed immediatly by a 6 digit herd number, ' +
            'followed by a space then a further 6 digits. ' +
            'eg "UK373831 500255"'}
        </TextField>
        <DropDownSelectField
          options={ breedOptions }
          name="Breed"
          testId="animal-breed-dropdown"
          error={ getError('breed') }
          update={ value => update('breed', value) } />
        <DropDownSelectField
          options={ sexOptions }
          name="Sex"
          testId="animal-sex-dropdown"
          error={ getError('animalCategory') }
          update={ value => update('animalCategory', value) } />
        { farm }
        <NumberField name="Weight"
          value={ getAttribute('weight')}
          error={ getError('weight') }
          testId="animal-weight-input"
          update={ value => update('weight', value) } />
        { weighDate }
      </form>
    </Fragment>
  );
};

CoreAnimalFields.propTypes = {
  showErrors: PropTypes.bool.isRequired
};

export default CoreAnimalFields;
