import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ToolTip from '../../../util/tooltips/components/ToolTip';
import DropDownArrowIcon from
  '../../../util/icons/components/DropDownArrowIcon';
import './expandable-form-section.scss';

const ExpandableFormSection = ({
  children, isErrored, title, toolTip, startOpen, id, onFirstOpen
}) => {
  const [used, setUsed] = useState(startOpen);
  const [expanded, setExpanded] = useState(!!startOpen);
  const [transitioning, setTransitioning] = useState(false);
  const [overflow, setOverflow] = useState('hidden');
  const [limitHeight, setLimitHeight] = useState(true);
  const [height, setHeight] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  if (onFirstOpen && startOpen) {
    onFirstOpen();
    setUsed(true);
  }
  const contentWrapperRef = useRef(null);
  let className = 'expandable-form-section';
  if (expanded) className += ' expanded';
  const toolTipElement = toolTip ? (
    <ToolTip stopPropagation={ true }>{ toolTip }</ToolTip>
  ) : null;
  const handleErrorClicked = () => {
    if (onFirstOpen && !used) onFirstOpen();
    setUsed(true);
    setExpanded(true);
  };
  const errorElement = isErrored ? (
    <div><div className="section-header-error" onClick={ handleErrorClicked }>
      Errors below (expand to view)
    </div></div>
  ) : null;
  const expandableBlockStyles = {
    overflowY: overflow
  };
  if (limitHeight) {
    expandableBlockStyles.maxHeight = `${height}px`;
  }
  useLayoutEffect(() => {
    setLimitHeight(transitioning || !expanded);
    const height = expanded ? contentWrapperRef.current.clientHeight : 0;
    setHeight(height);
    setOverflow(transitioning || !expanded ? 'hidden' : 'visible');
  }, [transitioning, expanded, clickCount]);
  const handleToggleClicked = () => {
    setTransitioning(true);
    if (!expanded && onFirstOpen && !used) {
      onFirstOpen();
      setUsed(true);
    }
    setExpanded(!expanded);
  };
  const handleContentClicked = () => {
    setClickCount(clickCount + 1);
  };
  const handleContentTransitionEnd = () => {
    setTransitioning(false);
  };
  const expandClass = isErrored ? 'expand-wrapper error' : 'expand-wrapper';
  return (
    <section className={ className }>
      <div className="top-row"
        onClick={ handleToggleClicked }>
        <h4 className="section-header">
          { title }
          { toolTipElement }
        </h4>
        { errorElement }
        <div className={ expandClass }
          data-cy={ id }>
          <DropDownArrowIcon />
        </div>
      </div>
      <div className="content-container"
        onClick={ handleContentClicked }
        onTransitionEnd={ handleContentTransitionEnd }
        style={ expandableBlockStyles }>
        <div ref={ contentWrapperRef }>
          { children }
        </div>
      </div>
    </section>
  );
};

ExpandableFormSection.propTypes = {
  isErrored: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toolTip: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  startOpen: PropTypes.bool,
  id: PropTypes.string,
  onFirstOpen: PropTypes.func
};

export default ExpandableFormSection;
