import React from 'react';
import PropTypes from 'prop-types';
import './toggle-switch-field.scss';
import ToolTip from '../util/tooltips/components/ToolTip';

const ToggleSwitchField = ({ isOn, setIsOn, label, children, testId }) => {
  let switchClass = 'switch';
  switchClass += isOn ? ' on' : ' off';
  const toolTip = children ? <ToolTip>{ children }</ToolTip> : null;
  return (
    <div className="form-field-wrapper toggle-switch-field">
      <div className="form-label">{ label }{ toolTip }</div>
      <div className={ switchClass }
        data-cy={ testId }
        onClick={ () => setIsOn(!isOn) }>
        <div className="switch-button"></div>
      </div>
    </div>
  );
};

ToggleSwitchField.propTypes = {
  isOn: PropTypes.bool.isRequired,
  setIsOn: PropTypes.func.isRequired,
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  testId: PropTypes.string
};

export default ToggleSwitchField;
