/* eslint-disable standard/object-curly-even-spacing */
const counties = [
  { name: 'Bedfordshire', value: 'Bedfordshire' },
  { name: 'Buckinghamshire', value: 'Buckinghamshire' },
  { name: 'Cambridgeshire', value: 'Cambridgeshire' },
  { name: 'Cheshire', value: 'Cheshire' },
  { name: 'Cleveland', value: 'Cleveland' },
  { name: 'Cornwall', value: 'Cornwall' },
  { name: 'Cumbria', value: 'Cumbria' },
  { name: 'Derbyshire', value: 'Derbyshire' },
  { name: 'Devon', value: 'Devon' },
  { name: 'Dorset', value: 'Dorset' },
  { name: 'Durham', value: 'Durham' },
  { name: 'East Sussex', value: 'East Sussex' },
  { name: 'Essex', value: 'Essex' },
  { name: 'Gloucestershire', value: 'Gloucestershire' },
  { name: 'Greater London', value: 'Greater London' },
  { name: 'Greater Manchester', value: 'Greater Manchester' },
  { name: 'Hampshire', value: 'Hampshire' },
  { name: 'Hertfordshire', value: 'Hertfordshire' },
  { name: 'Kent', value: 'Kent' },
  { name: 'Lancashire', value: 'Lancashire' },
  { name: 'Leicestershire', value: 'Leicestershire' },
  { name: 'Lincolnshire', value: 'Lincolnshire' },
  { name: 'Merseyside', value: 'Merseyside' },
  { name: 'Norfolk', value: 'Norfolk' },
  { name: 'North Yorkshire', value: 'North Yorkshire' },
  { name: 'Northamptonshire', value: 'Northamptonshire' },
  { name: 'Northumberland', value: 'Northumberland' },
  { name: 'Nottinghamshire', value: 'Nottinghamshire' },
  { name: 'Oxfordshire', value: 'Oxfordshire' },
  { name: 'Shropshire', value: 'Shropshire' },
  { name: 'Somerset', value: 'Somerset' },
  { name: 'South Yorkshire', value: 'South Yorkshire' },
  { name: 'Staffordshire', value: 'Staffordshire' },
  { name: 'Suffolk', value: 'Suffolk' },
  { name: 'Surrey', value: 'Surrey' },
  { name: 'Tyne and Wear', value: 'Tyne and Wear' },
  { name: 'Warwickshire', value: 'Warwickshire' },
  { name: 'West Berkshire', value: 'West Berkshire' },
  { name: 'West Midlands', value: 'West Midlands' },
  { name: 'West Sussex', value: 'West Sussex' },
  { name: 'West Yorkshire', value: 'West Yorkshire' },
  { name: 'Wiltshire', value: 'Wiltshire' },
  { name: 'Worcestershire', value: 'Worcestershire' },
  { name: 'Flintshire', value: 'Flintshire' },
  { name: 'Glamorgan', value: 'Glamorgan' },
  { name: 'Merionethshire', value: 'Merionethshire' },
  { name: 'Monmouthshire', value: 'Monmouthshire' },
  { name: 'Montgomeryshire', value: 'Montgomeryshire' },
  { name: 'Pembrokeshire', value: 'Pembrokeshire' },
  { name: 'Radnorshire', value: 'Radnorshire' },
  { name: 'Anglesey', value: 'Anglesey' },
  { name: 'Breconshire', value: 'Breconshire' },
  { name: 'Caernarvonshire', value: 'Caernarvonshire' },
  { name: 'Cardiganshire', value: 'Cardiganshire' },
  { name: 'Carmarthenshire', value: 'Carmarthenshire' },
  { name: 'Denbighshire', value: 'Denbighshire' },
  { name: 'Aberdeen City', value: 'Aberdeen City' },
  { name: 'Aberdeenshire', value: 'Aberdeenshire' },
  { name: 'Angus', value: 'Angus' },
  { name: 'Argyll and Bute', value: 'Argyll and Bute' },
  { name: 'City of Edinburgh', value: 'City of Edinburgh' },
  { name: 'Clackmannanshire', value: 'Clackmannanshire' },
  { name: 'Dumfries and Galloway', value: 'Dumfries and Galloway' },
  { name: 'Dundee City', value: 'Dundee City' },
  { name: 'East Ayrshire', value: 'East Ayrshire' },
  { name: 'East Dunbartonshire', value: 'East Dunbartonshire' },
  { name: 'East Lothian', value: 'East Lothian' },
  { name: 'East Renfrewshire', value: 'East Renfrewshire' },
  { name: 'Eilean Siar', value: 'Eilean Siar' },
  { name: 'Falkirk', value: 'Falkirk' },
  { name: 'Fife', value: 'Fife' },
  { name: 'Glasgow City', value: 'Glasgow City' },
  { name: 'Highland', value: 'Highland' },
  { name: 'Inverclyde', value: 'Inverclyde' },
  { name: 'Midlothian', value: 'Midlothian' },
  { name: 'Moray', value: 'Moray' },
  { name: 'North Ayrshire', value: 'North Ayrshire' },
  { name: 'North Lanarkshire', value: 'North Lanarkshire' },
  { name: 'Orkney Islands', value: 'Orkney Islands' },
  { name: 'Perth and Kinross', value: 'Perth and Kinross' },
  { name: 'Renfrewshire', value: 'Renfrewshire' },
  { name: 'Scottish Borders', value: 'Scottish Borders' },
  { name: 'Shetland Islands', value: 'Shetland Islands' },
  { name: 'South Ayrshire', value: 'South Ayrshire' },
  { name: 'South Lanarkshire', value: 'South Lanarkshire' },
  { name: 'Stirling', value: 'Stirling' },
  { name: 'West Dunbartonshire', value: 'West Dunbartonshire' },
  { name: 'West Lothian', value: 'West Lothian' },
  { name: 'Antrim', value: 'Antrim' },
  { name: 'Armagh', value: 'Armagh' },
  { name: 'Down', value: 'Down' },
  { name: 'Fermanagh', value: 'Fermanagh' },
  { name: 'Derry and Londonderry', value: 'Derry and Londonderry' },
  { name: 'Tyrone', value: 'Tyrone' }
];

export default counties;
