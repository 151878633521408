/* eslint-disable max-len */
import React from 'react';
import './ultrasound-id-icon.scss';

const UltrasoundIdIcon = () => (
  <svg className="ultrasound-id-icon" viewBox="0 0 16.87 16.869">
    <path d="M-6057.757-296.417l-6.035-6.035a3.075,3.075,0,0,0-2.225-.891l-4.636.108a3.042,3.042,0,0,0-2.974,2.974l-.108,4.635a3.06,3.06,0,0,0,.891,2.225l6.036,6.035a3.025,3.025,0,0,0,2.153.892,3.024,3.024,0,0,0,2.153-.892l4.744-4.744a3.023,3.023,0,0,0,.892-2.154A3.023,3.023,0,0,0-6057.757-296.417Zm-1.353,2.954-4.744,4.744a1.123,1.123,0,0,1-.8.331,1.123,1.123,0,0,1-.8-.331l-6.035-6.035a1.138,1.138,0,0,1-.332-.827l.108-4.636a1.13,1.13,0,0,1,1.1-1.1l4.636-.108h.027a1.136,1.136,0,0,1,.8.331l6.036,6.035a1.126,1.126,0,0,1,.331.8A1.126,1.126,0,0,1-6059.11-293.463Z" transform="translate(6073.735 303.343)"/>
    <circle cx="1.866" cy="1.866" r="1.866" transform="translate(3.685 3.685)"/>
  </svg>
);

export default UltrasoundIdIcon;
