import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './org-list-view-item.scss';
import moment from 'moment';

const OrgListViewItem = ({ org, match }) => {
  const name = org.name ? (
    <Link to={ `${match.url}/${org.id}`}>
      <h3 className="name">{ org.name }</h3>
    </Link>
  ) : null;
  const lastUpdate = org.lastUpdate ? (
    <span className="update-info">
      { `Last changed ${moment(org.lastUpdate.dateTime).fromNow()} 
        by ${org.lastUpdate.userName || 'unknown'}` }
    </span>
  ) : null;
  const timestamp = org.id.toString().substring(0, 8);
  let creationDate = new Date(parseInt(timestamp, 16) * 1000);
  const creationString = `Created ${moment(creationDate).fromNow()}`;
  return (
    <div className="org-list-item" data-cy="org-list-view-item">
      <div className="block name-block">
        { lastUpdate }
        { name }
      </div>
      <div className="block info-block">
        <div className="top">
          <div className="creationDate">{ creationString }</div>
        </div>
      </div>
      <div className="block edit-button-block"
        data-cy={ 'edit-block-' + org.id }>
        <Link to={ `${match.url}/${org.id}`}
          className="edit-link">
            Select
        </Link>
      </div>
    </div>
  );
};

OrgListViewItem.propTypes = {
  org: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default OrgListViewItem;
