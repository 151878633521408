export const cowMaturity =
  ['Maiden heifer', '1st calved', '2nd calved', 'Mature cow'];

export const updateObjectAttribute = (initialObj, fieldName, fieldValue) => {
  const obj = JSON.parse(JSON.stringify(initialObj));
  const parts = fieldName.split('.');
  if (parts.length < 2) {
    obj[fieldName] = fieldValue;
  } else if (parts.length === 2) {
    obj[parts[0]] = obj[parts[0]] || {};
    obj[parts[0]][parts[1]] = fieldValue;
    // console.log(obj[parts[0]][parts[1]], fieldValue);
  } else if (parts.length === 3) {
    obj[parts[0]] = obj[parts[0]] || {};
    obj[parts[0]][parts[1]] = obj[parts[0]][parts[1]] || {};
    obj[parts[0]][parts[1]][parts[2]] = fieldValue;
  }
  return obj;
};

export const getObjectAttribute = (obj, key) => {
  try {
    const attributePath = key.split('.');
    let attribute = obj;
    attributePath.forEach(attributeName => {
      attribute = attribute[attributeName];
    });
    return attribute;
  } catch (error) {
    return null;
  }
};
