import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../form/TextField';
import DateField from '../../form/DateField';
import { useDispatch, useSelector } from 'react-redux';
import { getAnimalEditErrors, getAnimalUnderEdit } from '../reducer';
import { getObjectAttribute, updateObjectAttribute } from '../util';
import { updateAnimalUnderEdit } from '../actions';
// import ToolTip from '../../util/tooltips/components/ToolTip';
import ExpandableFormSection from '../util/components/ExpandableFormSection';

const fields = [
  'slaughterDate', 'cuttingDate', 'cuttingPlantID', 'slaughterHouseID'
];

const FOOD_ESTABLISHMENTS_LINK =
  'https://www.food.gov.uk/business-guidance/approved-food-establishments';

const labels = {
  slaughterDate: {
    name: 'Slaughter Date',
    type: 'date'
  },
  cuttingDate: {
    name: 'Cutting Date',
    type: 'date'
  },
  cuttingPlantID: {
    name: 'Cutting Plant ID',
    type: 'text'
  },
  slaughterHouseID: {
    name: 'Slaughter House ID',
    type: 'text'
  }
};

const BeefProcessingAnimalFields = ({ showErrors }) => {
  const dispatch = useDispatch();
  const animal = useSelector(getAnimalUnderEdit);
  const errors = useSelector(getAnimalEditErrors);
  const update = (param, value) => {
    const newAnimal = updateObjectAttribute(animal, param, value);
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const getAttribute = key => getObjectAttribute(animal, key);
  const getError = key => {
    if (showErrors && errors && errors[key]) return errors[key];
    return null;
  };
  let isErrored = false;
  fields.forEach(field => {
    if (showErrors && getError(`beefProcessing.${field}`)) isErrored = true;
  });
  const fieldElements = fields.map(field => {
    const key = `beefProcessing.${field}`;
    if (labels[field].type === 'date') {
      const strDate = getAttribute(`beefProcessing.${field}`);
      return (
        <DateField name={ labels[field].name }
          key={ key }
          value={ strDate ? new Date(strDate) : null }
          testId={ `animal-${field}-input` }
          error={ getError(key) }
          update={ value => update(key, value) } />
      );
    } else if (labels[field].type === 'text') {
      return (
        <TextField name={ labels[field].name }
          key={ key }
          value={ getAttribute(`beefProcessing.${field}`) }
          testId={ `animal-${field}-input` }
          error={ getError(key) }
          update={ value => update(key, value) } >
          This is the UK government identifier given to all
          licensed meat processing establishments. To find
          the ID for an establishment try
          searching <a href={ FOOD_ESTABLISHMENTS_LINK }
            tartet="_blank" rel="noopenner noreferrer">
            here
          </a>
        </TextField>
      );
    }
  });
  let toolTipMsg = 'Complete this section in order to print packaging labels ';
  toolTipMsg += 'which meet the UK regulatory requirements for beef labelling';
  return (
    <ExpandableFormSection
      isErrored={ isErrored }
      title="Beef processing fields for labels"
      id="beef-processing-expandable"
      toolTip={ toolTipMsg }>
      <form className="animal-form">
        { fieldElements}
      </form>
    </ExpandableFormSection>
  );
};

BeefProcessingAnimalFields.propTypes = {
  showErrors: PropTypes.bool.isRequired
};

export default BeefProcessingAnimalFields;
