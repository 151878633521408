import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './scan-list-view-item.scss';
import UltrasoundIdIcon from '../../../util/icons/components/UltrasoundIDIcon';

const ScanListViewItem = ({ scan, match }) => {
  return (
    <div className="scan-list-item" data-cy={ `scan-list-item-${scan.id}`}>
      <div className="block main-block">
        <Link to={ `${match.url}/${scan.id}`}>
          <h3 className="title" data-cy={ `date-${scan.id}` }>
            <span className="main-text">
              {moment(scan.date).format('MMMM Do YYYY') }
            </span>
            <span className="scan-id">
              <UltrasoundIdIcon />
              { scan.scanId }
            </span>
          </h3>
        </Link>
        <div className="technician-info">
          <span className="techinician-label">
            Scanning Technician:
          </span>
          { ' ' + scan.technician }
        </div>
      </div>
      <div className="block info-block">
        <div className="top">
          <div className="farm">
            { scan.farm }
          </div>
        </div>
        <div className="scan-size">
          { `${scan?.animals?.length || '?'} animals` }
        </div>
      </div>
      <div className="block view-button-block"
        data-cy={ 'view-block-' + scan.id }>
        <Link to={ `${match.url}/${scan.id}`}
          className="view-link">
          <span data-cy={`view-link-${scan.id}`}>View</span>
        </Link>
      </div>
    </div>
  );
};

ScanListViewItem.propTypes = {
  scan: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default ScanListViewItem;
