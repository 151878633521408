import React from 'react';
import DocumentUpload from './DocumentUpload';
import documentDefinitions from '../../../cows/documentDefinitions';
import PropTypes from 'prop-types';
import './farm-level-documents.scss';

const FarmDocuments = ({ documents, addDocument, deleteDocument, orgId }) => {
  let existingDocuments = null;
  try {
    existingDocuments = Object.keys(documents).map(key => {
      const document = documents[key];
      return (
        <div key={ key } className="saved-document">
          <div className="document-type">
            { documentDefinitions[key].name + ':' }
          </div>
          <a className="file-name"
            target='_blank'
            href={ document.src }
            data-cy={ `document-link-${key}`}
            rel="noopener noreferrer">
            { /[^/]*$/.exec(document.src)[0] }
          </a>
          <div className="delete"
            onClick={ () => deleteDocument(key) }>
            x
          </div>
        </div>
      );
    });
  } catch (error) { /* */ }
  const documentElements = existingDocuments ? (
    <div className="saved-documents">
      { existingDocuments }
    </div>
  ) : null;
  return (
    <div className="farm-level-documents">
      <DocumentUpload
        ownerId={ orgId }
        uploaded={ addDocument }
        farmLevelOnly={ true } />
      { documentElements }
    </div>
  );
};

FarmDocuments.propTypes = {
  documents: PropTypes.object.isRequired,
  addDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired
};

export default FarmDocuments;
