import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './outlet-list-view-item.scss';
import moment from 'moment';
import { outletCategories } from '../../../wheretobuy/outletCategories';
import { buildAddressString } from '../../../util/address';

const OutletListViewItem = ({ outlet, match }) => {
  const name = outlet.name ? (
    <Link to={ `${match.url}/${outlet.id}`}>
      <h3 className="name" data-cy={ `name-${outlet.id}` }>
        { outlet.name }
      </h3>
    </Link>
  ) : null;
  const timestamp = outlet.id.toString().substring(0, 8);
  let creationDate = new Date(parseInt(timestamp, 16) * 1000);
  const creationString = `Created ${moment(creationDate).fromNow()}`;
  const addressInfo = outlet.address ? (
    <div className="address-info">
      { buildAddressString(outlet) }
    </div>
  ) : null;
  const category = outletCategories[outlet.category] || '';
  return (
    <div className="outlet-list-item" data-cy="outlet-list-view-item">
      <div className="block name-block">
        { name }
        { addressInfo }
      </div>
      <div className="block info-block">
        <div className="top">
          <div className="creationDate">{ creationString }</div>
        </div>
        <div className="outlet-category">{ category }</div>
      </div>
      <div className="block edit-button-block"
        data-cy={ 'edit-block-' + outlet.id }>
        <Link to={ `${match.url}/${outlet.id}`}
          className="edit-link">
            Edit
        </Link>
      </div>
    </div>
  );
};

OutletListViewItem.propTypes = {
  outlet: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default OutletListViewItem;
