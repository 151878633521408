import React, { Fragment } from 'react';
import TickBoxField from '../../../form/TickBoxField';
import propTypes from 'prop-types';
import { availableVideoPerms, permissionNames } from '../util';

const HelpVidPermsEditor = ({ perms, update }) => {
  perms = perms || [];
  const elements = availableVideoPerms.map(perm => {
    const toggle = () => {
      if (perm === 'public') {
        if (perms.includes('public')) return; // can't turn off "public" permission - need to actually add a restriction
        update(['public']); // setting public, removes other restrictions
      } else {
        let newPerms = [ ...perms ];
        if (perms.includes(perm)) {
          newPerms = perms.filter(p => p !== perm);
          if (newPerms.length === 0) newPerms.push('public');
        } else {
          newPerms = [ ...perms, perm ].filter(p => p !== 'public');
        }
        update(newPerms);
      }
    };
    let toolTip = 'Check the box to restrict view of this video to ';
    toolTip += `users with "${availableVideoPerms[perm]}" permissions`;
    if (perm === 'public') {
      toolTip = null;
    }
    return (
      <TickBoxField
        key={ perm }
        title={ permissionNames[perm] }
        isOn={ perms.includes(perm) }
        toggle={ toggle }
        testId={ `${perm}-permission-tickbox`} >
        { toolTip }
      </TickBoxField>
    );
  });
  return (
    <Fragment>
      { elements }
    </Fragment>
  );
};

HelpVidPermsEditor.propTypes = {
  perms: propTypes.array,
  update: propTypes.func
};

export default HelpVidPermsEditor;
