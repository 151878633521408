import React, { Fragment, useEffect } from 'react';
import history from '../../goodBeefIndex/gbiHistory';
import PropTypes from 'prop-types';
import './sticky-save-buttons.scss';

/*
  React is too slow - do this directly in js
*/
const handleScroll = () => {
  const marker = document.getElementById('section-end');
  if (marker) {
    const rect = marker.getBoundingClientRect();
    let bottom = window.innerHeight || document.documentElement.clientHeight;
    bottom = bottom - 95;
    const fix = rect.bottom > bottom;
    const buttonRow = document.getElementById('main-save-delete-controls');
    if (buttonRow.className === 'fixed' && !fix) {
      buttonRow.className = '';
    } else if (buttonRow.className !== 'fixed' && fix) {
      buttonRow.className = 'fixed';
    }
  }
};

const StickySaveButtons = ({
  backUrl,
  saveButtonClass,
  handleSaveClicked,
  deleteButtonClass,
  handleDeleteClicked,
  children
}) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  });
  return (
    <Fragment>
      <div id="section-end"></div>
      <div id="main-save-delete-controls">
        <div className="fade"></div>
        <div className="button-row center">
          <div className="cancel button"
            data-cy="cancel-button"
            onClick={ () => history.push(backUrl) }>
      Cancel
          </div>
          <div className={ saveButtonClass }
            data-cy="save-button"
            onClick={ handleSaveClicked }>
      Save
          </div>
          <div className={ deleteButtonClass }
            data-cy="delete-button"
            onClick={ handleDeleteClicked }>
      Delete
          </div>
        </div>
        { children }
      </div>
    </Fragment>
  );
};

StickySaveButtons.propTypes = {
  saveButtonClass: PropTypes.string.isRequired,
  backUrl: PropTypes.string,
  handleSaveClicked: PropTypes.func.isRequired,
  deleteButtonClass: PropTypes.string.isRequired,
  handleDeleteClicked: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default StickySaveButtons;
