/* eslint-disable max-len */
import React from 'react';
import './scan-calendar-icon.scss';

const ScanCalendarIcon = () => (
  <svg className="scan-calendar-icon" viewBox="0 0 14.738 13.967">
    <path d="M-6054.831-560.263v-1.807h-1.95v1.807h-3.064v-1.807h-1.949v1.807h-3.888v7.961a4.2,4.2,0,0,0,4.2,4.2h6.339a4.2,4.2,0,0,0,4.2-4.2v-7.961Zm1.938,7.961a2.253,2.253,0,0,1-2.25,2.25h-6.339a2.253,2.253,0,0,1-2.25-2.25v-6.011h1.939v1.4h1.949v-1.4h3.064v1.4h1.95v-1.4h1.938Z" transform="translate(6065.681 562.07)"/>
  </svg>
);

export default ScanCalendarIcon;
