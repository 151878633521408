import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BigDonutChart from '../../util/charts/components/BigDonutChart';
import './animal-score-display.scss';
import GoodBeefSheild from '../../util/icons/components/GoodBeefShield';
import MobileBigDonutChart
  from '../../util/charts/components/MobileBigDonutChart';
import {
  calculateScore,
  calculateElementScore
} from '../scoreDefinitions/util';
import { getScoreDefinitions } from '../scoreDefinitions/reducer';

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

class AnimalScoreDisplay extends Component {
  constructor (props) {
    super(props);
    this.state = {
      openSection: 'production',
      version: window.innerWidth > 500 ? 'desktop' : 'mobile'
    };
    this.containerRef = React.createRef();
    this.showSection = this.showSection.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount () {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }
  showSection (openSection) {
    this.setState({ openSection });
    this.props.selectElement(openSection);
  }
  handleResize () {
    const version = window.innerWidth > 500 ? 'desktop' : 'mobile';
    this.setState({ version });
  }
  getTriangleStyle () {
    let percentageWidth = 0;
    switch (this.state.openSection) {
      case 'production':
        percentageWidth = this.state.version === 'mobile' ? 17 : 33.4;
        break;
      case 'eating':
        percentageWidth = this.state.version === 'mobile' ? 50 : 60;
        break;
      case 'environment':
        percentageWidth = this.state.version === 'mobile' ? 83 : 86.7;
        break;
    }
    return { left: percentageWidth + '%' };
  }
  renderBigDonuts () {
    let donutElements = [];
    const prodScore = calculateElementScore(
      this.props.animal,
      'production',
      this.props.scoreDefinitions
    );
    const eatingScore = calculateElementScore(
      this.props.animal,
      'eating',
      this.props.scoreDefinitions
    );
    const environmentScore = calculateElementScore(
      this.props.animal,
      'environment',
      this.props.scoreDefinitions
    );
    donutElements.push(
      <MobileBigDonutChart
        key="production"
        onClick={() => this.showSection('production')}
        score={ prodScore }
        sectionIsOpen={ this.state.openSection === 'production' }
        type="production" />
    );
    donutElements.push(
      <MobileBigDonutChart
        key="eating"
        onClick={() => this.showSection('eating')}
        score={ eatingScore }
        sectionIsOpen={ this.state.openSection === 'eating' }
        type="eating" />
    );
    donutElements.push(
      <MobileBigDonutChart
        key="environment"
        onClick={() => this.showSection('environment')}
        score={ environmentScore }
        sectionIsOpen={ this.state.openSection === 'environment' }
        type="environment" />
    );
    return donutElements;
  }
  renderDonuts () {
    if (this.state.version === 'mobile') return this.renderBigDonuts();
    let donutElements = [];
    const prodScore = calculateElementScore(
      this.props.animal,
      'production',
      this.props.scoreDefinitions
    );
    const eatingScore = calculateElementScore(
      this.props.animal,
      'eating',
      this.props.scoreDefinitions
    );
    const environmentScore = calculateElementScore(
      this.props.animal,
      'environment',
      this.props.scoreDefinitions
    );
    donutElements.push(
      <BigDonutChart
        key="production"
        onClick={() => this.showSection('production')}
        score={ prodScore }
        sectionIsOpen={ this.state.openSection === 'production' }
        type="production" />
    );
    donutElements.push(
      <BigDonutChart
        key="eating"
        onClick={() => this.showSection('eating')}
        score={ eatingScore }
        sectionIsOpen={ this.state.openSection === 'eating' }
        type="eating" />
    );
    donutElements.push(
      <BigDonutChart
        key="environment"
        onClick={() => this.showSection('environment')}
        score={ environmentScore }
        sectionIsOpen={ this.state.openSection === 'environment' }
        type="environment" />
    );
    return donutElements;
  }
  renderShield () {
    if (this.state.version === 'mobile') return null;
    const shieldScore =
      calculateScore(this.props.animal, this.props.scoreDefinitions);
    return (
      <Fragment>
        <div className="line"></div>
        <GoodBeefSheild score={ shieldScore } />
      </Fragment>
    );
  }
  render () {
    const noClass = this.state.openSection ? 'figures visible' : 'figures';
    const shieldScore =
      calculateScore(this.props.animal, this.props.scoreDefinitions);
    const mobileLogo = this.state.version === 'mobile' ? (
      <div className="gbi-logo">
        <GoodBeefSheild score={ shieldScore } />
      </div>
    ) : null;
    const className = this.props.scoreError ? 'scores-are-errored' : '';
    const errorMsg = this.props.scoreError ? (
      <div className="error-wrapper">{ this.props.scoreError }</div>
    ) : null;
    return (
      <article id='animal-score-display'
        ref={this.containerRef}
        className={ className }>
        { mobileLogo }
        <div className="mobile-sheild-wrapper gbi-only">
          <GoodBeefSheild score={ shieldScore } />
        </div>
        <div className='donuts'>
          { this.renderShield() }
          { this.renderDonuts() }
        </div>
        <div className={ noClass }>
          <div className="pointer-wrapper">
            <div className="triangle" style={ this.getTriangleStyle()}>
            </div>
          </div>
          <div className="table">
            { this.props.children }
            { errorMsg }
          </div>
        </div>
      </article>
    );
  }
};

AnimalScoreDisplay.propTypes = {
  animal: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cowName: PropTypes.string
    })
  }),
  selectElement: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  scoreDefinitions: PropTypes.object,
  scoreError: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default connect(mapStateToProps)(AnimalScoreDisplay);
