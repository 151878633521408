import React, { useEffect } from 'react';
import './score-list-view.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScores } from '../actions';
import { getScores, hasFetchedScores } from '../reducer';
import ScoreListViewHeader from './ScoreListViewHeader';
import PropTypes from 'prop-types';
import ScoreListViewItem from './ScoreListViewItem';

const ScoresListView = ({ match, history }) => {
  const dispatch = useDispatch();
  const scores = useSelector(getScores);
  const hasFetched = useSelector(hasFetchedScores);
  useEffect(() => {
    if (!scores || !hasFetched || scores.length < 1) {
      dispatch(fetchScores());
    }
  }, []);
  const scoreListItems = scores.map(score => (
    <ScoreListViewItem key={ score.id } score={score} match={match} />
  ));
  return (
    <div id="admin-score-list" className="bb-page-wrapper scores">
      <h2 className="caption">Score Definitions</h2>
      <ScoreListViewHeader />
      { scoreListItems }
    </div>
  );
};

ScoresListView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default ScoresListView;
