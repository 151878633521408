import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getUsersObj } from '../reducer';
import { fetchUser, updateUser, deleteUser } from '../actions';
import './admin-user.scss';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import { Link } from 'react-router-dom';
import UserStatusString from './UserStatusString';
import TextField from '../../../form/TextField';
import { userHasChanged, getUserValidationErrors } from '../util';
import Modal from '../../../main/components/Modal';
import GBIButton from '../../../util/buttons/components/GBIButton';
import mongoid from 'mongoid-js';
import TickBoxField from '../../../form/TickBoxField';
import TopLevelFormError from '../../../util/components/TopLevelFormError';
import UserOrgs from './UserOrgs';
import TickIcon from '../../../util/icons/components/TickIcon';

const UserDetail = ({ match, history }) => {
  const userId = match.params.userId;
  const userObject = useSelector(getUsersObj);
  const savedUser = userObject[userId];
  // console.log(`userId ${userId} savedUser ${JSON.stringify(savedUser)}`);
  const localUser = (userId !== 'new' && !!savedUser) ?
    JSON.parse(JSON.stringify(savedUser)) : {
      id: mongoid(), emailAuthenticated: true
    };
  // console.log(`localUser ${JSON.stringify(localUser)}`);
  const [user, setUser] = useState(localUser);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (savedUser) setUser(savedUser);
    // setUser(savedUser);
    // console.log(`called setUser in useEffect - ${JSON.stringify(savedUser)}`);
  }, [savedUser]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId !== 'new' && !savedUser) dispatch(fetchUser(userId));
  }, []);
  const backUrl = match.url.replace('/' + userId, '');
  const handleSaveClicked = () => {
    const errors = getUserValidationErrors(user);
    setErrors(errors);
    if (Object.keys(errors).length < 1 && userHasChanged(savedUser, user)) {
      dispatch(updateUser(user));
    }
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClicked = () => {
    setShowDelete(true);
  };
  const updateUserName = name => {
    const newUser = JSON.parse(JSON.stringify(user));
    newUser.name = name;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const updateUserPhone = phone => {
    const newUser = JSON.parse(JSON.stringify(user));
    newUser.phone = phone;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const updateEmail = email => {
    const newUser = JSON.parse(JSON.stringify(user));
    newUser.email = email.toLowerCase();
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  let saveButtonClass = 'button';
  if (!userHasChanged(savedUser, user)) {
    saveButtonClass += ' disabled';
  }
  const deleteButtonClass = 'button';
  const deleteUserFromDB = () => {
    dispatch(deleteUser(savedUser));
    history.push(backUrl);
  };
  const togglePostOnBB = () => {
    const newUser = JSON.parse(JSON.stringify(user));
    const permissions = newUser.permissions || {};
    permissions.canPostOnBeefBook = !permissions.canPostOnBeefBook;
    newUser.permissions = permissions;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const togglePostOnGBI = () => {
    const newUser = JSON.parse(JSON.stringify(user));
    const permissions = newUser.permissions || {};
    permissions.canPostOnGBI = !permissions.canPostOnGBI;
    newUser.permissions = permissions;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const toggleFarmerPermissions = () => {
    const newUser = JSON.parse(JSON.stringify(user));
    const permissions = newUser.permissions || {};
    permissions.canViewFarmerPages = !permissions.canViewFarmerPages;
    newUser.permissions = permissions;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const toggleUltrasoundPermissions = () => {
    const newUser = JSON.parse(JSON.stringify(user));
    const permissions = newUser.permissions || {};
    permissions.ultrasound = !permissions.ultrasound;
    newUser.permissions = permissions;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const errorDetails = Object.values(errors).length < 1 ? null : (
    <TopLevelFormError />
  );
  let title = user ? user.email : '';
  if (user && user.name) title = user.name;
  const deleteModal = showDelete ? (
    <Modal close={ () => setShowDelete(false) }
      pos={ window.scrollY }>
      <div className="delete-user-modal-content">
        <h2 className="title">{ `Delete user ${title}?` }</h2>
        <h3 className='sub-title'>{ 'this action can not be undone' }</h3>
        <div className="button-row">
          <GBIButton
            onClick={ () => setShowDelete(false) }
            label="cancel"
            className="light" />
          <GBIButton onClick={ deleteUserFromDB } label="delete" />
        </div>
      </div>
    </Modal>
  ) : null;
  const emailField = savedUser && savedUser.saved ? null : (
    <TextField name="email"
      error={ errors.email }
      value={ user ? user.email : '' }
      update={ updateEmail } />
  );
  const email = (user && user.email && user.name) ? (
    <h4 className="email">{ user.email }</h4>
  ) : null;
  const orgsList = user && user.admin !== true ? (
    <UserOrgs orgIds={ user && user.orgIds ? user.orgIds : []} />
  ) : null;
  const adminIcon = user && user.admin ? (
    <div className="administrator" data-cy="admin-icon">
      <TickIcon />
      <span className="text">Administrator</span>
    </div>
  ) : null;
  return (
    <div id="user">
      <Link className="back-to-users-link"
        to={ backUrl }>
        <ChevronIcon /> Back to users
      </Link>
      <h2 data-cy="user-detail-title">{ title } </h2>
      { email }
      <div style={{ display: 'none' }}>updated</div>
      { orgsList }
      { adminIcon }
      <UserStatusString user={ user } />
      <form onSubmit={ e => e.preventDefault() }>
        <TextField name="Name"
          value={ user ? user.name || '' : '' }
          error={ null }
          update={ updateUserName } />
        <TextField name="Phone"
          value={ user && user.phone ? user.phone : '' }
          error={ null }
          update={ updateUserPhone } />
        { emailField }
        <TickBoxField
          title="BeefBook Permissions"
          isOn={
            user && user.permissions && user.permissions.canPostOnBeefBook }
          error={ null }
          toggle={ togglePostOnBB } />
        <TickBoxField
          title="GoodBeef Index Permissions"
          isOn={
            user && user.permissions && user.permissions.canPostOnGBI }
          error={ null }
          toggle={ togglePostOnGBI } />
        <TickBoxField
          title="Ultrasound Permissions"
          isOn={
            user && user.permissions && user.permissions.ultrasound }
          error={ null }
          toggle={ toggleUltrasoundPermissions } />
        <TickBoxField
          title="Farmer Page Permissions"
          isOn={
            user && user.permissions && user.permissions.canViewFarmerPages }
          error={ null }
          toggle={ toggleFarmerPermissions } />
      </form>
      <div className="button-row center">
        <div className="cancel button"
          onClick={ () => history.push(backUrl) }>
          Cancel
        </div>
        <div className={ saveButtonClass }
          onClick={ handleSaveClicked }>
          Save
        </div>
        <div className={ deleteButtonClass }
          onClick={ handleDeleteClicked }>
          Delete
        </div>
      </div>
      { errorDetails }
      { deleteModal }
    </div>
  );
};

UserDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default UserDetail;
