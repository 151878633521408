import React from 'react';
import propTypes from 'prop-types';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';
import { getCSV } from '../breedplanExportCSV';

const downloadFile = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute(
    'href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const BreedplanExport = ({ scanSession }) => {
  if (!scanSession) return null;
  const download = () => {
    const csv = getCSV(scanSession);
    downloadFile(`scanning_session_${scanSession.scanId}.csv`, csv);
  };
  return (
    <div className="breedplan-export"
      data-cy="breedplan-export-link"
      onClick={ download }
      rel="noopener noreferrer">
      <span className="label">Breedplan Export</span>
      <DownloadIcon />
    </div>
  );
};

BreedplanExport.propTypes = {
  scanSession: propTypes.object
};

export default BreedplanExport;
