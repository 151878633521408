import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DropDownArrowIcon
  from '../../../util/icons/components/DropDownArrowIcon';
import './help-page-row.scss';

const HelpPageVideo = ({ vid }) => {
  const [ open, setOpen ] = useState(false);
  const className = open ? 'help-video-row open' : 'help-video-row';
  const sources = [];
  const data = vid.videoData;
  if (data && data.mp4) {
    sources.push(<source type="video/mp4" src={ data.mp4} key="mp4" />);
  }
  if (data && data.hls) {
    sources.push(
      <source type='application/x-mpegURL' src={ data.hls} key="hls" />
    );
  }
  if (data && data.dash) {
    sources.push(
      <source type="application/dash+xml" src={ data.dash } key="dash" />
    );
  }
  return (
    <li className={ className } onClick={ () => setOpen(!open)}>
      <span data-cy={ `video-title-${vid.id}`}>{ vid.title }</span>
      <DropDownArrowIcon />
      <div className="video" onClick={ e => e.stopPropagation() }>
        <video
          className="how-to-video"
          data-cy={ `video-${vid.id}`}
          playsInline=""
          poster={ data.poster }
          controls>
          { sources }
        </video>
      </div>
    </li>
  );
};

HelpPageVideo.propTypes = {
  vid: PropTypes.object.isRequired
};

export default HelpPageVideo;
