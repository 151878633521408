/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import './copy-button.scss';

const CopyButton = ({ onClick }) => (
  <div onClick={ onClick } className="material-copy-button">
    <svg viewBox="0 0 48 48">
      <path d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z"/>
    </svg>
  </div>
);

CopyButton.propTypes = {
  onClick: PropTypes.func
};

export default CopyButton;
