import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEditingOrgErrors,
  getOrgsObj,
  getOrgUnderEdit
} from '../reducer';
import { fetchOrg, updateOrg, deleteOrg, setEditingOrg } from '../actions';
import './admin-org.scss';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import { Link, Prompt } from 'react-router-dom';
import TextField from '../../../form/TextField';
import { newOrgHasChanged, orgHasChanged } from '../util';
import Modal from '../../../main/components/Modal';
import GBIButton from '../../../util/buttons/components/GBIButton';
import mongoid from 'mongoid-js';
import OrgUsers from './OrgUsers';
import { timeFromNow } from '../../../util/datesAndAges';
import FarmDocuments from '../../documents/components/FarmDocuments';
import AdminAPI from '../../AdminAPI';
import getS3ObjectFromURL from '../../util/getS3ObjectFromURL';
import Images from '../../imageAdmin/components/Images';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import FarmLevelScores from './FarmLevelScores';
import { captureException } from '../../../util/logger';
import { getScoreDefinitions } from '../../scoreDefinitions/reducer';
import VideoAdminSection from '../../videoAdmin/components/VideoAdminSection';
import { getOrgIds, isAdmin } from '../../../util/jwt';
import Address from '../../util/components/Address';
import TopLevelFormError from '../../../util/components/TopLevelFormError';
import ExpandableFormSection from '../../util/components/ExpandableFormSection';
import StickySaveButtons from '../../components/StickySaveButtons';
import NumberField from '../../../form/NumberField';
import TickBoxField from '../../../form/TickBoxField';
import { getGBIDomain } from '../../../util/site';
import OrgLogo from './OrgLogo';
import TextAreaField from '../../../form/TextAreaField';

const OrgDetail = ({ match, history }) => {
  const dispatch = useDispatch();
  const id = useRef(
    match.params.orgId === 'new' ? mongoid() : match.params.orgId
  );
  const orgId = match.params.orgId;
  const scoreDefinitions = useSelector(getScoreDefinitions);
  const [ newFiles, setNewFiles ] = useState([]);
  const [ deletedFiles, setDeletedFiles ] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [ showErrors, setShowErrors ] = useState(false);
  const newFilesRef = useRef(newFiles);
  const deletedFilesRef = useRef(deletedFiles);
  const orgObject = useSelector(getOrgsObj);
  const savedOrg = orgObject[id.current];
  const org = useSelector(getOrgUnderEdit);
  const errors = useSelector(getEditingOrgErrors);

  useEffect(() => {
    if (match.params.orgId !== 'new') {
      dispatch(fetchOrg(match.params.orgId)); // always fetch - fetch all doesn't include user list
    }
  }, []);

  useEffect(() => {
    newFilesRef.current = newFiles;
  }, [newFiles]);
  useEffect(() => {
    deletedFilesRef.current = deletedFiles;
  }, [deletedFiles]);
  useEffect(() => {
    return () => {
      setOrg(null);
      if (newFilesRef.current && newFilesRef.current.length > 0) {
        AdminAPI.deleteDocuments(newFilesRef.current).catch(
          error => captureException(error)
        );
      }
    };
  }, []);

  const setOrg = org => dispatch(setEditingOrg(org));

  if (!org) {
    if (orgId === 'new') {
      setOrg({ id: id.current });
    } else if (savedOrg) {
      setOrg(savedOrg);
    }
    return (
      <div className='org-api-blanket visible' >
        <LoadingIcon />
      </div>
    );
  }
  const backUrl = (isAdmin() || getOrgIds().length > 1) ?
    match.url.replace('/' + orgId, '') : '/account';
  const handleSaveClicked = () => {
    setShowErrors(true);
    if (Object.keys(errors).length < 1
      && orgHasChanged(savedOrg, org, scoreDefinitions)) {
      let filesToRemove = [];
      if (deletedFilesRef.current && deletedFilesRef.current.length > 0) {
        filesToRemove = deletedFilesRef.current.slice();
      }
      dispatch(updateOrg(org), filesToRemove);
      setNewFiles([]);
      setDeletedFiles([]);
    }
  };
  const handleDeleteClicked = () => {
    setShowDelete(true);
  };
  const updateOrgName = name => {
    const newOrg = JSON.parse(JSON.stringify(org));
    newOrg.name = name;
    setOrg(newOrg);
  };
  const updateOrgAttribute = (attribute, value) => {
    const newOrg = JSON.parse(JSON.stringify(org));
    newOrg[attribute] = value;
    setOrg(newOrg);
  };
  const updateHerdIds = herdIdsString => {
    if (herdIdsString === '') return;
    const newOrg = JSON.parse(JSON.stringify(org));
    const herdIds = herdIdsString.split(',')
      .map(id => id.trim())
      .filter(id => id !== '');
    if (herdIds.length === 0) return;
    newOrg.herdIds = herdIds;
    setOrg(newOrg);
  };
  const updateBreedIds = breedsString => {
    const newOrg = JSON.parse(JSON.stringify(org));
    const breeds = breedsString.split(',').map(id => id.trim());
    newOrg.breeds = breeds;
    setOrg(newOrg);
  };
  let saveButtonClass = 'button';
  if (!orgHasChanged(savedOrg, org, scoreDefinitions)) {
    saveButtonClass += ' disabled';
  }
  const deleteButtonClass = 'button';
  const deleteOrgFromDB = () => {
    dispatch(deleteOrg(savedOrg, newFiles));
    history.push(backUrl);
  };
  const addDocument = (src, documentType) => {
    const newOrg = JSON.parse(JSON.stringify(org));
    const documents = newOrg.documents || {};
    documents[documentType] = { src };
    newOrg.documents = documents;
    setOrg(newOrg);
    const s3Object = getS3ObjectFromURL(src);
    setNewFiles([ ...newFiles, s3Object ]);
  };
  const addImage = src => {
    const newOrg = JSON.parse(JSON.stringify(org));
    const images = newOrg.images || [];
    const imageData = {
      fileName: src.substring(src.lastIndexOf('/') + 1),
      processed: false,
      base: src
    };
    images.push(imageData);
    setNewFiles([ ...newFiles, getS3ObjectFromURL(src) ]);
    newOrg.images = images;
    setOrg(newOrg);
  };
  const deleteImage = image => {
    const newOrg = JSON.parse(JSON.stringify(org));
    let images = newOrg.images || [];
    images = images.filter(img => {
      return img.base !== image.base;
    });
    newOrg.images = images;
    setOrg(newOrg);
  };
  const moveImage = (oldIndex, newIndex) => {
    const newOrg = JSON.parse(JSON.stringify(org));
    let images = newOrg.images;
    const image = images[oldIndex];
    images.splice(oldIndex, 1);
    images.splice(newIndex, 0, image);
    newOrg.images = images;
    setOrg(newOrg);
  };
  const deleteDocument = documentType => {
    // soft delete - we keep the files (est £200 annually for 1m ophaned files)
    // we don't easily know which animals might be using them.
    const newOrg = JSON.parse(JSON.stringify(org));
    const documents = newOrg.documents || {};
    delete documents[documentType];
    newOrg.documents = documents;
    setOrg(newOrg);
  };
  const updateAddress = address => {
    const newOrg = JSON.parse(JSON.stringify(org));
    newOrg.address = address;
    setOrg(newOrg);
  };
  const updateOrgScores = scores => {
    const newOrg = JSON.parse(JSON.stringify(org));
    newOrg.scores = scores;
    setOrg(newOrg);
  };
  const updateVideo = videoData => {
    const newOrg = JSON.parse(JSON.stringify(org));
    newOrg.videoData = videoData;
    setOrg(newOrg);
  };
  let title = org && org.name ? org.name : 'New Organisation';
  const deleteModal = showDelete ? (
    <Modal
      pos={ window.scrollY }
      contentClass="delete-org-modal-content"
      close={ () => setShowDelete(false) }>
      <div>
        <h2 className="title">{ `Delete org ${title}?` }</h2>
        <p className="text">{ 'this action can not be undone' }</p>
        <div className="button-row">
          <GBIButton
            onClick={ () => setShowDelete(false) }
            label="cancel"
            className="light" />
          <GBIButton onClick={ deleteOrgFromDB } label="delete" />
        </div>
      </div>
    </Modal>
  ) : null;
  const users = org && org.saved ? (
    <OrgUsers
      orgId={ org && org.id ? org.id : '' }
      users={ org && org.users ? org.users : [] } />
  ) : null;
  const lastUpdate = org.lastUpdate ? (
    <span className="update-info">
      { `Last changed ${timeFromNow(org.lastUpdate.dateTime)} 
        by ${org.lastUpdate.userName || 'unknown'}` }
    </span>
  ) : null;
  const video = isAdmin() && org && org.saved ? (
    <VideoAdminSection
      videoData={ org ? org.videoData : null }
      orgId={ org ? org.id : null }
      expandable
      update={ updateVideo } />
  ) : null;
  const images = org && org.saved ? (
    <Images images={ org.images || [] }
      addImage={ addImage }
      deleteImage={ deleteImage }
      ownerId={ org.id }
      expandable
      moveImage={ moveImage }
      maxImages={ 1 }/>
  ) : null;
  const additionalInfoError = showErrors && (
    errors['herdIds'] ||
    errors['breeds'] ||
    errors['websiteURL'] ||
    errors['logo'] ||
    errors['address_town'] ||
    errors['address_county']);
  const errorMsg =
    showErrors && Object.keys(errors) && Object.keys(errors).length ? (
      <TopLevelFormError testId="org-error-message"/>
    ) : null;
  const backText = (isAdmin() || getOrgIds().length > 1) ?
    'Back to organisations' : 'Back to my profile';
  const isMemberFarm = !!(org && org.memberFarm);
  const toggleMemberFarm = () => {
    updateOrgAttribute('memberFarm', !isMemberFarm);
  };
  const memberFarm = (isAdmin()) ? (
    <TickBoxField
      title="Member Farm"
      testId="member-farm-tick-box"
      isOn={ isMemberFarm }
      error={ null }
      toggle={ toggleMemberFarm } >
      Tick the checkbox to display this farm on the Our Farms
      page <a href={ `${getGBIDomain()}/for-consumers/our-farms` }>here</a>
    </TickBoxField>
  ) : null;
  const isFoundingFarm = !!(org && org.foundingFarm);
  const toggleFoundingFarm = () => {
    updateOrgAttribute('foundingFarm', !isFoundingFarm);
  };
  const foundingFarm = (isAdmin()) ? (
    <TickBoxField
      title="Founding Farm"
      testId="founding-farm-tick-box"
      isOn={ isFoundingFarm }
      error={ null }
      toggle={ toggleFoundingFarm } >
      Tick the checkbox to mark this farm as a founding farm in the Our Farms
      page <a href={ `${getGBIDomain()}/for-consumers/our-farms` }>here</a>
    </TickBoxField>
  ) : null;
  const hasChanged = match.params.orgId === 'new' ?
    newOrgHasChanged(org) : orgHasChanged(savedOrg, org, scoreDefinitions);
  const authToolTipMsg = 'Upload authentication documents. That can be used ' +
    'by any animals in your herd';
  return (
    <Fragment>
      <div id="org" data-cy="org-page">
        <Prompt
          when={ hasChanged }
          message={location =>
            'You have unsaved changes - you will lose them if you continue'}
        />
        <Link className="back-to-orgs-link"
          to={ backUrl }>
          <ChevronIcon /> <span data-cy="back-button">{ backText }</span>
        </Link>
        <h2 data-cy="org-page-title">{ title } </h2>
        { lastUpdate }
        <div className="form-fields open">
          <TextField name="Farm (or Organisation) Name"
            value={ org ? org.name || '' : '' }
            error={ showErrors ? errors['name'] : null }
            testId="org-name-input"
            update={ updateOrgName } />
          <TextField name="Farmer"
            value={ org ? org.farmerName || '' : '' }
            error={ showErrors ? errors['farmerName'] : null }
            testId="org-farmerName-input"
            update={ name => updateOrgAttribute('farmerName', name) } />
          { memberFarm }
          { foundingFarm }
        </div>
        <ExpandableFormSection title="Additional Info"
          isErrored={ !!additionalInfoError }
          id="additional-info-expandable">
          <div className="form-fields">
            <Address
              address={ org ? org.address : null }
              errors={ showErrors ? errors : {} }
              onChange={ updateAddress } />
            <TextField name="Herd ID(s)"
              value={ org && org.herdIds ? org.herdIds.join(', ') : '' }
              error={ showErrors ? errors['herdIds'] : null }
              testId="herdIds-input"
              update={ updateHerdIds } >
              { 'Enter your herd ID (as used in the BCMS Cattle Tracing ' +
                'System). If you have multiple herds associated with this ' +
                'organisation then seperate each one with a comma and space ' +
                'eg "123456, 789012, 234567"'}
            </TextField>
            <TextField name="Breed(s)"
              value={ org && org.breeds ? org.breeds.join(', ') : '' }
              error={ showErrors ? errors['breeds'] : null}
              testId="breeds-input"
              update={ updateBreedIds } >
              { 'Enter your breeds you keep on your farm. If you have ' +
                ' multiple breeds then seperate each one with a comma and ' +
                'space. eg "Aberdeen Angus, Ruby Red Devon, Stabiliser"'}
            </TextField>
            <NumberField name="Herd size"
              value={ org && org.herdSize ? org.herdSize : null }
              testId="herd-size"
              error={ showErrors ? errors['herdSize'] : null }
              update={ size => updateOrgAttribute('herdSize', size) } />
            <NumberField name="Farm size (acres)"
              value={ org && org.farmSize ? org.farmSize : null }
              testId="farm-size"
              error={ showErrors ? errors['farmSize'] : null }
              update={ size => updateOrgAttribute('farmSize', size) } />
            <TextField name="Website URL"
              value={ org && org.websiteURL ? org.websiteURL : '' }
              error={ showErrors ? errors['websiteURL'] : null }
              testId="website-url-input"
              update={ url => updateOrgAttribute('websiteURL', url) } />
            <OrgLogo
              ownerId={ org ? org.id : mongoid() }
              current={ org && org.logo ? org.logo : null }
              uploaded={ src => updateOrgAttribute('logo', src) }
              label="Logo" />
            <TextAreaField
              name="Description"
              value={ org && org.description ? org.description : '' }
              error={ showErrors ? errors['description'] : null }
              update={ desc => updateOrgAttribute('description', desc) }
              testId="description-field"
              className="description-field">
              Add a description to be used in promotional material such as the
              Our Farms page
              <a href={ `${getGBIDomain()}/for-consumers/our-farms` }>here</a>
            </TextAreaField>
          </div>
        </ExpandableFormSection>
        <ExpandableFormSection title="Farm level authentication documents"
          toolTip={ authToolTipMsg }
          id="farm-docs-expandable">
          <FarmDocuments documents={ org.documents || {} }
            addDocument={ addDocument }
            deleteDocument={ deleteDocument }
            orgId={ org.id } />
        </ExpandableFormSection>
        { images }
        { video }
        <FarmLevelScores scores={ org.scores }
          update={ updateOrgScores } />
        { users }
        <StickySaveButtons
          handleSaveClicked={ handleSaveClicked }
          handleDeleteClicked={ handleDeleteClicked }
          saveButtonClass={ saveButtonClass }
          deleteButtonClass={ deleteButtonClass }
          backUrl={ backUrl }>
          { errorMsg }
        </StickySaveButtons>
        { deleteModal }
      </div>
    </Fragment>
  );
};

OrgDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default OrgDetail;
