const parsePriceCheckPrice = (priceString, cut, retailer) => {
  if (typeof priceString === 'undefined' || priceString === '') {
    throw new Error(`price must not be empty (${retailer} - ${cut})`);
  }
  if (typeof priceString !== 'string') {
    throw new Error(
      `invalid price of type ${typeof priceString} "${priceString}" ` +
      `(${retailer} - ${cut})`
    );
  }
  try {
    const pounds = parseFloat(priceString);
    const pennies = pounds * 100;
    const intValue = Math.round(pennies);
    return intValue;
  } catch (error) {
    throw new Error(
      `failed to parse price string "${priceString}" ${error.toString()}` +
      `(${retailer} - ${cut})`
    );
  }
};

export default parsePriceCheckPrice;
