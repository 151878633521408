const parseCutName = (nameInput, rowNo, lastRow = false) => {
  if (typeof nameInput === 'undefined' || nameInput === '') {
    let msg = `cut must not be empty, see row ${rowNo}, column 0.`;
    if (lastRow) {
      msg += ' (is this a blank row at the end of the csv?)';
    }
    throw new Error(msg);
  }
  if (typeof nameInput !== 'string') {
    throw new Error(
      `invalid cut name of type ${typeof nameInput} "${nameInput}" ` +
      `(row ${rowNo}, column 0)`
    );
  }
  return nameInput;
};

export default parseCutName;
