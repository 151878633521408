/* eslint-disable max-len */
import React from 'react';
// import GBIButton from '../../../util/buttons/components/GBIButton';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';

const csvStr =
  `Member Code,Herd Prefix,Tattoo,Sex,Birthdate,Reg. Number,Sire Reg. Number,Dam Reg. Number,Tech. Code,Mgmt. Code,Scan Date,Lot Scan Date,Rump Fat Thickness,Rib Fat Thickness,Ribeye Area,IMF,Weight,Scan Type,Test Tag,Comments,
  1000000,382172,402243,B,,,,,,,05/26/2021,05/26/2021,3.81,4.32,64.7,02.31,0495,Y,402243,SC39,
  1000000,382172,202255,B,,,,,,,05/26/2021,05/26/2021,7.37,8.13,72.6,02.95,0493,Y,202255,SC38,
  1000000,382172,402278,B,,,,,,,05/26/2021,05/26/2021,3.81,2.54,51.4,02.42,0376,Y,402278,SC37,
  1000000,382172,202220,B,,,,,,,05/26/2021,05/26/2021,3.05,4.57,53.6,02.22,0441,Y,202220,SC37,
  1000000,382172,102240,B,,,,,,,05/26/2021,05/26/2021,5.33,4.06,62.2,02.00,0489,Y,102240,SC39,
  1000000,382172,502223,B,,,,,,,05/26/2021,05/26/2021,2.54,3.81,65.3,02.71,0518,Y,502223,SC43,
  1000000,382172,302249,B,,,,,,,05/26/2021,05/26/2021,4.83,4.32,68.4,02.65,0482,Y,302249,SC42,
  1000000,382172,302242,B,,,,,,,05/26/2021,05/26/2021,5.84,4.83,61.2,02.74,0474,Y,302242,SC39,
  `;

const ExampleCSVLink = () => {
  const handleClick = () => {
    const file = new window.Blob([csvStr], { type: 'csv' });
    const el = document.createElement('a');
    const url = URL.createObjectURL(file);
    el.href = url;
    el.download = 'example_cuplabs_file_25994_ATA.csv';
    document.body.appendChild(el);
    el.click();
    setTimeout(() => {
      document.body.removeChild(el);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
  return (
    <div className="example-link"
      data-cy="example-csv-link"
      onClick={ handleClick }>
      <span className="label">Example File</span>
      <DownloadIcon />
    </div>
  );
};

export default ExampleCSVLink;
