/* eslint-disable max-len */
import React from 'react';
import './ultrasound-farm-icon.scss';

const UltrasoundFarmIcon = () => (
  <svg className="ultrasound-farm-icon" viewBox="0 0 12.977 18.059">
    <path d="M-6105.532-763.744a6.5,6.5,0,0,0-8.9-2.251,6.444,6.444,0,0,0-2.968,3.981,6.443,6.443,0,0,0,.716,4.913l4.583,7.69a1.152,1.152,0,0,0,.4.4,1.145,1.145,0,0,0,.589.163,1.156,1.156,0,0,0,.993-.563l4.583-7.688v0A6.5,6.5,0,0,0-6105.532-763.744Zm-1.5,5.749-4.069,6.827-4.069-6.828a4.709,4.709,0,0,1-.523-3.588,4.707,4.707,0,0,1,2.167-2.907,4.712,4.712,0,0,1,2.418-.667,4.741,4.741,0,0,1,4.078,2.311A4.746,4.746,0,0,1-6107.036-758Z" transform="translate(6117.596 766.909)"/>
    <circle cx="1.991" cy="1.991" r="1.991" transform="translate(3.675 6.262) rotate(-45)"/>
  </svg>
);

export default UltrasoundFarmIcon;
