import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../util/tables/Pagination';
import AdminSearchBox from '../../adminListSearch/components/AdminSearchBox';
import { fetchUltrasoundScans, setUltrasoundPage } from '../actions';
import {
  getPageOfScans,
  getUltrasoundCount,
  getUltrasoundPage,
  getUltrasoundPageSize,
  hasFetchedScans,
  isFetchingUltrasoundScans
} from '../reducer';
import ScanListViewItem from './ScanListViewItem';
import UltrasoundListViewHeader from './UltrasoundListViewHeader';
import PropTypes from 'prop-types';
import './scan-list-view.scss';
import { hasUltrasoundPerms } from '../../../util/jwt';
import UltrasoundUpload from './UltrasoundUpload';

const filterAttributes = [
  { name: 'Scanning Technician', attribute: 'technician', dataType: 'string' },
  { name: 'Farm Name', attribute: 'farm', dataType: 'string' },
  { name: 'Scan ID', attribute: 'scanId', dataType: 'string' }
];

const UltrasoundListView = ({ match }) => {
  const dispatch = useDispatch();
  const scans = useSelector(getPageOfScans);
  const scanCount = useSelector(getUltrasoundCount);
  const pageSize = useSelector(getUltrasoundPageSize);
  const page = useSelector(getUltrasoundPage);
  const fetched = useSelector(hasFetchedScans);
  const fetching = useSelector(isFetchingUltrasoundScans);
  useEffect(() => {
    if (!fetching && !fetched) {
      dispatch(fetchUltrasoundScans());
    }
  });
  const scanListItems = scans.map(scan => (
    <ScanListViewItem key={ scan.id } scan={ scan} match={ match } />
  ));
  const uploadButton = hasUltrasoundPerms() ? (
    <UltrasoundUpload />
  ) : null;
  return (
    <div id="ultrasound-scan-list"
      className="bb-page-wrapper">
      <h2 className="caption" data-cy="ultrasound-scans-title">
        Scanning Sessions
      </h2>
      <div className="top-row">
        <AdminSearchBox attributes={ filterAttributes } />
        { uploadButton }
      </div>
      <UltrasoundListViewHeader />
      { scanListItems }
      <Pagination
        toPage={ page => dispatch(setUltrasoundPage(page)) }
        total={ Math.ceil(scanCount / pageSize) }
        current={ page } />
    </div>
  );
};

UltrasoundListView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default UltrasoundListView;
