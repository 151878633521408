import React, { useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import GBIButton from '../../../util/buttons/components/GBIButton';
import AdminAPI from '../../AdminAPI';
import ProgressBar from '../../../util/figures/ProgressBar';
import { captureException } from '../../../util/logger';
import VideoAPI from '../VideoAPI';
import useInterval from '../../../util/hooks/useInterval';
import uuid from 'uuid/v4';
import './video-upload.scss';
import Modal from '../../../main/components/Modal';
import ExistingVideo from './ExistingVideo';
import ExpandableFormSection from '../../util/components/ExpandableFormSection';

const VideoAdminSection = ({
  videoData, update, orgId, animalId, uploadText, helpVid, expandable
}) => {
  const fileInputRef = useRef();
  const inputId = useRef(uuid());
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);
  const [polling, setPolling] = useState(false);
  const [pollErrorCount, setPollErrorCount] = useState(null);
  const [processingJobId, setProcessingJobId] = useState(null);
  const [progress, setProgress] = useState(0);
  useInterval(() => {
    VideoAPI.pollEncodingJob(processingJobId)
      .then(response => {
        setPollErrorCount(0);
        const finished = response.data.processed;
        if (finished) {
          setPolling(false);
          setProgress(0);
          const processingError = response.data.processingError || false;
          if (processingError) {
            setError('Error processing video: ' + processingError);
          } else if (response.data.video) {
            update(response.data.video);
          }
        }
      }).catch(error => {
        const msg =
          `Error processing video: ${processingJobId} ${error.toString()}`;
        captureException(msg);
        setPollErrorCount(pollErrorCount + 1);
        if (pollErrorCount > 3) {
          setError(msg);
          setPolling(false);
          setPollErrorCount(0);
        }
      });
  }, polling ? 5000 : null);
  const onProgress = event => {
    if (event.lengthComputable) {
      const progress = event.loaded / event.total;
      setProgress(progress);
    }
  };
  const onUploaded = response => {
    const data = response.data;
    const base = `https://${data.bucket}/${data.name}`;
    // setSrc(base);
    update({ base });
    if (animalId) {
      VideoAPI.encodeAnimalVideo(data.bucket, data.name, orgId, animalId)
        .then(response => {
          setProcessingJobId(response.data.gbiJobId);
          setPolling(true);
        }).catch(error => {
          const msg = `Error uploading video ${error.toString()}`;
          captureException(msg);
          setError(msg);
        });
    } else if (orgId) {
      VideoAPI.encodeOrgVideo(data.bucket, data.name, orgId)
        .then(response => {
          setProcessingJobId(response.data.gbiJobId);
          setPolling(true);
        }).catch(error => {
          const msg = `Error uploading video ${error.toString()}`;
          captureException(msg);
          setError(msg);
        });
    } else if (helpVid) {
      VideoAPI.encodeHelpVideo(
        helpVid.id, helpVid.title, data.bucket, helpVid.tags || [], data.name
      ).then(response => {
        setProcessingJobId(response.data.gbiJobId);
      }).catch(error => {
        const msg = `Error uploading video ${error.toString()}`;
        captureException(msg);
        setError(msg);
      });
    }
  };
  const onChange = event => {
    let name = event.target.files[0].name;
    const ext = name.split('.').pop();
    name = ext && ext.match(/^[a-zA-Z]{3}$/) ? 'video.' + ext : 'video';
    setName(name);
    setShowOverwriteWarning(false);
    AdminAPI.saveFile(
      orgId || 'help-video', name, event.target.files[0], onProgress
    ).then(onUploaded).catch(error => {
      captureException('error uploading document: ' + error);
      setError('File upload failed. Network error');
    });
  };
  const overwriteWarningModal = showOverwriteWarning ? (
    <Modal
      pos={ window.scrollY }
      contentClass="delete-existing-video-modal-content"
      close={ () => setShowOverwriteWarning(false) }>
      <div>
        <h2 className="title">{ `Upload new video?` }</h2>
        <p className="text">{
          'this will erase the existing video, and ' +
          'cannot be undone' }</p>
        <div className="button-row">
          <GBIButton
            onClick={ () => setShowOverwriteWarning(false) }
            label="cancel"
            className="light" />
          <label htmlFor={ inputId.current }>
            <GBIButton label='Replace Video' />
          </label>
        </div>
      </div>
    </Modal>
  ) : null;
  let uploadProgress = null;
  if (progress) {
    const status = progress === 1 && !error ? (
      <div className="uploaded-text">
        {
          uploadText ||
          'Uploaded - video will be attached to your animals after processing'
        }
      </div>
    ) : (
      <ProgressBar percentage={ progress * 100 } />
    );
    uploadProgress = (
      <Fragment>
        <h5 className="uploading-file-name">
          { `upload${progress === 1 ? 'ed' : 'ing'} ${name}` }
        </h5>
        { status }
      </Fragment>
    );
  }
  let newVideo = null;
  if (!progress) {
    if (videoData && videoData.base) {
      newVideo = (
        <GBIButton label='New Video'
          onClick={ () => setShowOverwriteWarning(true) } />
      );
    } else {
      newVideo = (
        <label htmlFor={ inputId.current }>
          <GBIButton label='Upload Video +' />
        </label>
      );
    }
  }
  const currentError = error ?
    <div className="error">{ error } </div> : null;
  const content = (
    <Fragment>
      <ExistingVideo videoData={ videoData } />
      { newVideo }
      { uploadProgress }
      { currentError }
      { overwriteWarningModal }
      <input
        id={ inputId.current }
        ref={fileInputRef}
        className="file-input"
        type="file"
        multiple
        data-cy="document-upload-input"
        onChange={onChange} />
    </Fragment>
  );
  if (expandable) {
    return (
      <ExpandableFormSection title="Video" isErrored={ currentError }>
        <div className="video-admin" data-cy="video-admin-section">
          { content }
        </div>
      </ExpandableFormSection>
    );
  }
  return (
    <div className="video-admin" data-cy="video-admin-section">
      <h4>Video</h4>
      { content }
    </div>
  );
};

VideoAdminSection.propTypes = {
  videoData: PropTypes.object,
  update: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  animalId: PropTypes.string,
  helpVid: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string)
  }),
  uploadText: PropTypes.string,
  expandable: PropTypes.bool
};

export default VideoAdminSection;
