const parseRetailerName = (nameInput, columnNo) => {
  if (typeof nameInput === 'undefined' || nameInput === '') {
    throw new Error(`retailer must not be empty (row 0, column ${columnNo})`);
  }
  if (typeof nameInput !== 'string') {
    throw new Error(
      `invalid retailer name of type ${typeof nameInput} "${nameInput}" ` +
      `(row 0, column ${columnNo})`
    );
  }
  return nameInput;
};

export default parseRetailerName;
