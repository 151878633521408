/* eslint-disable max-len */
import React from 'react';
import './scanning-technician-icon.scss';

const ScanningTechnicianIcon = () => (
  <svg className="scanning-technician-icon" viewBox="0 0 16.079 15.431">
    <path className="a" d="M-5989.069-548.585a21.446,21.446,0,0,1-7.4-1.306.975.975,0,0,1-.628-1.07,8.192,8.192,0,0,1,4.223-5.888,4.6,4.6,0,0,1-.792-2.571,4.6,4.6,0,0,1,4.6-4.6,4.6,4.6,0,0,1,4.6,4.6,4.6,4.6,0,0,1-.792,2.571,8.191,8.191,0,0,1,4.223,5.888.974.974,0,0,1-.627,1.07A21.454,21.454,0,0,1-5989.069-548.585Zm-5.918-2.86a19.538,19.538,0,0,0,5.918.91,19.538,19.538,0,0,0,5.918-.91,6.226,6.226,0,0,0-4.078-4.121.975.975,0,0,1-.671-.771.975.975,0,0,1,.387-.947,2.622,2.622,0,0,0,1.09-2.136,2.65,2.65,0,0,0-2.646-2.646,2.649,2.649,0,0,0-2.646,2.646,2.621,2.621,0,0,0,1.09,2.136.974.974,0,0,1,.386.947.975.975,0,0,1-.671.771A6.225,6.225,0,0,0-5994.987-551.445Z" transform="translate(5997.108 564.016)"/>
  </svg>
);

export default ScanningTechnicianIcon;
