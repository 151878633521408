/* eslint-disable max-len */
import React from 'react';
import './error-icon.scss';

const ErrorIcon = () => (
  <svg className="error-icon" viewBox="0 0 14.686 14.686">
    <path d="M-5737.3-865.8a7.343,7.343,0,0,0-7.343,7.343,7.343,7.343,0,0,0,7.343,7.343,7.343,7.343,0,0,0,7.343-7.343A7.343,7.343,0,0,0-5737.3-865.8Zm-1.115,2.39h2.229v5.644h-2.229Zm1.115,10.012a1.388,1.388,0,0,1-1.388-1.388,1.388,1.388,0,0,1,1.388-1.388,1.388,1.388,0,0,1,1.388,1.388A1.388,1.388,0,0,1-5737.3-853.394Z" transform="translate(5744.641 865.796)"/>
  </svg>
);

export default ErrorIcon;
