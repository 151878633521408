import { parse } from 'json2csv';
import moment from 'moment';

const UNKNOWN = 'unkown';
const EMPTY = '<empty>';

const TECHNICIANS = {
  'Simon Collins': '3515',
  'Jonathan Chapman': '3513',
  'Ben Barton': '3514'
};

/* Abri (Breedplan) wanted a csv with two headers (they'd accept no headers
but that's only going to be useful for direct upload - this system supports
human editing). The header produced by json2csv is the second header row.
I've added comments so you can see what each column actually is */

export const getCSV = scanSession => {
  // console.log(JSON.stringify(scanSession, null, 2));
  let dateStr = '';
  if (scanSession.date) {
    const d = Date.parse(scanSession.date);
    dateStr = moment(d).format('DDMMYYYY');
  }
  let accreditationNo = '';
  if (scanSession.technician && TECHNICIANS[scanSession.technician]) {
    accreditationNo = TECHNICIANS[scanSession.technician];
  }
  const fields = [
    { label: '#Ident', value: UNKNOWN }, // herd ident
    { label: 'Kgs', default: 'K', value: UNKNOWN }, // lbs /kg
    { label: EMPTY, value: 'earTag' }, // Animal Ident
    { label: 'Code', value: UNKNOWN }, // Disposal Code
    { label: EMPTY, value: 'weighDay', default: dateStr }, // Weigh Date
    { label: EMPTY, value: 'weight' }, // weight
    { label: 'Grp', value: UNKNOWN }, // Mgmt Grp
    { label: 'Indicator', value: UNKNOWN }, // Analysis Indicator
    { label: 'Dry', value: UNKNOWN }, // Wet Dry
    { label: 'Cond', value: UNKNOWN }, // Cow Cond
    { label: 'Flag', value: UNKNOWN }, // --Castrate-- Flag
    { label: 'Date', value: UNKNOWN }, // --Castrate-- Date
    { label: 'Size', value: 'scrotalCircumference' }, // Scrotal Size
    { label: 'Ht', value: UNKNOWN }, // Hip ht
    { label: 'P8 Fat', value: 'rumpFatThickness' }, // Ultrasound Scanning P8 Fat
    { label: 'Rib Fat', value: 'ribFatThickness' }, // Ultrasound Scanning Rib Fat
    { label: 'Eye Muscle', value: 'ribEyeArea' }, // Ultrasound Scanning Eye Muscle
    { label: 'IMF%', value: 'imf' }, // Ultrasound Scanning IMF%
    { label: 'IMF-No', value: UNKNOWN }, // Ultrasound Scanning IMF-No
    { label: 'Number', value: 'accreditationNo' }, // Accreditation Number
    { label: 'Score', value: UNKNOWN }, // Docility Score
    { label: 'Grp', value: UNKNOWN }, // Docility Grp
    { label: 'Code', value: UNKNOWN }, // Extra Trait #1 Code
    { label: 'Value', value: UNKNOWN }, // Extra Trait #1 Value
    { label: 'Code', value: UNKNOWN }, // Extra Trait #2 Code
    { label: 'Value', value: UNKNOWN }, // Extra Trait #2 Value
    { label: 'Code', value: UNKNOWN }, // Extra Trait #3 Code
    { label: 'Value', value: UNKNOWN }, // Extra Trait #3 Value
    { label: 'Code', value: UNKNOWN }, // Extra Trait #4 Code
    { label: 'Value', value: UNKNOWN }, // Extra Trait #4 Value
    { label: 'Code', value: UNKNOWN }, // Extra Trait #5 Code
    { label: 'Value', value: UNKNOWN }, // Extra Trait #5 Value
    { label: 'Code', value: UNKNOWN }, // Extra Trait #6 Code
    { label: 'Value', value: UNKNOWN } // Extra Trait #6 Value
  ];
  const transforms = [
    item => {
      const scrotalCircumference = item.scrotalCircumference ?
        Math.round(item.scrotalCircumference * 10) / 10 : '';
      const rumpFatThickness = item.rumpFatThickness ?
        Math.round(item.rumpFatThickness) : '';
      const ribFatThickness = item.ribFatThickness ?
        Math.round(item.ribFatThickness) : '';
      const ribEyeArea = item.ribEyeArea ? Math.round(item.ribEyeArea) : '';
      const imf = item.imf ? Math.round(item.imf * 10) / 10 : '';
      return {
        ...item,
        scrotalCircumference,
        rumpFatThickness,
        ribFatThickness,
        ribEyeArea,
        imf,
        accreditationNo
      };
    }
  ];
  const opts = { fields, transforms };
  let csv = parse(scanSession.animals, opts);
  // eslint-disable-next-line max-len
  const header = `#Herd,Lbs /,Animal Ident,Disposal,Weigh Date,Weight,Mgmt,Analysis,Wet,Cow, --- Castrate ---,,Scrotal,Hip,Ultrasound Scanning,,,,,Accreditation,Docility,,Extra Trait # 1,,Extra Trait # 2,,Extra Trait # 3,,Extra Trait # 4,,Extra Trait # 5,,Extra Trait # 6,
`; // the header is taken from an example sent by Abri
  csv = header + csv;
  csv = csv.replace(/<empty>/g, '');
  csv = csv.replace(/"/g, ''); // remove quotes
  // console.log(csv);
  return csv;
};
