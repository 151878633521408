import React, { useEffect } from 'react';
import { isAdmin } from '../../../util/jwt';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPricings,
  fetchingPricings,
  getPricingUploadsObj
} from '../reducer';
import { fetchPricing } from '../actions';
import PricingListViewHeader from './PricingListViewHeader';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import ToolTip from '../../../util/tooltips/components/ToolTip';
import PricingListViewItem from './PricingListViewItem';
import UploadPriceCheck from './UploadPriceCheck';

const PricingListView = () => {
  const pricings = useSelector(getPricings);
  const pricingUploads = useSelector(getPricingUploadsObj);
  const fetching = useSelector(fetchingPricings);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPricing());
  }, []);
  if (!isAdmin()) {
    return (
      <h2 data-cy="restricted-header">Restricted</h2>
    );
  }
  const items = fetching ? (
    <LoadingIcon />
  ) : pricings.map(priceCheck => {
    const uploading = pricingUploads[priceCheck.id] === 'uploading';
    const errored = pricingUploads[priceCheck.id] &&
      pricingUploads[priceCheck.id] !== 'uploading';
    return (
      <PricingListViewItem
        key={priceCheck.id }
        errored={ errored }
        priceCheck={ priceCheck }
        uploading={ uploading } />
    );
  });
  return (
    <div id="pricing-admin">
      <h2 className="caption">
        Price Checks
        <ToolTip>
          To upload a new price check take the example file
          and edit it to include the latest data. Make sure
          that there are no missing values or the upload
          will fail (hopefully with some useful feedback,
          telling you what needs to be corrected).
        </ToolTip>
      </h2>
      <UploadPriceCheck />
      <PricingListViewHeader />
      { items }
    </div>
  );
};

export default PricingListView;
