import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AnimalScore from '../../components/AnimalScore';
import './farm-level-score-display.scss';
import { getScoreDefinitions } from '../../scoreDefinitions/reducer';
import ExpandableFormSection from '../../util/components/ExpandableFormSection';

const FarmLevelScores = ({ scores, update }) => {
  const scoreDefinitions = useSelector(getScoreDefinitions);
  scores = scores || {};
  const scoreElements = Object.keys(scoreDefinitions).map(key => ({
    key,
    name: scoreDefinitions[key].name,
    category: scoreDefinitions[key].category,
    farmLevel: scoreDefinitions[key].farmLevel || false,
    scores: scoreDefinitions[key].scoreCard.map(score => score.score)
  })).filter(factor => factor.farmLevel).map(factor => {
    const score = scores[factor.key] || '-';
    const setNewScore = score => {
      const newScores = JSON.parse(JSON.stringify(scores));
      newScores[factor.key] = score;
      update(newScores);
    };
    return <AnimalScore key={ factor.key }
      score={ score }
      update={ setNewScore }
      factor={ factor } />;
  });
  return (
    <ExpandableFormSection title="Farm level scores"
      id="farm-level-score-display">
      <div id="farm-level-score-display">
        <div className='bb-page-wrapper'>
          <div className='figures'>
            <div className="table">
              { scoreElements }
            </div>
          </div>
          <div className="border"></div>
        </div>
      </div>
    </ExpandableFormSection>
  );
};

FarmLevelScores.propTypes = {
  scores: PropTypes.object,
  update: PropTypes.func.isRequired
};

export default FarmLevelScores;
