
const retailers = {
  'Waitrose': {
    type: 'Supermarket', obfuscate: true
  },
  'Tesco (finest)': {
    type: 'Supermarket', obfuscate: true
  },
  'Sainsburys (TtD)': {
    type: 'Supermarket', obfuscate: true
  },
  'Warrens': {
    type: 'Butcher', obfuscate: true
  },
  'HG Walter': {
    type: 'Butcher', obfuscate: true
  },
  'Pipers Farm': {
    type: 'Boxed Beef', obfuscate: true
  },
  'Riverford': {
    type: 'Boxed Beef', obfuscate: true
  },
  'FarmDrop': {
    type: 'Boxed Beef', obfuscate: true
  },
  'Farmison & Co': {
    type: 'Boxed Beef', obfuscate: true
  },
  'National Retail (AHDB)': {
    type: '', obfuscate: false
  },
  'Total Average': {
    type: '', obfuscate: false
  },
  'Retail Average': {
    type: '', obfuscate: false
  },
  'Boxed Beef Average': {
    type: '', obfuscate: false
  },
  'Warson': {
    type: '', obfuscate: false
  }
};

const obfuscateRetailer = (retailer, label) => {
  label = typeof label === 'undefined' ? '' : label;
  if (retailers[retailer]) {
    if (retailers[retailer].obfuscate) {
      return retailers[retailer].type + ' ' + label;
    } else {
      return retailer;
    }
  }
  return 'Unspecified Retailer ' + label;
};

export default obfuscateRetailer;
