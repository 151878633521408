import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  getHelpVidCount,
  getHelpVidPage,
  getHelpVidPageSize,
  getPageOfHelpVids
} from '../reducer';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AdminSearchBox from '../../adminListSearch/components/AdminSearchBox';
import HelpVidListViewHeader from './HelpVideoListHeader';
import Pagination from '../../../util/tables/Pagination';
import { fetchHelpVids, setHelpVideoView, setHelpVidPage } from '../actions';
import './help-vid-list-view.scss';
import SwitchViewsButton from './SwitchViewsButton';

const filterAttributes = [
  { name: 'Title', attribute: 'title', dataType: 'string' }
];

const HelpVideoListView = ({ match }) => {
  const videos = useSelector(getPageOfHelpVids);
  const pageSize = useSelector(getHelpVidPageSize);
  const page = useSelector(getHelpVidPage);
  const helpVidCount = useSelector(getHelpVidCount);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHelpVids());
    dispatch(setHelpVideoView(true));
  }, []);
  const items = videos.map(vid => {
    let tags = vid.tags ? vid.tags.map(tag => (
      <div key={ tag } className="tag">{ tag }</div>
    )) : [];
    return (
      <div key={ vid.id } data-cy="video-list-item" className="help-video-item">
        <div className="block name-block">
          <h3 className="name"
            data-cy={`video-title-${vid.id}`}>
            { vid.title }
          </h3>
        </div>
        <div className="tags block">
          { tags }
        </div>
        <div className="block edit-button-block"
          data-cy={ 'edit-block-' + vid.id }>
          <Link to={ `${match.url}/${vid.id}`}
            className="edit-link">
            <span data-cy="edit-button">Edit</span>
          </Link>
        </div>
      </div>
    );
  });
  return (
    <div id="help-vid-admin-list">
      <h2 data-cy="help-videos-title">Help Videos</h2>
      <SwitchViewsButton />
      <Link className="new-vid-button" to={ `${match.url}/new`}>
        <span data-cy="add-new-button" className="text">Add new</span>
        <span className="plus-icon">+</span>
      </Link>
      <AdminSearchBox attributes={ filterAttributes } />
      <HelpVidListViewHeader />
      { items }
      <Pagination
        toPage={ page => dispatch(setHelpVidPage(page)) }
        total={ Math.ceil(helpVidCount / pageSize)}
        current={ page } />
    </div>
  );
};

HelpVideoListView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default HelpVideoListView;
