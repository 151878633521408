import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDownSelect from '../util/form/components/DropDownSelect';
import ToolTip from '../util/tooltips/components/ToolTip';

class DropDownSelectField extends Component {
  getError () {
    return this.props.error ?
      <div className="field-error-text" data-cy="field-error-text">
        { this.props.error }
      </div> : null;
  }
  renderToolTip () {
    if (this.props.children) {
      return <ToolTip>
        { this.props.children }
      </ToolTip>;
    }
    return null;
  }
  render () {
    let labelClass = 'form-label';
    if (this.getError()) labelClass += ' error';
    let className = 'form-field-wrapper';
    labelClass += this.getError() ? ' error' : '';
    if (this.props.children) labelClass += ' tooltip';
    return (
      <div className={ className }>
        <label className={ labelClass }>
          <span className="name">{ this.props.name }</span>
          { this.renderToolTip() }
        </label>
        <DropDownSelect
          testId={ this.props.testId }
          onChange={ this.props.update }
          className={ this.props.className }
          placeholder="select"
          options={ this.props.options } />
        { this.getError() }
      </div>
    );
  }
}

DropDownSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      selected: PropTypes.bool
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  error: PropTypes.string,
  update: PropTypes.func,
  testId: PropTypes.string
};

export default DropDownSelectField;
