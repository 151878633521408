import React, { useState } from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import getDataURIFromImageSrc from '../../labels/util/getDataURIFromImageSrc';
import SimplePackaginglabelPDF from
  '../../labels/components/SimplePackagingLabelsPDF';
import { calculateScore, getGradeFromScore } from '../../scoreDefinitions/util';
import { useSelector } from 'react-redux';
import { getScoreDefinitions } from '../../scoreDefinitions/reducer';
import { captureException } from '../../../util/logger';
import {
  background as backgroundImage,
  classicShield,
  labelLogo,
  primeShield,
  standardShield,
  superiorShield,
  supremeShield
} from '../../labels/util/imageURLs';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';

const SimplePackingLabelsButton = ({ animal, farm }) => {
  if (!animal || !animal.tag || !animal.saved) return null;
  const [labelLogoUri, setLabelLogoUri] = useState(null);
  const [ shieldUri, setShieldDataUri ] = useState(null);
  const [background, setBackgroundUri] = useState(null);
  const [ labelsRequested, setLabelsRequested ] = useState(false);
  const scoreDefs = useSelector(getScoreDefinitions);
  if (!animal || !farm) return null;
  const tag = animal.tag || '';
  const name = animal.name || '';
  if (!tag || !name) return null;
  const buildLabels = () => {
    setLabelsRequested(true);
    const score = calculateScore(animal, scoreDefs);
    const grade = getGradeFromScore(score);
    getDataURIFromImageSrc(labelLogo, setLabelLogoUri, 'png');
    getDataURIFromImageSrc(backgroundImage, setBackgroundUri, 'jpg');
    switch (grade) {
      case 'Standard':
        getDataURIFromImageSrc(standardShield, setShieldDataUri, 'png');
        break;
      case 'Superior':
        getDataURIFromImageSrc(superiorShield, setShieldDataUri, 'png');
        break;
      case 'Classic':
        getDataURIFromImageSrc(classicShield, setShieldDataUri, 'png');
        break;
      case 'Prime':
        getDataURIFromImageSrc(primeShield, setShieldDataUri, 'png');
        break;
      case 'Supreme':
        getDataURIFromImageSrc(supremeShield, setShieldDataUri, 'png');
        break;
    }
  };
  let button = null;
  let fileName = `simple_packaging_labels_${tag.replaceAll(' ', '_')}.pdf`;
  const beef = animal.beefProcessing;
  if (beef && beef.slaughterHouseID && beef.cuttingPlantID && tag) {
    if (!labelsRequested) {
      button = (
        <div className="button small-labels-button"
          onClick={ buildLabels }>
          <span className="label">Build Packaging Labels</span>
          <ChevronIcon />
        </div>
      );
    } else if (labelLogoUri && shieldUri && background) {
      const simpleLabelProps = {
        ...beef,
        labelLogoUri,
        farm,
        tag,
        name,
        shieldUri,
        background
      };
      button = (
        <BlobProvider
          document={ <SimplePackaginglabelPDF { ...simpleLabelProps } /> }>
          {({ blob, url, loading, error }) => {
            if (loading) {
              return (
                <div className="button disabled">
                  <span>Building Packaging Labels</span>
                  <LoadingIcon />
                </div>
              );
            }
            if (error) {
              captureException(error);
              return null;
            }
            return (
              <a href={ url }
                className="download-package-labels-button button"
                data-cy="packaging-labels"
                download={fileName }>
                Download Packaging labels
                <DownloadIcon />
              </a>
            );
          }}
        </BlobProvider>
      );
    } else {
      button = (
        <div className="button disabled">
          <span>Building Packaging Labels</span>
          <LoadingIcon />
        </div>
      );
    }
  }
  return (
    <div className="simple-packing-labels-button">
      { button }
    </div>
  );
};

SimplePackingLabelsButton.propTypes = {
  animal: PropTypes.object,
  farm: PropTypes.string
};

export default SimplePackingLabelsButton;
