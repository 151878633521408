import React from 'react';
import PropTypes from 'prop-types';
import './price-check-display.scss';
import buildBreakdownMap from '../util.js/buildBreakdownMap';
import { timeFromNow } from '../../../util/datesAndAges';
import { BASE_FARM_GATE_PRICE, CARCASS_WEIGHT } from '../util.js/variables';
import obfuscateRetailer from '../util.js/obfuscateRetailer';

const PricingDisplay = ({ pricing, obfuscate }) => {
  let farmGatePrice;
  if (pricing.farmGatePrice) {
    farmGatePrice = pricing.farmGatePrice / 100;
  } else {
    farmGatePrice = BASE_FARM_GATE_PRICE;
  }
  const breakdownMap = buildBreakdownMap(pricing.breakdown);
  let cells = [
    <div className="cell title" key="top-left">
      Cut of Meat
    </div>
  ];
  pricing.retailers.forEach((retailer, index) => {
    const name = obfuscate ?
      obfuscateRetailer(retailer.name, index + 1) : retailer.name;
    cells.push(
      <div className="cell header"
        data-cy={ retailer.name }
        key={ retailer.name }>
        { name }
      </div>
    );
  });
  cells.push(
    <div className="cell header eblex" key="eblex-header">
      EBLEX breakdown
    </div>
  );
  pricing.retailers[0].cuts.forEach((cut, index) => {
    cells.push(
      <div className="cell title" key={ cut.name } data-cy={ cut.name }>
        { cut.name }
      </div>
    );
    pricing.retailers.forEach(retailer => {
      let price = retailer.cuts[index].price / 100;
      price = price.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
      const key = `${retailer.name}-${cut.name}`;
      cells.push(
        <div className="cell" key={ key } data-cy={ key }>
          { price }
        </div>
      );
    });
    const eblex = Math.round(breakdownMap[cut.name]) + ' kg';
    cells.push(
      <div className="cell eblex" key={ `eblex-${cut.name}`}>
        { eblex }
      </div>
    );
  });
  const columns = pricing.retailers.length + 2;
  const mainTableStyles = {
    gridTemplateColumns: `repeat(${columns}, minmax(min-content, 1fr))`
  };
  const spacerStyles = {
    gridColumn: `1/${columns + 1}`
  };

  cells.push(
    <div className="cell spacer" key="spacer1" style={ spacerStyles }></div>
  );
  cells.push(
    <div className="cell title" key="carcass-value">
      Carcass Value
    </div>
  );
  pricing.retailers.forEach(retailer => {
    let carcassValue = 0;
    retailer.cuts.forEach(cut => {
      carcassValue += cut.price * breakdownMap[cut.name];
    });
    carcassValue = Math.round(carcassValue / 100).toLocaleString();
    const key = `carcass-value-${retailer.name}`;
    cells.push(
      <div className="cell" key={ key } data-cy={ key }>
        £{ carcassValue }
      </div>
    );
  });
  cells.push(<div className="cell clear" key="clear-cell-1"></div>);

  let butcheredTotal = pricing.breakdown.reduce((accumulator, item) => {
    return accumulator + item.weight;
  }, 0);
  cells.push(
    <div className="cell spacer" key="spacer2" style={ spacerStyles }></div>
  );
  cells.push(
    <div className="cell sub-title" key="butchered-breakdown">
      Retail Price per Butchered Kg
    </div>
  );
  pricing.retailers.forEach(retailer => {
    let carcassValue = 0;
    retailer.cuts.forEach(cut => {
      carcassValue += cut.price * breakdownMap[cut.name] / 100;
    });
    let perKg = carcassValue / butcheredTotal;
    perKg = perKg.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
    const key = `per-kg-${retailer.name}`;
    cells.push(
      <div className="cell" key={ key } data-cy={ key }>
        £{ perKg }
      </div>
    );
  });
  cells.push(<div className="cell clear" key="clear-cell-2"></div>);
  cells.push(
    <div className="cell sub-title" key="carcass-breakdown">
      Retail Price per Carcass Kg
    </div>
  );
  const shareCells = [];
  pricing.retailers.forEach(retailer => {
    let carcassValue = 0;
    retailer.cuts.forEach(cut => {
      carcassValue += cut.price * breakdownMap[cut.name] / 100;
    });
    const perKg = carcassValue / CARCASS_WEIGHT;
    const perKgStr = '£' + perKg.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
    const key = `carcass-per-kg-${retailer.name}`;
    cells.push(
      <div className="cell" key={ key } data-cy={ key }>
        { perKgStr }
      </div>
    );
    console.log(`{ name: '${key}', content: '${perKgStr}' }`);
    const shareKey = `farmer-share-${retailer.name}`;
    const share = Math.round(farmGatePrice / perKg * 100) + '%';
    shareCells.push(
      <div className="cell share" key={ shareKey } data-cy={ shareKey }>
        { share }
      </div>
    );
    console.log(`{ name: '${shareKey}', content: '${share}' }`);
  });
  cells.push(<div className="cell clear" key="clear-cell-3"></div>);

  cells.push(
    <div className="cell spacer" key="spacer1" style={ spacerStyles }></div>
  );
  cells.push(
    <div className="cell sub-title" key="farmer-share">
      Farmer Share of Retail Value
    </div>
  );
  cells = cells.concat(shareCells);
  cells.push(<div className="cell clear" key="clear-cell-4"></div>);

  const farmGate = '£' + farmGatePrice.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
  return (
    <div className="price-check-display">
      <h1 className="check-title" data-cy="price-check-title">Price Check</h1>
      <h3 className="check-date" data-cy="price-check-age">
        { timeFromNow(pricing.date) }
      </h3>
      <p className="introduction">
        Working with a carcass weight of { CARCASS_WEIGHT }kg, and
        an example farm gate price of { farmGate }.
      </p>
      <div className="table-wrapper">
        <div className="main-table" style={ mainTableStyles }>
          { cells }
        </div>
      </div>
    </div>
  );
};

PricingDisplay.propTypes = {
  pricing: PropTypes.object,
  obfuscate: PropTypes.bool
};

export default PricingDisplay;
