import { StyleSheet, Font } from '@react-pdf/renderer';
import font from '../../../../font/lumiosMarker/font.ttf';

// eslint-disable-next-line object-property-newline
Font.register({ family: 'LumiosMarker', fonts: [
  { src: font, format: 'truetype' }
] });

const brandPink = '#c92f6d';

/*
  Based on Bespoke Label Request Number: 575
  From https://secure.uk.onlinelabels.com

   - https://secure.uk.onlinelabels.com/General/CustomSizesStep4.aspx?Type=1&ShapeID=90&Width=94&Height=88.3&CornerRadius=3.2&HLabels=2&VLabels=3&HSpacing=6&VSpacing=6&HMargin=8&VMargin=10.1&MaterialID=1

  label height 88.3mm
  label width 94mm
  page border top/bottom 10.1mm
  page border left/right 8mm
  horizontal / vertical label gap 6mm
*/

const SimplePackaginglabelStyles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    paddingTop: '10mm',
    paddingLeft: '8mm',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  label: {
    marginRight: '6mm',
    marginBottom: '6mm',
    width: '94mm',
    height: '88.3mm',
    position: 'relative'
  },
  background: {
    position: 'absolute',
    right: '-3mm',
    left: '-3mm',
    bottom: '-3mm',
    top: '-3mm'
  },
  logo: {
    position: 'absolute',
    width: '40mm',
    height: '17mm',
    top: '7mm',
    left: '27mm'
  },
  farmContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    top: '32mm'
  },
  farm: {
    fontFamily: 'LumiosMarker',
    fontSize: '6.5mm'
  },
  shield: {
    position: 'absolute',
    height: '21.4mm', // 150 on png
    width: '24mm', // 168 on png
    left: '35mm',
    bottom: '26.5mm'
  },
  data: {
    position: 'absolute',
    bottom: '9mm',
    left: '8mm',
    width: '50mm',
    height: '16mm'
  },
  dataRow: {
    width: '100%'
  },
  dataRowLabel: {
    fontWeight: 400,
    fontSize: '2mm',
    color: '#555'
  },
  spacer: {
    color: '#dcdcdc'
  },
  dataRowData: {
    fontWeight: 500,
    fontSize: '2.8mm'
  },
  name: {
    color: brandPink,
    fontWeight: 700,
    fontSize: '3.2mm',
    marginBottom: '1mm'
  },
  traceTitle: {
    color: brandPink,
    fontWeight: 600,
    fontSize: '2.8mm',
    position: 'absolute',
    bottom: '29mm',
    right: '27mm'
  },
  qrSection: {
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  qrCode: {
    position: 'absolute',
    height: '18mm',
    width: '18mm',
    bottom: '7mm',
    right: '8.2mm'
  }
});

export default SimplePackaginglabelStyles;
