import React, { useRef, useState, useEffect } from 'react';
import '../../components/admin-list-view-header.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOutletSortAttribute,
  getOutletCount
} from '../reducer';
import { setAdminOutletSort } from '../actions';
import DropDownIcon from '../../../util/icons/components/DropDownIcon';

const sortOptions = {
  name: 'Name',
  id: 'Creation date',
  category: 'Sales Category'
};

const OutletListViewHeader = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const handleSortOptionClicked = sortOption => {
    dispatch(setAdminOutletSort(sortOption));
  };
  const [ showingDropdown, setShowingDropdown ] = useState(false);
  const toggleVisibility = () => setShowingDropdown(!showingDropdown);
  const dropDownOptions = Object.keys(sortOptions).map(key => (
    <li key={ key }
      data-cy={ `outlet-sort-option-${key}` }
      onClick={() => handleSortOptionClicked(key)}>
      { sortOptions[key] }
    </li>
  ));
  const currentSort = useSelector(getOutletSortAttribute);
  const outletCount = useSelector(getOutletCount);
  const optionsClass = showingDropdown ?
    'dropdown-options' : 'dropdown-options hidden';
  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setShowingDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const arrowClass = showingDropdown ? 'open' : null;
  return (
    <div id='admin-search-header'>
      <div className='count'>
        <span className='number'>
          { outletCount.toLocaleString() }
        </span>
        Outlets
      </div>
      <div ref={wrapperRef}
        className='sort'
        data-cy="outlet-sort-dropdown"
        onClick={ toggleVisibility }>
        { 'Sort by' }
        <p className='attribute'>{ sortOptions[currentSort] }</p>
        <ul className={ optionsClass }>
          { dropDownOptions }
        </ul>
        <DropDownIcon className={ arrowClass } />
      </div>
      <div className="triangle-wrapper">
        <div className='triangle'></div>
      </div>
    </div>
  );
};

export default OutletListViewHeader;
