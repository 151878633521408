import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  Text,
  View,
  Document,
  Image
} from '@react-pdf/renderer';
import styles from './SimplePackagingLabelStyles';

const SimplePackaginglabelPDF = ({
  slaughterHouseID,
  cuttingPlantID,
  labelLogoUri,
  shieldUri,
  tag,
  name,
  farm,
  background
}) => {
  const qrCodeWrapper = document.getElementById('animal-qr-code-wrapper');
  const qrCodeCanvas = qrCodeWrapper.querySelector('canvas');
  const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
  const nameTitle = name ? (
    <Text style={ styles.name }>
      { name }
    </Text>
  ) : null;
  const labels = [ ...Array(6) ].map((_, i) => (
    <View key={ i } style={ styles.label }>
      <Image style={ styles.background } source={ { uri: background } } />
      <Image style={ styles.shield } source={ { uri: shieldUri } } />
      <View style={ styles.farmContainer } >
        <Text style={ styles.farm }>{ farm }</Text>
      </View>
      <Image style={ styles.logo } source={ { uri: labelLogoUri } } />
      <View style={ styles.data } >
        { nameTitle }
        <Text style={ styles.dataRow }>
          <Text style={ styles.dataRowLabel }>
            TRACKING ID
          </Text>
          <Text style={ styles.spacer }>_</Text>
          <Text style={ styles.dataRowData }>
            { tag }
          </Text>
        </Text>
        <Text style={ styles.dataRow }>
          <Text style={ styles.dataRowLabel }>
            ORIGIN
          </Text>
          <Text style={ styles.spacer }>_</Text>
          <Text style={ styles.dataRowData }>
            UK
          </Text>
        </Text>
        <Text style={ styles.dataRow }>
          <Text style={ styles.dataRowLabel }>
            SLAUGHTERED IN
          </Text>
          <Text style={ styles.spacer }>_</Text>
          <Text style={ styles.dataRowData }>
            { `UK (${slaughterHouseID})` }
          </Text>
        </Text>
        <Text style={ styles.dataRow }>
          <Text style={ styles.dataRowLabel }>
            CUT IN
          </Text>
          <Text style={ styles.spacer }>_</Text>
          <Text style={ styles.dataRowData }>
            { `UK (${cuttingPlantID})` }
          </Text>
        </Text>
      </View>
      <View style={ styles.qrSection }>
        <Text style={ styles.traceTitle }>Trace your beef</Text>
        <Image style={ styles.qrCode } source={ { uri: qrCodeDataUri } } />
      </View>
    </View>
  ));
  return (
    <Document>
      <Page size='A4' style={ styles.page }>
        { labels }
      </Page>
    </Document>
  );
};

SimplePackaginglabelPDF.propTypes = {
  slaughterHouseID: PropTypes.string.isRequired,
  cuttingPlantID: PropTypes.string.isRequired,
  labelLogoUri: PropTypes.string.isRequired,
  shieldUri: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  tag: PropTypes.string,
  name: PropTypes.string,
  farm: PropTypes.string
};

export default SimplePackaginglabelPDF;
