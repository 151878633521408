import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandableFormSection from '../util/components/ExpandableFormSection';
import { useDispatch, useSelector } from 'react-redux';
import { getAnimalEditErrors, getAnimalUnderEdit } from '../reducer';
import TickBoxField from '../../form/TickBoxField';
import { getObjectAttribute, updateObjectAttribute } from '../util';
import { updateAnimalUnderEdit } from '../actions';
import TextField from '../../form/TextField';
import './calving-fields.scss';
import DateField from '../../form/DateField';
import DropDownSelectField from '../../form/DropDownSelectField';
import ToggleSwitchField from '../../form/ToggleSwitchField';

const CalvingFields = ({ showErrors }) => {
  const animal = useSelector(getAnimalUnderEdit);
  let calving = {};
  if (animal && animal.calving) {
    calving = animal.calving;
  }
  const dispatch = useDispatch();
  const errors = useSelector(getAnimalEditErrors);
  const [inCalfIsAI, setInCalfIsAI] =
    useState(calving.inCalf && calving.inCalf.aiSire);
  const [atFootIsAI, setAtFootIsAI] =
    useState(calving.calfAtFoot && calving.calfAtFoot.aiSire);
  const update = (param, value) => {
    const newAnimal = JSON.parse(JSON.stringify(animal));
    newAnimal.calving = updateObjectAttribute(calving, param, value);
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const getAttribute = key => getObjectAttribute(calving, key);
  const getError = key => {
    if (showErrors && errors && errors[key]) return errors[key];
    return null;
  };
  if (!animal) return null;
  if (!animal.sex === 'female' ||
    !['BreedingCows', 'Heifers'].includes(animal.type)) {
    return null;
  }
  let isErrored = false;
  const toggleInCalf = () => {
    const newAnimal = JSON.parse(JSON.stringify(animal));
    const newCalving = JSON.parse(JSON.stringify(calving));
    if (calving.inCalf) {
      delete newCalving.inCalf;
    } else {
      newCalving.inCalf = {};
    }
    newAnimal.calving = newCalving;
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const inCalfTick = (
    <TickBoxField
      title="In Calf"
      testId="in-calf-tick-box"
      isOn={ !!getAttribute('inCalf') }
      error={ getError('calvingInCalf') }
      toggle={ toggleInCalf } />
  );
  const toggleRunningWithBull = () => {
    const newAnimal = JSON.parse(JSON.stringify(animal));
    const newCalving = JSON.parse(JSON.stringify(calving));
    if (calving.runningWithBull) {
      delete newCalving.runningWithBull;
    } else {
      newCalving.runningWithBull = {};
    }
    newAnimal.calving = newCalving;
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const runningWithBullTick = (
    <TickBoxField
      title="Running with Bull"
      testId="running-with-bull-tick-box"
      isOn={ !!getAttribute('runningWithBull') }
      error={ getError('calvingRunningWithBull') }
      toggle={ toggleRunningWithBull } />
  );
  const toggleCalfAtFoot = () => {
    const newAnimal = JSON.parse(JSON.stringify(animal));
    const newCalving = JSON.parse(JSON.stringify(calving));
    if (calving.calfAtFoot) {
      delete newCalving.calfAtFoot;
    } else {
      newCalving.calfAtFoot = {};
    }
    newAnimal.calving = newCalving;
    dispatch(updateAnimalUnderEdit(newAnimal));
  };
  const toggleInCalfAI = isAI => {
    const newAnimal = JSON.parse(JSON.stringify(animal));
    const newCalving = JSON.parse(JSON.stringify(calving));
    if (isAI) {
      if (calving.inCalf) {
        delete newCalving.inCalf.naturalSire;
      }
    } else {
      if (calving.inCalf) {
        delete newCalving.inCalf.aiSire;
        delete newCalving.inCalf.sweeperBull;
      }
    }
    newAnimal.calving = newCalving;
    dispatch(updateAnimalUnderEdit(newAnimal));
    setInCalfIsAI(isAI);
  };
  const toggleAtFootAI = isAI => {
    const newAnimal = JSON.parse(JSON.stringify(animal));
    const newCalving = JSON.parse(JSON.stringify(calving));
    if (isAI) {
      if (calving.calfAtFoot) {
        delete newCalving.calfAtFoot.naturalSire;
      }
    } else {
      if (calving.calfAtFoot) {
        delete newCalving.calfAtFoot.aiSire;
        delete newCalving.calfAtFoot.sweeperBull;
      }
    }
    newAnimal.calving = newCalving;
    dispatch(updateAnimalUnderEdit(newAnimal));
    setAtFootIsAI(isAI);
  };
  let dateStr = getAttribute('inCalf.predictedBirth');
  const predictedBirthDate = dateStr ? new Date(dateStr) : undefined;
  dateStr = getAttribute('calfAtFoot.birthDate');
  const birthDate = dateStr ? new Date(dateStr) : undefined;
  dateStr = getAttribute('inCalf.scanDate');
  const scanDate = dateStr ? new Date(dateStr) : undefined;
  dateStr = getAttribute('runningWithBull.bullIn');
  const bullIn = dateStr ? new Date(dateStr) : undefined;
  dateStr = getAttribute('runningWithBull.bullOut');
  const bullOut = dateStr ? new Date(dateStr) : undefined;
  const animalCategoryOptions = ['bull', 'steer', 'heifer'].map(cat => ({
    value: cat,
    name: cat[0].toUpperCase() + cat.slice(1),
    selected: getAttribute('calfAtFoot.animalCategory') === cat
  }));
  const calfAtFootTick = (
    <TickBoxField
      title="Calf at Foot"
      testId="calf-at-foot-tick-box"
      isOn={ !!getAttribute('calfAtFoot') }
      error={ getError('calvingCalfAtFoot') }
      toggle={ toggleCalfAtFoot } />
  );
  const runningWithBull = calving.runningWithBull ? (
    <div className="calf-details" data-cy="running-with-bull-data">
      <h5 className="calf-title">Running with bull</h5>
      <TextField name="Bull Name"
        testId="running-with-bull-name"
        value={ getAttribute('runningWithBull.name') }
        error={ getError('calvingRunningWithBullName') }
        update={ value => update('runningWithBull.name', value) } />
      <TextField name="Bull Eartag"
        testId="running-with-bull-tag"
        value={ getAttribute('runningWithBull.tag') }
        error={ getError('calvingRunningWithBullTag') }
        update={ value => update('runningWithBull.tag', value) } />
      <DateField name="Bull in"
        value={ bullIn }
        testId="bull-out"
        error={ getError('calvingRunningWithBullBullIn') }
        update={ value => update('runningWithBull.bullIn', value) } />
      <DateField name="Bull out"
        value={ bullOut }
        testId="bull-out"
        error={ getError('calvingRunningWithBullBullOut') }
        update={ value => update('runningWithBull.bullOut', value) } />
    </div>
  ) : null;
  const inCalfSireFields = inCalfIsAI ? (
    <>
      <TextField name="AI Sire Name"
        testId="in-calf-ai-name"
        value={ getAttribute('inCalf.aiSire.name') }
        error={ getError('calvingInCalfAISireName') }
        update={ value => update('inCalf.aiSire.name', value) } />
      <TextField name="AI Sire Eartag"
        testId="in-calf-ai-tag"
        value={ getAttribute('inCalf.aiSire.tag') }
        error={ getError('calvingInCalfAISireTag') }
        update={ value => update('inCalf.aiSire.tag', value) } />
      <TextField name="Sweeper Bull Name"
        testId="in-calf-sweeper-name"
        value={ getAttribute('inCalf.sweeperBull.name') }
        error={ getError('calvingInCalfSweeperBullName') }
        update={ value => update('inCalf.sweeperBull.name', value) } />
      <TextField name="Sweeper Bull Eartag"
        testId="in-calf-sweeper-tag"
        value={ getAttribute('inCalf.sweeperBull.tag') }
        error={ getError('calvingInCalfSweeperBullTag') }
        update={ value => update('inCalf.sweeperBull.tag', value) } />
    </>
  ) : (
    <>
      <TextField name="Natural Sire - Bull Name"
        testId="in-calf-natural-name"
        value={ getAttribute('inCalf.naturalSire.name') }
        error={ getError('calvingInCalfNaturalSireName') }
        update={ value => update('inCalf.naturalSire.name', value) } />
      <TextField name="Natural Sire Eartag"
        testId="in-calf-natural-tag"
        value={ getAttribute('inCalf.naturalSire.tag') }
        error={ getError('calvingInCalfNaturalSireTag') }
        update={ value => update('inCalf.naturalSire.tag', value) } />
    </>
  );
  const inCalf = calving.inCalf ? (
    <div className="calf-details" data-cy="in-calf-data">
      <h5 className="calf-title">In Calf</h5>
      <ToggleSwitchField isOn={ inCalfIsAI || false }
        setIsOn= { toggleInCalfAI }
        testId="in-calf-ai-toggle"
        label="Artificial Insemination" >
        Click the switch to mark this calf as having been sired through
        artificial insemination.
      </ToggleSwitchField>
      { inCalfSireFields }
      <DateField name="Predicted Birth Date"
        value={ predictedBirthDate }
        testId="predicted-birth-date"
        error={ getError('calvingInCalfPredictedBirth') }
        update={ value => update('inCalf.predictedBirth', value) } />
      <DateField name="Scan Date"
        value={ scanDate }
        testId="scan-date"
        error={ getError('calvingInCalfScanDate') }
        update={ value => update('inCalf.scanDate', value) } />
    </div>
  ) : null;
  const atFootSireFields = atFootIsAI ? (
    <>
      <TextField name="AI Sire Name"
        testId="calf-at-foot-ai-name"
        value={ getAttribute('calfAtFoot.aiSire.name') }
        error={ getError('calvingCalfAtFootAISireName') }
        update={ value => update('calfAtFoot.aiSire.name', value) } />
      <TextField name="AI Sire Eartag"
        testId="calf-at-foot-ai-tag"
        value={ getAttribute('calfAtFoot.aiSire.tag') }
        error={ getError('calvingCalfAtFootAISireTag') }
        update={ value => update('calfAtFoot.aiSire.tag', value) } />
      <TextField name="Sweeper Bull Name"
        testId="calf-at-foot-sweeper-name"
        value={ getAttribute('calfAtFoot.sweeperBull.name') }
        error={ getError('calvingCalfAtFootSweeperBullName') }
        update={ value => update('calfAtFoot.sweeperBull.name', value) } />
      <TextField name="Sweeper Bull Eartag"
        testId="calf-at-foot-sweeper-tag"
        value={ getAttribute('calfAtFoot.sweeperBull.tag') }
        error={ getError('calvingCalfAtFootSweeperBullTag') }
        update={ value => update('calfAtFoot.sweeperBull.tag', value) } />
    </>
  ) : (
    <>
      <TextField name="Natural Sire - Bull Name"
        testId="calf-at-foot-natural-name"
        value={ getAttribute('calfAtFoot.naturalSire.name') }
        error={ getError('calvingCalfAtFootNaturalSireName') }
        update={ value => update('calfAtFoot.naturalSire.name', value) } />
      <TextField name="Natural Sire Eartag"
        testId="calf-at-foot-natural-tag"
        value={ getAttribute('calfAtFoot.naturalSire.tag') }
        error={ getError('calvingCalfAtFootNaturalSireTag') }
        update={ value => update('calfAtFoot.naturalSire.tag', value) } />
    </>
  );
  const calfAtFoot = calving.calfAtFoot ? (
    <div className="calf-details" data-cy="calf-at-foot-data">
      <h5 className="calf-title">Calf at Foot</h5>
      <ToggleSwitchField isOn={ atFootIsAI || false }
        setIsOn= { toggleAtFootAI }
        testId="at-foot-ai-toggle"
        label="Artificial Insemination" >
        Click the switch to mark this calf as having been sired through
        artificial insemination.
      </ToggleSwitchField>
      { atFootSireFields }
      <DateField name="Birth Date"
        value={ birthDate }
        testId="birth-date"
        error={ getError('calvingCalfAtFootBirthDate') }
        update={ value => update('calfAtFoot.birthDate', value) } />
      <DropDownSelectField
        name="Calf Sex"
        className="calf-sex-dropdown"
        options={ animalCategoryOptions }
        error={ getError('calvingCAlfAtFootAnimalCategory')}
        update={ val => update('calfAtFoot.animalCategory', val)}
        testId="animal-category-dropdown" />
    </div>
  ) : null;
  return (
    <ExpandableFormSection isErrored={ isErrored }
      id="calving-fields-expandable"
      title="Calving">
      <div className="calving-fields">
        <div className="animal-form">
          { runningWithBullTick }
          { inCalfTick }
          { calfAtFootTick }
        </div>
        { runningWithBull }
        { inCalf }
        { calfAtFoot }
      </div>
    </ExpandableFormSection>
  );
};

CalvingFields.propTypes = {
  showErrors: PropTypes.bool
};

export default CalvingFields;
