/* global Image, MouseEvent, FontFace, XMLSerializer */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLabels } from '../reducer';
import {
  largeBackground as backgroundImage,
  classicShield,
  labelLogo,
  primeShield,
  standardShield,
  superiorShield,
  supremeShield
} from '../../labels/util/imageURLs';
import { calculateScore, getGradeFromScore } from '../../scoreDefinitions/util';
import { getScoreDefinitions } from '../../scoreDefinitions/reducer';
import PropTypes from 'prop-types';
import './complex-labels-button.scss';
import { finishPrintingLabels, printLabels } from '../actions';

/*
  For full label dimensions see the labels.forEach loop
  in the handleClick function in the component
*/

const labelWidthWithBleed = 1241;
const labelHeightWithBleed = 1712;
const bleed = 35;
const lumiosMarkerURL =
  'https://media.goodbeefindex.org/fonts/LumiosMarker/lumiosmarker.woff2';
const dataLabelFont = '300 26px Work Sans';
const dataFont = '500 28px Work Sans';
const dateFont = '600 32px Work Sans';
const cutFont = '120px HudsonNY';
const weightFont = '500 60px Work Sans';
const brandPink = '#c92f6d';
const brandBlack = '#3A3a3A';
const textGrey = '#555';
const boxWidth = 366;
const boxHeight = 121;

const downloadCanvas = (canvas, fileName) => {
  const link = document.createElement('a');
  link.href = canvas.toDataURL('image/png');
  link.download = fileName;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
  document.body.removeChild(link);
};

const addRow = (top, left, gap, label, data, ctx) => {
  ctx.font = dataLabelFont;
  ctx.fillStyle = brandBlack;
  let metrics = ctx.measureText(label);
  let height =
    metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
  ctx.FillStyle = brandBlack;
  ctx.fillText(label, left, top + height);
  ctx.font = dataFont;
  ctx.fillText(data, left + gap + metrics.width, top + height);
};

const addAnimalDataToLabel = (
  name, id, born, reared, cutting, slaughter, ctx
) => {
  const lineHeight = 39;
  const gap = 16;
  // Name
  ctx.font = '500 38px Work Sans';
  let metrics = ctx.measureText(name);
  const left = 92;
  let top = 1154 + bleed;
  let height =
    metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
  ctx.fillStyle = brandPink;
  ctx.fillText(name, left, top + height);

  top = 1210 + bleed;
  addRow(top, left, gap, 'TRACKING ID', id, ctx);
  top += lineHeight;
  if (born === reared) {
    addRow(top, left, gap, 'ORIGIN', born, ctx);
  } else {
    addRow(top, left, gap, 'BORN', born, ctx);
    top += lineHeight;
    addRow(top, left, gap, 'REARED', reared, ctx);
  }
  top += lineHeight;
  addRow(top, left, gap, 'SLAUGHTERED IN', `UK (${slaughter})`, ctx);
  top += lineHeight;
  addRow(top, left, gap, 'CUT IN', `UK (${cutting})`, ctx);
};

const addQRCode = (ctx, qrCode) => {
  ctx.drawImage(qrCode, 820 + bleed, 1122 + bleed, 240, 240);
  ctx.font = '500 30px Work Sans';
  const left = 826 + bleed;
  let top = 1104 + bleed;
  ctx.fillStyle = brandPink;
  ctx.fillText('Trace your beef', left, top);
};

const addRefrigerationText = ctx => {
  ctx.font = dataLabelFont;
  ctx.fillStyle = textGrey;
  let text = 'Keep refrigerated below 3°C. ';
  text += 'If frozen store elow -18°C. ';
  text += 'Once opened consume within 3 days';
  let metrics = ctx.measureText(text);
  let left = (labelWidthWithBleed - metrics.width) / 2;
  let height =
    metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
  ctx.fillText(text, left, 1584 + height + bleed);
};

const addFarmToLabel = (farm, ctx) => {
  ctx.font = '80px Lumios Marker';
  ctx.fillStyle = brandBlack;
  let metrics = ctx.measureText(farm);
  let left = (labelWidthWithBleed - metrics.width) / 2;
  let height =
    metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
  ctx.fillText(farm, left, 476 + height);
};

const addCutToLabel = (cut, ctx) => {
  ctx.font = cutFont;
  ctx.fillStyle = '#FFF';
  let metrics = ctx.measureText(cut);
  let left = (labelWidthWithBleed - metrics.width) / 2;
  let height =
    metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
  ctx.fillText(cut, left, 572 + height);
};

const addWeightToLabel = (weight, ctx) => {
  ctx.font = weightFont;
  ctx.fillStyle = '#FFF';
  let metrics = ctx.measureText(weight);
  let left = (labelWidthWithBleed - metrics.width) / 2;
  const top = 740;
  const padTop = 20;
  const padLeft = 40;
  let height =
    metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
  ctx.fillText(weight, left, top + height);
  ctx.fillStyle = textGrey;
  ctx.lineWidth = 2;
  ctx.strokeRect(
    left - padLeft,
    top - 4,
    metrics.width + (padLeft * 2),
    height + (padTop * 2)
  );
};

const drawDateBox = (ctx, top, left, label, date) => {
  ctx.fillStyle = '#FFF';
  ctx.fillRect(left, top, boxWidth, boxHeight);

  ctx.font = dataLabelFont;
  ctx.fillStyle = textGrey;
  let metrics = ctx.measureText(label);
  ctx.fillText(label, left + ((boxWidth - metrics.width) / 2), top + 42);

  ctx.font = dateFont;
  ctx.fillStyle = brandBlack;
  metrics = ctx.measureText(date);
  ctx.fillText(date, left + ((boxWidth - metrics.width) / 2), top + 85);
};

const addDatesToLabel = (ctx, packDate, useBy, frozenUse) => {
  const margin = 28;
  const gap =
    (labelWidthWithBleed - (bleed * 2) - (margin * 2) - (boxWidth * 3)) / 2;
  let left = bleed + margin;
  let top = 1480;
  drawDateBox(ctx, top, left, 'PACK DATE', packDate);
  left += gap + boxWidth;
  drawDateBox(ctx, top, left, 'USE BY', useBy);
  left += gap + boxWidth;
  drawDateBox(ctx, top, left, 'FROZEN USE BY', frozenUse);
};

const ComplexLabelButton = ({ animal, farm }) => {
  const labels = useSelector(getLabels);
  const scoreDefs = useSelector(getScoreDefinitions);
  const dispatch = useDispatch();
  const logo = useRef();
  const shield = useRef();
  const background = useRef();
  const qrCode = useRef();
  const [ logoLoaded, setLogoLoaded ] = useState(false);
  const [ shieldLoaded, setShieldLoaded ] = useState(false);
  const [ backgroundLoaded, setBackgroundLoaded ] = useState(false);
  const [ fontLoaded, setFontLoaded ] = useState(false);
  const [ qrLoaded, setQRLoaded ] = useState(false);

  let error = null;
  if (!animal) error = 'no animal data to print from';
  if (!farm) error = 'missing farm name (required for printing)';
  const tag = animal.tag || '';
  const name = animal.name || '';
  if (!tag) error = 'missing eartag (required for printing)';
  const beef = animal.beefProcessing;
  if (!beef) error = 'missing beef processing data (required for printing)';
  let slaughterHouseID = null;
  let cuttingPlantID = null;
  try {
    slaughterHouseID = beef.slaughterHouseID;
    cuttingPlantID = beef.cuttingPlantID;
    if (!cuttingPlantID) {
      error = 'missing cutting plant ID (required for labels)';
    }
    if (!slaughterHouseID) {
      error = 'missing slaughterhouse ID (required for labels)';
    }
  } catch (e) {}
  if (error) {
    return (
      <div className="missing-data-warning">{ error }</div>
    );
  }

  useEffect(() => {
    const font = new FontFace('Lumios Marker', `url(${lumiosMarkerURL})`);
    font.load().then(() => setFontLoaded(true));
    const score = calculateScore(animal, scoreDefs);
    const grade = getGradeFromScore(score);
    logo.current = new Image();
    shield.current = new Image();
    background.current = new Image();
    logo.current.crossOrigin = 'anonymous';
    shield.current.crossOrigin = 'anonymous';
    background.current.crossOrigin = 'anonymous';
    logo.current.onload = () => setLogoLoaded(true);
    shield.current.onload = () => setShieldLoaded(true);
    background.current.onload = () => setBackgroundLoaded(true);
    logo.current.src = labelLogo;
    background.current.src = backgroundImage;
    switch (grade) {
      case 'Standard':
        shield.current.src = standardShield;
        break;
      case 'Superior':
        shield.current.src = superiorShield;
        break;
      case 'Classic':
        shield.current.src = classicShield;
        break;
      case 'Prime':
        shield.current.src = primeShield;
        break;
      case 'Supreme':
        shield.current.src = supremeShield;
        break;
      default:
        shield.current.src = standardShield;
    }
    const qrCodeWrapper = document.getElementById('animal-qr-code-wrapper');
    const qrCodeSVG = qrCodeWrapper.querySelector('svg');
    const svgURL = new XMLSerializer().serializeToString(qrCodeSVG);
    qrCode.current = new Image();
    qrCode.current.onload = () => setQRLoaded(true);
    qrCode.current.src =
      'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
  }, []);
  const handleClick = () => {
    dispatch(printLabels());
    let canvas;
    let baseCtx;
    const baseLabelCanvas = document.createElement('canvas');
    baseLabelCanvas.width = labelWidthWithBleed;
    baseLabelCanvas.height = labelHeightWithBleed;
    const ctx = baseLabelCanvas.getContext('2d');

    ctx.drawImage(
      background.current, 0, 0, labelWidthWithBleed, labelHeightWithBleed
    );
    ctx.drawImage(logo.current, bleed + 346, bleed + 60, 480, 233);
    ctx.drawImage(shield.current, bleed + 445, bleed + 890, 280, 250);

    addFarmToLabel(farm, ctx);
    addAnimalDataToLabel(
      name, tag, 'UK', 'UK', cuttingPlantID, slaughterHouseID, ctx
    );
    addQRCode(ctx, qrCode.current);
    addRefrigerationText(ctx);
    labels.forEach((label, index) => {
      const mod = index % 4;
      if (mod === 0) {
        canvas = document.createElement('canvas');
        canvas.width = 2480;
        canvas.height = 3508;
        baseCtx = canvas.getContext('2d');
      }
      const labelCanvas = document.createElement('canvas');
      labelCanvas.width = labelWidthWithBleed;
      labelCanvas.height = labelHeightWithBleed;
      const ctx = labelCanvas.getContext('2d');
      ctx.drawImage(
        baseLabelCanvas, 0, 0, labelWidthWithBleed, labelHeightWithBleed
      );
      addDatesToLabel(ctx, label.packDate, label.useBy, label.frozenUse);
      addCutToLabel(label.cut, ctx);
      addWeightToLabel(label.weight, ctx);

      let top = 42;
      let left = 0;
      if (mod === 1 || mod === 3) {
        left = (canvas.width / 2);
      }
      if (mod === 2 || mod === 3) {
        top = top + (canvas.height / 2);
      }
      baseCtx.drawImage(
        labelCanvas, left, top, labelWidthWithBleed, labelHeightWithBleed
      );

      if (mod === 3 || index === (labels.length - 1)) {
        const fileName =
          `${tag.replaceAll(' ', '_')}_labels_${Math.floor(index / 4) + 1}.png`;
        downloadCanvas(canvas, fileName);
      }
      dispatch(finishPrintingLabels());
    });
  };
  const count = labels.length;
  let button =
    logoLoaded && shieldLoaded && backgroundLoaded && fontLoaded && qrLoaded ? (
      <div className="print-button button" onClick={ handleClick }>
        { `Print ${count} label${count > 1 ? 's' : ''}` }
      </div>
    ) : (
      <div className="preparing">preparing label assets</div>
    );
  return (
    <div className="complex-labels-button">
      { button }
      <div className="hidden">
        <span className="lumios-marker">pre-load</span>
        <span className="hudson-ny">pre-load</span>
      </div>
    </div>
  );
};

ComplexLabelButton.propTypes = {
  animal: PropTypes.object,
  farm: PropTypes.string
};

export default ComplexLabelButton;
