import React from 'react';
import PropTypes from 'prop-types';
import { timeFromNow } from '../../../util/datesAndAges';
import './user-status-string.scss';

const userHasPermissions = user => {
  try {
    return user.permissions.canPostOnBeefBook ||
      user.permissions.canPostOnGBI;
  } catch (error) {
    return false;
  }
};

const UserStatusString = ({ user }) => {
  if (!user) return null;
  let statusInfo = null;
  if (user.lastLogin) {
    statusInfo = (
      <div className='user-status-info'>
        { `Last logged in ${timeFromNow(user.lastLogin)} ` }
      </div>
    );
  } else {
    statusInfo = (
      <div className='user-status-info'>
        No Login recorded
      </div>
    );
  }
  let statusClass = 'user-status-icon';
  if (user.admin || userHasPermissions(user)) {
    statusClass += ' live';
  }
  return (
    <div className="user-status-wrapper">
      <div className={ statusClass }></div>
      { statusInfo }
    </div>
  );
};

UserStatusString.propTypes = {
  user: PropTypes.object
};

export default UserStatusString;
