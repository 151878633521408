import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './user-list-view-item.scss';
import moment from 'moment';
import UserStatusString from './UserStatusString';
import { useSelector } from 'react-redux';
import { getOrgsObj } from '../../orgAdmin/reducer';

const UserListViewItem = ({ user, match }) => {
  const orgs = useSelector(getOrgsObj);
  let org = null;
  try {
    org = <span className="org">{ orgs[user.orgIds[0]].name }</span>;
  } catch (error) { /* */ } // care not
  const name = user.name ? (
    <Link to={ `${match.url}/${user.id}`}>
      <h3 className="name">{ user.name }</h3>
    </Link>
  ) : null;
  const timestamp = user.id.toString().substring(0, 8);
  let creationDate = new Date(parseInt(timestamp, 16) * 1000);
  const creationString = `Created ${moment(creationDate).fromNow()}`;

  return (
    <div className="user-list-item" data-cy="user-list-view-item">
      <div className="block name-block">
        <UserStatusString user={user} />
        { name }
        <Link to={ `${match.url}/${user.id}`}>
          <h5 className="email">
            <span>{ user.email}</span>
            { org }
          </h5>
        </Link>
      </div>
      <div className="block info-block">
        <div className="top">
          <div className="creationDate">{ creationString }</div>
        </div>
      </div>
      <div className="block edit-button-block">
        <Link to={ `${match.url}/${user.id}`}
          className="edit-link">
            Edit
        </Link>
      </div>
    </div>
  );
};

UserListViewItem.propTypes = {
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default UserListViewItem;
