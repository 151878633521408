import React from 'react';
import TickIcon from '../util/icons/components/TickIcon';
import PropTypes from 'prop-types';
import './tick-box-field.scss';
import ToolTip from '../util/tooltips/components/ToolTip';
import TopLevelFormError from '../util/components/TopLevelFormError';

const TickBoxField = ({ title, isOn, toggle, error, testId, children }) => {
  let toolTip = null;
  if (children) {
    toolTip = <ToolTip>
      { children }
    </ToolTip>;
  }
  const errorMsg = error ? (
    <TopLevelFormError message={ error } testId="field-error-text" />
  ) : null;
  return (
    <div className="tick-box-form-field">
      <label className='form-label'>
        { title }
      </label>
      <div data-cy={ testId }
        className={ isOn ? 'tick-box on' : 'tick-box off' }
        onClick={ toggle }>
        <TickIcon />
      </div>
      { toolTip }
      { errorMsg }
    </div>
  );
};

TickBoxField.propTypes = {
  title: PropTypes.string,
  isOn: PropTypes.bool,
  toggle: PropTypes.func,
  error: PropTypes.string,
  testId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default TickBoxField;
