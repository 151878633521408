import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import { Link } from 'react-router-dom';
import TextField from '../../../form/TextField';
import TextAreaField from '../../../form/TextAreaField';
import NumberField from '../../../form/NumberField';
import Modal from '../../../main/components/Modal';
import GBIButton from '../../../util/buttons/components/GBIButton';
import { getOutletsObj } from '../reducer';
import { mongoid } from 'mongoid-js';
import { deleteOutlet, fetchOutlets, updateOutlet } from '../actions';
import { getOutletValidationErrors, outletHasChanged } from '../util';
import Address from '../../util/components/Address';
import DropDownSelectField from '../../../form/DropDownSelectField';
import { outletCategories } from '../../../wheretobuy/outletCategories';
import './admin-outlet.scss';

const OutletDetail = ({ match, history }) => {
  const outletId = match.params.outletId;
  const outletsObject = useSelector(getOutletsObj);
  const savedOutlet = outletsObject[outletId];
  const localOutlet = (outletId !== 'new' && !!savedOutlet) ?
    JSON.parse(JSON.stringify(savedOutlet)) : { id: mongoid() };
  const [outlet, setOutlet] = useState(localOutlet);
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const hasErrors = Object.values(errors).length > 0;
  useEffect(() => {
    if (savedOutlet) setOutlet(savedOutlet);
  }, [savedOutlet]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (outletId !== 'new' && !savedOutlet) dispatch(fetchOutlets());
  }, []);
  const backUrl = match.url.replace('/' + outletId, '');
  const handleSaveClicked = () => {
    if (!showErrors) setShowErrors(true);
    if (!hasErrors && outletHasChanged(savedOutlet, outlet)) {
      dispatch(updateOutlet(outlet));
    }
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClicked = () => setShowDelete(true);
  const updateOutletName = name => {
    const newOutlet = JSON.parse(JSON.stringify(outlet));
    newOutlet.name = name;
    setErrors(getOutletValidationErrors(newOutlet));
    setOutlet(newOutlet);
  };
  const updateOutletCategory = category => {
    const newOutlet = JSON.parse(JSON.stringify(outlet));
    newOutlet.category = category;
    setErrors(getOutletValidationErrors(newOutlet));
    setOutlet(newOutlet);
  };
  const updateOutletDescription = description => {
    const newOutlet = JSON.parse(JSON.stringify(outlet));
    newOutlet.description = description;
    setErrors(getOutletValidationErrors(newOutlet));
    setOutlet(newOutlet);
  };
  const updateAddress = address => {
    const newOutlet = JSON.parse(JSON.stringify(outlet));
    newOutlet.address = { ...address };
    setErrors(getOutletValidationErrors(newOutlet));
    setOutlet(newOutlet);
  };
  const updateOutletURL = url => {
    const newOutlet = JSON.parse(JSON.stringify(outlet));
    newOutlet.url = url;
    setErrors(getOutletValidationErrors(newOutlet));
    setOutlet(newOutlet);
  };
  const updateOutletLat = lat => {
    const newOutlet = JSON.parse(JSON.stringify(outlet));
    let geo = {
      type: 'Point',
      coordinates: ['', '']
    };
    try {
      geo = JSON.parse(JSON.stringify(outlet.geometry));
    } catch (_) {}
    geo.coordinates[1] = lat;
    newOutlet.geometry = geo;
    setErrors(getOutletValidationErrors(newOutlet));
    setOutlet(newOutlet);
  };
  const updateOutletLng = lng => {
    const newOutlet = JSON.parse(JSON.stringify(outlet));
    let geo = {
      type: 'Point',
      coordinates: ['', '']
    };
    try {
      geo = JSON.parse(JSON.stringify(outlet.geometry));
    } catch (_) {}
    geo.coordinates[0] = lng;
    newOutlet.geometry = geo;
    setErrors(getOutletValidationErrors(newOutlet));
    setOutlet(newOutlet);
  };
  let saveButtonClass = 'button';
  if (savedOutlet && !outletHasChanged(savedOutlet, outlet)) {
    saveButtonClass += ' disabled';
  } else if (!outletHasChanged({ id: outlet.id }, outlet)) {
    saveButtonClass += ' disabled';
  } else if (hasErrors && showErrors) {
    saveButtonClass += ' disabled';
  }
  const deleteButtonClass = 'button';
  const deleteOutletFromDB = () => {
    dispatch(deleteOutlet(savedOutlet));
    history.push(backUrl);
  };
  let latitude = null;
  try {
    latitude = outlet.geometry.coordinates[1];
  } catch (_) {}
  let longitude = null;
  try {
    longitude = outlet.geometry.coordinates[0];
  } catch (_) {}
  const categoryOptions = Object.keys(outletCategories).map(key => ({
    name: outletCategories[key], value: key, selected: outlet.category === key
  }));
  const errorDetails = Object.values(errors).length < 1 || !showErrors ?
    null : (
      <div data-cy="errors" className="errors">
        <div className="title">Please correct the following errors</div>
        { Object.values(errors).map((error, index) => (
          <div key={ index } className="error">{ '- ' + error }</div>
        )) }
      </div>
    );
  let title = outlet.name;
  if (!outlet.name) {
    if (outlet.id === 'new') {
      title = 'New Sales Outlet';
    } else {
      title = 'Unnamed Sales Outlet';
    }
  }
  const deleteModal = showDelete ? (
    <Modal close={ () => setShowDelete(false) }
      pos={ window.scrollY }>
      <div className="delete-outlet-modal-content">
        <h2 className="title">{ `Delete ${title}?` }</h2>
        <h3 className='sub-title'>{ 'this action can not be undone' }</h3>
        <div className="button-row">
          <GBIButton
            onClick={ () => setShowDelete(false) }
            label="cancel"
            className="light" />
          <GBIButton testId="confirm-delete"
            onClick={ deleteOutletFromDB }
            label="delete" />
        </div>
      </div>
    </Modal>
  ) : null;
  return (
    <div id="outlet">
      <Link className="back-to-outlets-link"
        to={ backUrl }>
        <span data-cy="back-to-outlets">
          <ChevronIcon />Back to outlets
        </span>
      </Link>
      <h2 data-cy="user-detail-title">{ title }</h2>
      <form className="top" onSubmit={ e => e.preventDefault() }>
        <TextField name="Name"
          value={ outlet ? outlet.name || '' : '' }
          error={ showErrors ? errors.name : null }
          testId="name-field"
          update={ updateOutletName } />
        <DropDownSelectField
          name="Category"
          error={ showErrors ? errors.category : null }
          options={ categoryOptions }
          update={ updateOutletCategory }
          testId="category-field" />
        <TextField name="URL"
          value={ outlet ? outlet.url || '' : '' }
          error={ showErrors ? errors.url : null }
          update={ updateOutletURL }
          testId="url-field" />
        <NumberField name="Latitude"
          value={ latitude }
          error={ showErrors ? errors.latitude : null }
          update={ updateOutletLat }
          testId="lat-field" />
        <NumberField name="Longitude"
          value={ longitude }
          error={ showErrors ? errors.longitude : null }
          update={ updateOutletLng }
          testId="lng-field" />
      </form>
      <form className="description-section"
        onSubmit={ e => e.preventDefault() } >
        <TextAreaField name="Description"
          value={ outlet ? outlet.description || '' : '' }
          error={ showErrors ? errors.description : null }
          className="description-field"
          testId="description-field"
          update={ updateOutletDescription } />
      </form>
      <form className="bottom" onSubmit={ e => e.preventDefault() }>
        <Address address={ outlet ? outlet.address : null }
          onChange={ updateAddress } />
      </form>
      <div className="button-row center">
        <div className="cancel button"
          data-cy="cancel-button"
          onClick={ () => history.push(backUrl) }>
          Cancel
        </div>
        <div className={ saveButtonClass }
          data-cy="save-button"
          onClick={ handleSaveClicked }>
          Save
        </div>
        <div className={ deleteButtonClass }
          data-cy="delete-button"
          onClick={ handleDeleteClicked }>
          Delete
        </div>
      </div>
      { errorDetails }
      { deleteModal }
    </div>
  );
};

OutletDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      outletId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default OutletDetail;
