import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import UserStatusString from '../../userAdmin/components/UserStatusString';
import './org-user-row.scss';
import { isAdmin } from '../../../util/jwt';
import { Link } from 'react-router-dom';

const OrgUserRow = ({ user }) => {
  let email = user.name ? (
    <div className="email">{ user.email }</div>
  ) : null;
  const content = isAdmin() ? (
    <Link to={ `/account/users/${user.id}`} >
      <div className="name">{ user.name || user.email }</div>
      { email }
    </Link>
  ) : (
    <Fragment >
      <div className="name">{ user.name || user.email }</div>
      { email }
    </Fragment>
  );
  return (
    <div className="org-user-row">
      <div className="details">
        { content }
      </div>
      <UserStatusString user={user} />
    </div>
  );
};

OrgUserRow.propTypes = {
  user: PropTypes.object.isRequired
};

export default OrgUserRow;
