/* global localStorage */
import React, { useEffect, useState } from 'react';
import { decodeJWT, getJWT } from '../../util/jwt';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, updateUser } from '../../admin/userAdmin/actions';
import { getUsersObj } from '../../admin/userAdmin/reducer';
import PropTypes from 'prop-types';
import {
  getUserValidationErrors,
  userHasChanged
} from '../../admin/userAdmin/util';
import TextField from '../../form/TextField';
import './profile-page.scss';
import { fetchOrgs } from '../../admin/orgAdmin/actions';
import { getOrgsObj } from '../../admin/orgAdmin/reducer';
import ProfilePermisions from './ProfilePermissions';
import { timeFromNow } from '../../util/datesAndAges';

const GBIMyAccount = ({ history }) => {
  const jwt = getJWT();
  const jwtUser = jwt ? decodeJWT(jwt) : '';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser(jwtUser.userId));
    dispatch(fetchOrgs());
  }, []);
  const users = useSelector(getUsersObj);
  const orgs = useSelector(getOrgsObj);
  const savedUser = users && users[jwtUser.userId] ?
    users[jwtUser.userId] : null;
  const [user, setUser] = useState(savedUser);
  useEffect(() => {
    if (savedUser) setUser(savedUser);
  }, [savedUser]);
  const updateUserName = name => {
    const newUser = JSON.parse(JSON.stringify(user));
    newUser.name = name;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const updateUserPhone = phone => {
    const newUser = JSON.parse(JSON.stringify(user)) || {};
    newUser.phone = phone;
    setErrors(getUserValidationErrors(newUser));
    setUser(newUser);
  };
  const [errors, setErrors] = useState({});
  const handleCancelClicked = () => {
    dispatch(fetchUser(jwtUser.userId));
  };
  const hasErrors = () => Object.values(errors).length > 0;
  const handleSaveClicked = () => {
    if (!hasErrors() && userHasChanged(savedUser, user)) {
      dispatch(updateUser(user));
    }
  };
  const errorDetails = Object.values(errors).length < 1 ? null : (
    <div className="errors">
      <div className="title">Please correct the following errors</div>
      { Object.values(errors).map((error, index) => (
        <div key={ index } className="error">{ '- ' + error }</div>
      )) }
    </div>
  );
  let saveButtonClass = 'button';
  if (!userHasChanged(savedUser, user)) {
    saveButtonClass += ' disabled';
  }
  const title = user && user.name ? user.name : 'Account Profile';
  let orgNames = [];
  if (user && user.orgIds) {
    user.orgIds.forEach(id => {
      if (orgs && orgs[id] && orgs[id].name) {
        orgNames.push(orgs[id].name);
      }
    });
  }
  const org = orgNames.length > 0 ? (
    <h4 className="orgs">{ orgNames.join(', ') }</h4>
  ) : null;
  const lastLoginString = localStorage.getItem('lastLogin') ? (
    <div className='user-status-info'>
      { `Last logged in ${timeFromNow(localStorage.getItem('lastLogin'))} ` }
    </div>
  ) : null;
  return (
    <div className="" id="profile" data-cy="profile-page">
      <h2 data-cy='profile-page-title'>{ title }</h2>
      { org }
      <div>
        { jwtUser.email }
      </div>
      { lastLoginString }
      <form onSubmit={ e => e.preventDefault() }>
        <TextField name="Name"
          testId="profile-name-field"
          value={ user && user.name ? user.name : '' }
          error={ null }
          update={ updateUserName } />
        <TextField name="Phone"
          testId="profile-phone-field"
          value={ user && user.phone ? user.phone : '' }
          error={ null }
          update={ updateUserPhone } />
      </form>
      <ProfilePermisions user={ user } />
      <div className="button-row center">
        <div className="cancel button"
          data-cy="cancel-button"
          onClick={ handleCancelClicked }>
          Cancel
        </div>
        <div className={ saveButtonClass }
          data-cy="save-button"
          onClick={ handleSaveClicked }>
          Save
        </div>
      </div>
      { errorDetails }
    </div>
  );
};

GBIMyAccount.propTypes = {
  history: PropTypes.object
};

export default GBIMyAccount;
