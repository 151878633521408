import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDownIcon from '../../../util/icons/components/DropDownIcon';
import { setUltrasoundSort } from '../actions';
import { getUltrasoundCount, getUltrasoundSort } from '../reducer';

const sortOptions = {
  technician: 'Scanning Technician',
  farm: 'Farm',
  date: 'Date'
};

const UltrasoundListViewHeader = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const handleSortOptionClicked = sortOption => {
    dispatch(setUltrasoundSort(sortOption));
  };
  const [ showingDropdown, setShowingDropdown ] = useState(false);
  const toggleVisibility = () => setShowingDropdown(!showingDropdown);
  const dropDownOptions = Object.keys(sortOptions).map(key => (
    <li key={ key }
      data-cy={ `outlet-sort-option-${key}` }
      onClick={() => handleSortOptionClicked(key)}>
      { sortOptions[key] }
    </li>
  ));
  const currentSort = useSelector(getUltrasoundSort);
  const scanCount = useSelector(getUltrasoundCount);
  const optionsClass = showingDropdown ?
    'dropdown-options' : 'dropdown-options hidden';
  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setShowingDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const arrowClass = showingDropdown ? 'open' : null;
  return (
    <div id='admin-search-header'>
      <div className='count'>
        <span className='number'>
          { scanCount.toLocaleString() }
        </span>
        Scanning Sessions
      </div>
      <div ref={wrapperRef}
        className='sort'
        data-cy="scan-sort-dropdown"
        onClick={ toggleVisibility }>
        { 'Sort by' }
        <p className='attribute'>{ sortOptions[currentSort] }</p>
        <ul className={ optionsClass }>
          { dropDownOptions }
        </ul>
        <DropDownIcon className={ arrowClass } />
      </div>
      <div className="triangle-wrapper">
        <div className='triangle'></div>
      </div>
    </div>
  );
};

export default UltrasoundListViewHeader;
