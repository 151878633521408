import React, { useState, useRef, useEffect } from 'react';
import Modal from '../../../main/components/Modal';
import GBIButton from '../../../util/buttons/components/GBIButton';
import './ultrasound-upload.scss';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import TextField from '../../../form/TextField';
import UltrasoundAPI from '../UltrasoundAPI';
import DownloadIcon from '../../../util/icons/components/DownloadIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../userAdmin/reducer';
import UploadResults from './UploadResults';
import TopLevelFormError from '../../../util/components/TopLevelFormError';
import { fetchUltrasoundScans } from '../actions';
import ToolTip from '../../../util/tooltips/components/ToolTip';
import ExampleCSVLink from './ExampleCSVLink';

const UltrasoundUpload = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const user = useSelector(getCurrentUser);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [tech, setTech] = useState(user ? user.name : '');
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setTech(user ? user.name : '');
  }, [user]);
  const onNameChange = tech => {
    setTech(tech);
    if (tech === '') {
      setNameError('technician name is required');
    } else if (tech.length < 5) {
      setNameError('plus enter the technician\'s full name');
    } else {
      setNameError(null);
    }
  };
  const onCloseModal = () => {
    fileInputRef.current.value = null;
    setNameError(null);
    setUploadError(null);
    setShowModal(false);
    setUploadError(null);
    setUploadResult(null);
    setTech(user ? user.name : '');
  };
  const onUploadProgress = event => {
    if (event.lengthComputable) {
      const progress = event.loaded / event.total;
      setProgress(progress);
    }
  };
  const handleSaveClicked = () => {
    if (!tech) {
      setNameError('technician name is required');
    } else if (tech && tech.length < 5) {
      setNameError('please enter the technician\'s full name');
    } else {
      // const formData = new FormData();
      const file = fileInputRef.current.files[0];
      setLoading(true);
      UltrasoundAPI.uploadSession(tech, file, onUploadProgress)
        .then(res => {
          if (res.data) {
            setUploadResult(res.data);
          }
          setLoading(false);
          dispatch(fetchUltrasoundScans());
        }).catch(err => {
          setLoading(false);
          if (err.response) {
            setUploadError(err.response.data);
            if (typeof err.response.data === 'string') {
              setUploadError(err.response.data);
            } else if (typeof err.response.data === 'object') {
              setUploadResult(err.response.data);
            }
          } else {
            setUploadError(err.toString());
          }
          dispatch(fetchUltrasoundScans());
        });
    };
  };
  let results = null;
  let httpError = null;
  let saveButton = (
    <GBIButton
      className={ `save-button${nameError ? ' error' : ''}` }
      onClick={ handleSaveClicked }
      testId="save-button"
      label="save" />
  );
  if (loading) {
    saveButton = <LoadingIcon progress={ progress }/>; // nonsense param "progress"
  }
  if (uploadResult) {
    saveButton = null;
    results = <UploadResults results={ uploadResult } />;
  }
  if (uploadError) {
    httpError = <TopLevelFormError testId="request-failed-error"
      message={ 'upload failed' } />;
    saveButton = null;
  }
  let fileName = null;
  try {
    fileName = (
      <h2 className="mini-title">
        file: { fileInputRef.current.files[0].name }
        <ToolTip>
          The file uploaded must be a cuplabs csv
          in the correct format.
          <ExampleCSVLink />
        </ToolTip>
      </h2>
    );
  } catch (e) { /* */ }
  // console.log('user', user);
  const closeButtonTitle = uploadError || uploadResult ? 'close' : 'cancel';
  const modal = showModal ? (
    <Modal contentClass="ultrasound-upload-modal"
      contentTestId="ultrasound-upload-modal"
      close={ onCloseModal }>
      <h1 className="main-title" data-cy="upload-modal-title">
        Upload Ultrasound Session Data from the Cup Lab
      </h1>
      { fileName }
      <div className="form-fields">
        <TextField name="scanning technician"
          value={ tech }
          testId="tech-name-input"
          update={ onNameChange }
          error={ nameError} >
          Enter the name of the ultrasound technician who carried out the
          scanning session
        </TextField>
      </div>
      { httpError }
      { results }
      <div className="button-row">
        <GBIButton className="disabled cancel-button"
          onClick={ onCloseModal }
          testId={ `${closeButtonTitle}-button`}
          label={ closeButtonTitle }/>
        { saveButton }
      </div>
    </Modal>
  ) : null;
  return (
    <div className="ultrasound-upload-widget">
      <GBIButton label="Upload Scanning Session"
        className="upload-button"
        testId="upload-csv-button"
        onClick={ () => fileInputRef.current.click() } >
        <DownloadIcon />
      </GBIButton>
      <input
        ref={fileInputRef}
        className="file-input"
        data-cy={ 'ultrasound-upload-input' }
        type="file"
        style={{ display: 'none' }}
        multiple
        onChange={ () => setShowModal(true) } />
      { modal }
    </div>
  );
};

export default UltrasoundUpload;
