export const tickSrc = 'https://media.goodbeefindex.org/pdf/gbitick.png';
export const logoSrc = 'https://media.goodbeefindex.org/pdf/gbilogo.png';
export const labelLogo =
  'https://media.goodbeefindex.org/pdf/GoodBeef-labels-logo.png';
export const standardShield =
  'https://media.goodbeefindex.org/pdf/gbi-grade-standard.svg';
export const superiorShield =
  'https://media.goodbeefindex.org/pdf/gbi-grade-superior.svg';
export const classicShield =
  'https://media.goodbeefindex.org/pdf/gbi-grade-classic.svg';
export const primeShield =
  'https://media.goodbeefindex.org/pdf/gbi-grade-prime.svg';
export const supremeShield =
  'https://media.goodbeefindex.org/pdf/gbi-grade-supreme.svg';
export const background =
  'https://media.goodbeefindex.org/pdf/SimpleLabelsBackground.jpg';
export const largeBackground =
  'https://media.goodbeefindex.org/pdf/DetailedLabelsBackground.jpg';
