import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getHelpVidsObj } from '../reducer';
import { mongoid } from 'mongoid-js';
import {
  deleteHelpVid,
  fetchHelpVids,
  updateHelpVid
} from '../actions';
import Modal from '../../../main/components/Modal';
import GBIButton from '../../../util/buttons/components/GBIButton';
import { vidHasChanged } from '../util';
import './help-video-edit.scss';
import { Link } from 'react-router-dom';
import ChevronIcon from '../../../util/icons/components/ChevronIcon';
import TextField from '../../../form/TextField';
import TextAreaField from '../../../form/TextAreaField';
import VideoAdminSection from '../../videoAdmin/components/VideoAdminSection';
import HelpVidPermsEditor from './HelpVidPermsEditor';

const HelpVideoEdit = ({ match, history }) => {
  const [showDelete, setShowDelete] = useState(false);
  const vidId = match.params.vidId;
  const videosObject = useSelector(getHelpVidsObj);
  const savedVid = videosObject[vidId];
  let localVid = (vidId !== 'new' && !!savedVid) ?
    JSON.parse(JSON.stringify(savedVid)) : { id: mongoid() };
  const [vid, setVid] = useState(localVid);
  const [tagsString, setTagsString] =
    useState(vid.tags ? vid.tags.join(', ') : '');
  useEffect(() => {
    if (savedVid) {
      setVid(savedVid);
      setTagsString(vid.tags ? vid.tags.join(', ') : '');
    }
  }, [savedVid]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (vidId !== 'new' && !savedVid) dispatch(fetchHelpVids());
  }, []);
  const backUrl = match.url.replace('/' + vidId, '');
  const canSave = vidHasChanged(savedVid, vid) &&
    vid.title &&
    vid.videoData &&
    (vid.videoData.mp4 ||
    vid.processing);
  const handleSaveClicked = () => {
    if (canSave) {
      dispatch(updateHelpVid(vid));
    }
  };
  const handleDeleteClicked = () => setShowDelete(true);
  const title = vid.title ? vid.title : 'new help video';
  const updateHelpVideoTitle = title => {
    const newVid = JSON.parse(JSON.stringify(vid));
    newVid.title = title;
    setVid(newVid);
  };
  const updateTags = tagsString => {
    setTagsString(tagsString);
    const newVid = JSON.parse(JSON.stringify(vid));
    let tags = tagsString.split(',');
    tags = tags.map(tag => tag.trim());
    newVid.tags = tags;
    setVid(newVid);
  };
  const deleteVidFromDB = () => {
    // const cloudFilesToRemove = [];
    dispatch(deleteHelpVid(savedVid));
    history.push(backUrl);
  };
  const updateVideo = videoData => {
    const newVid = JSON.parse(JSON.stringify(vid));
    newVid.videoData = videoData;
    setVid(newVid);
  };
  const saveButtonClass = canSave ? 'button' : 'button disabled';
  const deleteModal = showDelete ? (
    <Modal close={ () => setShowDelete(false) }
      pos={ window.scrollY }>
      <div className="delete-vid-modal-content">
        <h2 className="title">{ `Delete help video "${title}"?` }</h2>
        <h3 className='sub-title'>{ 'this action can not be undone' }</h3>
        <div className="button-row">
          <GBIButton
            onClick={ () => setShowDelete(false) }
            label="cancel"
            className="light" />
          <GBIButton testId="confirm-delete"
            onClick={ deleteVidFromDB }
            label="delete" />
        </div>
      </div>
    </Modal>
  ) : null;
  const videoUpload = vid.title ? (
    <VideoAdminSection videoData={ vid.videoData }
      update={ updateVideo }
      uploadText={'uploaded - video will be visible after processing'}
      helpVid={ vid } />
  ) : null;
  const updatePerms = perms => {
    const newVid = JSON.parse(JSON.stringify(vid));
    newVid.permissions = perms;
    setVid(newVid);
  };
  return (
    <div id="help-video-edit">
      { deleteModal }
      <Link className="back-to-videos-link"
        to={ backUrl }>
        <span data-cy="back-to-videos">
          <ChevronIcon />Back to videos
        </span>
      </Link>
      <h2 data-cy="video-title">{ title }</h2>
      <form onSubmit={ e => e.preventDefault() }>
        <TextField name="title"
          value={ vid.title }
          error={ null }
          update={ updateHelpVideoTitle }
          testId="video-title-field" />
        <TextAreaField name="tags"
          value={ tagsString }
          update={ updateTags }
          testId="video-tags-field"/>
        <HelpVidPermsEditor perms={ vid.permissions || [] }
          update={ updatePerms } />
      </form>
      { videoUpload }
      <div className="button-row center">
        <div className="cancel button"
          data-cy="cancel-button"
          onClick={ () => history.push(backUrl) }>
          Cancel
        </div>
        <div className={ saveButtonClass }
          data-cy="save-button"
          onClick={ handleSaveClicked }>
          Save
        </div>
        <div className="button"
          data-cy="delete-button"
          onClick={ handleDeleteClicked }>
          Delete
        </div>
      </div>
    </div>
  );
};

HelpVideoEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      vidId: PropTypes.string.isRequired
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object
};

export default HelpVideoEdit;
