import React from 'react';
import ErrorIcon from '../icons/components/ErrorIcon';
import PropTypes from 'prop-types';
import './top-level-form-error.scss';

const TopLevelFormError = ({ testId, message }) => (
  <div className="top-level-form-error-wrapper">
    <div data-cy={ testId || 'top-level-form-error'}
      className="top-level-error-message">
      <ErrorIcon />
      { message || 'Please correct the errors marked' }
    </div>
  </div>
);

TopLevelFormError.propTypes = {
  testId: PropTypes.string,
  message: PropTypes.string
};

export default TopLevelFormError;
