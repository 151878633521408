import React, { Fragment, useEffect, useState } from 'react';
import TextField from '../../../form/TextField';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgsObj, getOrgUnderEdit } from '../../orgAdmin/reducer';
import { fetchOrg, setEditingOrg } from '../../orgAdmin/actions';
import GBIButton from '../../../util/buttons/components/GBIButton';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import useInterval from '../../../util/hooks/useInterval';
import { syncAgriwebb } from '../actions';
import { timeFromNow } from '../../../util/datesAndAges';
import TopLevelFormError from '../../../util/components/TopLevelFormError';
import { getAgriwebbCredentialsError } from '../util';
import ToolTip from '../../../util/tooltips/components/ToolTip';

const Agriwebb = ({ orgId }) => {
  const [ requestingSync, setRequestingSync ] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const orgsObj = useSelector(getOrgsObj);
  const org = useSelector(getOrgUnderEdit);
  const agriwebb = org && org.agriwebb ? org.agriwebb : {};
  const dispatch = useDispatch();
  const errors = showErrors ? getAgriwebbCredentialsError(agriwebb) : {};
  const hasErrors = !!Object.keys(getAgriwebbCredentialsError(agriwebb)).length;
  useEffect(() => {
    return () => dispatch(setEditingOrg(null));
  }, []);
  useEffect(() => {
    if (orgsObj[orgId] && (!org || org.id !== orgId)) {
      dispatch(setEditingOrg(orgsObj[orgId]));
    }
    if (org && org.agriwebb && org.agriwebb.currentlySyncing) {
      setRequestingSync(false);
    }
  }, [orgsObj, org]);
  useInterval(() => {
    if (agriwebb.currentlySyncing || requestingSync) {
      dispatch(fetchOrg(orgId));
    }
  }, 6 * 1000, [agriwebb, requestingSync]); // poll the org endpoint
  const updateField = (field, value) => {
    const newOrg = JSON.parse(JSON.stringify(org || {}));
    const data = JSON.parse(JSON.stringify(agriwebb));
    data[field] = value;
    newOrg.agriwebb = data;
    dispatch(setEditingOrg(newOrg));
  };
  const handleSyncClicked = () => {
    setShowErrors(true);
    if (!requestingSync && !hasErrors) {
      dispatch(syncAgriwebb(org));
      setRequestingSync(true);
    }
  };
  if (agriwebb.currentlySyncing) {
    return (
      <div className="integration-content" data-cy="sync-in-progress">
        <h1>Agriwebb</h1>
        <h4>{` We're currently syncing your account...`}</h4>
        <p>{ `Task ID: ${agriwebb.currentlySyncing} `}</p>
        <LoadingIcon />
      </div>
    );
  }
  const lastSync = agriwebb.lastSync && !agriwebb.error ? (
    <div className="last-sync" data-cy="last-sync">
      <span className="label">Last sync: </span>
      <span className="date">{ timeFromNow(agriwebb.lastSync) }</span>
    </div>
  ) : null;
  const lastResult = agriwebb.lastResult ? (
    <div className="last-sync" data-cy="result">
      <span className="label">Sync result: </span>
      <span className="text">{ agriwebb.lastResult }</span>
    </div>
  ) : null;
  const buttonClass = requestingSync || (showErrors && hasErrors) ?
    'disabled' : null;
  const agriwebbError = agriwebb.error ? (
    <Fragment>
      <TopLevelFormError testId="sync-failed-warning"
        message={`Sync failed ${timeFromNow(agriwebb.lastSync) || ''}`}/>
      <div className="third-party-error" data-cy="bcms-error">
        <span className="error-label">Error response from Agriwebb: </span>
        <span className="error-text">{ agriwebb.error }</span>
      </div>
    </Fragment>
  ) : null;
  return (
    <div className="integration-content">
      <h2 className="integration-title">
        Agriwebb
        <ToolTip className="wide">
          <p>The GoodBeef Index Agriwebb sync service will download all
          cattle in your Agriwebb account to be uploaded to your
          GoodBeef Index account. If the animal exists in your account
          it the service will updated the animal (if necassary), otherwise
          a new animal will be created.</p>
        </ToolTip>
      </h2>
      <p>coming soon...</p>
      { lastSync }
      { lastResult }
      { agriwebbError }
      <div className="agriwebb-fields fields" style={{ display: 'none' }}>
        <TextField name="Agriwebb username"
          value={ agriwebb.ctsoluser }
          error={ errors.ctsoluser }
          testId="username"
          update={ val => updateField('ctsoluser', val) } />
        <TextField name="Agriwebb password"
          value={ agriwebb.pwd }
          error={ errors.pwd }
          testId="pwd"
          update={ val => updateField('pwd', val) } />
      </div>
      <div className="buttons" style={{ display: 'none' }}>
        <GBIButton label="Download Agriwebb Cattle"
          onClick={ handleSyncClicked }
          testId="sync_agriwebb_button"
          className={ buttonClass } />
      </div>
    </div>
  );
};

Agriwebb.propTypes = {
  orgId: PropTypes.string.isRequired
};

export default Agriwebb;
